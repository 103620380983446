import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Layout, Tabs, notification, Select } from 'antd';
import { FolderOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import queryString from 'query-string';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { GalleryForm } from '../../components/forms';
import { GalleryTable } from '../../components/tables';

const { Content } = Layout;
const { TabPane } = Tabs;

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'key',
  'updatedAt',
  'uploadedBy',
  'url',
  'forMobile',
];

const GalleryEditContent = (props) => {
  const history = useHistory();
  const {
    fromPage,
    itemId,
    imageIds,
    limit,
    gallery,
    pictogram,
    pictogramIds,
    sizeTableImage,
    verticalImage,
  } = queryString.parse(props.location.search);

  let prevImages = [];
  if (imageIds) prevImages = imageIds.split(',');
  if (pictogramIds) prevImages = pictogramIds.split(',');
  const currentuser = useContext(UserContext);
  const [images, fetchImages] = useAxios(
    '',
    null,
    currentuser.data.token,
    'get',
  );
  const [selected, setSelected] = useState(prevImages);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [chosenFolder, setChosenFolder] = useState('Prikazi sve');

  useEffect(() => {
    if (chosenFolder) {
      let folderFilter = {};
      if (chosenFolder === 'Prikazi sve') {
        folderFilter = { $exists: false };
      } else {
        folderFilter = chosenFolder;
      }
      fetchImages(
        `${SERVER_URL}/images?filter=${JSON.stringify({
          type: { $ne: 'reclamation' },
          folder: folderFilter,
        })}`,
      );
    } else if (!images.data || !chosenFolder) {
      fetchImages(
        `${SERVER_URL}/images?filter=${JSON.stringify({
          type: { $ne: 'reclamation' },
        })}`,
      );
    }
  }, [fetchImages, chosenFolder]);

  useEffect(() => {
    fetchData(`${SERVER_URL}/image-folders`, []);
  }, [fetchData]);

  let columnKeys;
  if (images && images.data && images.data.length > 0) {
    const keys = Object.keys(images.data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    if (!columnKeys.includes('folder')) {
      columnKeys.push('folder');
    }
  }

  let tableData = [];
  if (images && images.data && images.data.length > 0) {
    tableData = images.data.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      if (item.folder && item.folder.folderName) {
        item.folder = item.folder.folderName;
      }
      return item;
    });
  }

  const selectHandler = (value) => {
    if (gallery !== undefined || pictogram !== undefined) {
      // MULTISELECT
      if (selected.includes(value)) {
        const removed = selected.filter((item) => item !== value);
        setSelected(removed);
      } else {
        if (selected.length >= +limit && !fromPage.includes('slider')) {
          return notification.error({
            message: `You can select max ${limit} images`,
          });
        }
        setSelected([...selected, value]);
      }
    } else {
      if (selected.includes(value)) {
        const removed = selected.filter((item) => item !== value);
        setSelected(removed);
      } else {
        // SINGLE SELECT
        setSelected([value]);
      }
    }
  };

  const imageData = () => {
    let savePath = 'public/images/',
      type = 'common',
      height = 500;
    switch (fromPage.split('/')[0]) {
      case 'data-categories':
        savePath += 'categories/';
        type = 'category';
        height = 100;
        break;
      case 'data-attributes':
        savePath += 'attributes/';
        type = 'attribute';
        height = 100;
        break;
      case 'edit-store':
      case 'new-store':
        savePath += 'store/';
        type = 'store';
        break;
      case 'edit-data':
      case 'new-data':
      case 'new-variation':
        savePath += 'data/';
        type = sizeTableImage == 'true' ? null : 'data';
        type = verticalImage == 'true' ? null : 'data';
        break;
      case 'edit-user':
      case 'new-user':
        savePath += 'user/';
        type = 'user';
        height = 100;
        break;
      case 'edit-post':
      case 'new-post':
        savePath += 'post/';
        type = 'post';
        break;
      case 'new-tag':
      case 'edit-tag':
        savePath += 'tag/';
        type = 'tag';
        height = 100;
        break;
      case 'new-page':
      case 'edit-page':
        savePath += 'page/';
        type = 'page';
        height = 100;
        break;
      case 'new-pricelist':
      case 'edit-pricelist':
        savePath += 'pricelist/';
        type = 'pricelist';
        height = 100;
        break;
      case 'new-catalog':
      case 'edit-catalog':
        savePath += 'catalog/';
        type = 'catalog';
        height = 100;
        break;
      case 'new-brand':
      case 'edit-brand':
        savePath += 'brand/';
        type = 'brand';
        height = 100;
        break;
      case 'new-slider':
      case 'edit-slider':
        savePath += 'slider/';
        type = 'slider';
        height = 100;
        break;
      default:
        break;
    }
    return { savePath, type, height };
  };

  const insertHandler = () => {
    const uploadedImages = sessionStorage.getItem('uploaded');
    const prevImageIds = selected.length > 0 ? selected.join(',') : '';
    const uploadedImageIds = uploadedImages
      ? prevImageIds
        ? ',' + uploadedImages
        : uploadedImages
      : '';
    let path =
      gallery !== undefined
        ? `/admin/${fromPage}?imageIds=${prevImageIds.concat(uploadedImageIds)}`
        : pictogram !== undefined
          ? `/admin/${fromPage}?pictogramIds=${prevImageIds.concat(
            uploadedImageIds,
          )}`
          : sizeTableImage !== undefined
            ? `/admin/${fromPage}?sizeTableImageId=${uploadedImages
              ? uploadedImages
              : selected.length > 0
                ? selected[0]
                : ''
            }`
            : verticalImage !== undefined
              ? `/admin/${fromPage}?verticalImageId=${uploadedImages
                ? uploadedImages
                : selected.length > 0
                  ? selected[0]
                  : ''
              }`
              : `/admin/${fromPage}?imgId=${uploadedImages
                ? uploadedImages
                : selected.length > 0
                  ? selected[0]
                  : ''
              }`;
    sessionStorage.removeItem('uploaded');
    history.push(path);
  };

  return (
    <div className="content-wrapper">
      <div className="actions-block">
        <Button type="primary" onClick={insertHandler}>
          Insert
        </Button>
      </div>

      <div className="content">
        {/* {itemId && images.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />} */}
        {itemId && !images.isLoading && images.isError && (
          <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>
        )}
        <Layout className="site-layout-background">
          <Content style={{ padding: '0 0 0 15px', minHeight: 280 }}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="GALLERY" key="1">
                <div className="content">
                  <div style={{ marginBottom: '10px' }}>
                    {data &&
                      data.data &&
                      data.data.items &&
                      data.data.items.length !== 0 && (
                        <div className="gallery-upload-edit">
                          <span htmlFor="select">FOLDER: </span>
                          <Select
                            className="select-folder"
                            onSelect={(values) => setChosenFolder(values)}
                            style={{ display: 'unset' }}
                          >
                            {data.data.items.map((item) => (
                              <Select.Option value={item._id}>
                                {item.folderName}
                              </Select.Option>
                            ))}
                            <Select.Option value={'Prikazi sve'}>
                              Prikaži sve
                            </Select.Option>
                          </Select>
                        </div>
                      )}
                  </div>
                  {images && images.isLoading && (
                    <LoadingOutlined
                      spin
                      style={{
                        fontSize: '3rem',
                        marginTop: '5rem',
                        marginLeft: '50%',
                        transform: 'translateX(-50%)',
                      }}
                    />
                  )}
                  {images && !images.isLoading && images.isError && (
                    <h2 style={{ marginTop: '5rem' }}>
                      Something went wrong :(
                    </h2>
                  )}
                  {images &&
                    !images.isLoading &&
                    !images.isError &&
                    images.data &&
                    images.data.length > 0 && (
                      <GalleryTable
                        data={tableData}
                        columnKeys={columnKeys}
                        title="Gallery"
                        selectHandler={selectHandler}
                        selected={selected}
                      />
                    )}
                  {images &&
                    images.data &&
                    !images.isLoading &&
                    images.data.length === 0 && <h2>NO IMAGES</h2>}
                </div>
              </TabPane>
              <TabPane tab="UPLOAD" key="2">
                <div className="content">
                  <GalleryForm
                    token={currentuser.data.token}
                    selectHandler={selectHandler}
                    imageSavePath={imageData().savePath}
                    imageType={imageData().type}
                    imageHeight={imageData().height}
                  />
                </div>
              </TabPane>
            </Tabs>
          </Content>
        </Layout>
      </div>
    </div>
  );
};

export default GalleryEditContent;
