import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import CatalogForm from '../../components/forms/DocumentForm';
import { UserContext } from '../../App';
import { Button, notification } from 'antd';
import queryString from "query-string";
import {DataForm} from "../../components/forms";

const EditCatalog = (props) => {

  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [catalog, fetchCatalog] = useAxios('', {}, currentuser.data.token, 'get');
  const [catalogImage, fetchCatalogImage] = useAxios('', null, currentuser.data.token, 'get');
  const [catalogFile, fetchCatalogFile] = useAxios('', null, currentuser.data.token, 'get');
  const { id } = props.match.params;
  const { imgId } = queryString.parse(props.location.search);
  const [fileList, setFileList] = useState([]);
  const path = props.match.path;

  const getPdf = async (path) => {
    try {
      const resPdf = await Axios.get(`${SERVER_URL}/get-pdf?fileName=${path}`, {
        withCredentials: false,
        responseType: 'arraybuffer',
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      const blob = new Blob([resPdf.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      return url;
    } catch (err) {
      console.log(err);
    }
  };
  const prevForm = sessionStorage.getItem('catalogForm');
  const values = JSON.parse(prevForm);

  window.onbeforeunload = () => {
    sessionStorage.removeItem('catalogForm');
  };
  useEffect(() => {
    let fileList = [];
    if (id) fetchCatalog(`${SERVER_URL}/catalogs/${id}`, {});
    if (imgId) fetchCatalogImage(`${SERVER_URL}/images/${imgId}`, {});
    if (values && values.file) fetchCatalogFile(`${SERVER_URL}/pdfs/${values.file}`, {});

    const getFiles = async () => {
      if (!catalogFile.isLoading && catalogFile.data && currentuser.language) {
        let url = await getPdf(catalogFile.data.localPath);
        fileList.push({ name: catalogFile.data.originalname, _id: catalogFile.data._id, uid: '-1', status: 'done', url: url });
        setFileList(fileList);
      } else if (id && !catalog.isLoading && catalog.data && currentuser.language && catalog.data.file && !values) {
        let url = await getPdf(catalog.data.file.localPath);
        fileList.push({ name: catalog.data.file.originalname, _id: catalog.data.file._id, uid: '-1', status: 'done', url: url });
        setFileList(fileList);
      }
    };
    getFiles();
  }, [fetchCatalog, fetchCatalogImage, fetchCatalogFile, id, imgId, catalogFile, catalog]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/catalogs` : `${SERVER_URL}/catalogs/${id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Catalog ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });

      if (catalog.data.file && catalog.data.file._id !== formData.file && method === 'put') {
        try {
          const resPdf = await Axios.get(`${SERVER_URL}/delete-pdf?file=${JSON.stringify(catalog.data.file)}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` }
          });
        } catch (error) {
          const msg = error.response ? error.response.data.message : error.message ? error.message : error;
          notification.error({
            message: msg,
            placement: 'bottomRight',
          });
        }
      }
      history.push('/admin/catalogs');
    } catch (error) {
      console.log('error::', error);
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }

  };

  const onChangeHandler = async (file) => {
    if (file.status === 'uploading'){
      setFileList([...fileList.filter(item => item.status === 'done'), file]);
    } else if (file.status === 'done'){
      const url = await getPdf(file.response.file.localPath)
      setFileList([...fileList.filter(item => item.status === 'done'), {...file, url}])
    } else if (file.status === 'removed' || (file.response && file.response.status === 'removed')) {
      let fileId = file.response && file.response.file ? file.response.file._id : file._id;
      setFileList(fileList.filter(item => {
        if(item._id){
          return item._id !== fileId;
        } else if (item.response && item.response.file){
          return item.response.file._id !== fileId;
        }
      }))
      const check = catalog.data && catalog.data.file && catalog.data.file._id === fileId;
      if(!id || (id && !check)){
        await Axios.get(`${SERVER_URL}/pdfs/${fileId}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }).then(async res => {
          await Axios.get(`${SERVER_URL}/delete-pdf?file=${JSON.stringify(res.data)}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` }})
        })
      }
    }
  }
  const isDataFetched = !catalog.isLoading && catalog.data && currentuser.language && !catalogImage.isLoading;


  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/catalogs'>
          <Button type='primary'>All catalogs</Button>
        </Link>
      </div>
      <div>
        {!id && isDataFetched && (
          <CatalogForm
            isNew={true}
            image={catalogImage.data}
            file={fileList}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            onChangeHandler={onChangeHandler}
            path={path}
          />
        )}
        {id && !catalog.isError && catalog.data && isDataFetched && (
          <CatalogForm
            isNew={false}
            data={catalog.data}
            image={catalogImage.data}
            file={fileList}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            onChangeHandler={onChangeHandler}
            path={path}
          />
        )}
      </div>
    </div>

  );
};

export default EditCatalog;
