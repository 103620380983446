import React from 'react';
import { Button, Input, Table, Checkbox, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { SERVER_URL } from '../../config';
import { Link } from 'react-router-dom';

const GalleryTable = ({
  data,
  selectHandler,
  columnKeys,
  selected,
  deleteHandler,
  title,
  isGallery,
  editHandler,
  setSelectedImages,
}) => {
  let searchInput;

  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }}
      />
    ),
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? record[dataIndex[0]][dataIndex[1]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title: item.toUpperCase(),
    dataIndex: item,
    ...getColumnSearchProps(item),
  }));

  columns.unshift({
    title: 'File',
    render: (item) => (
      <div style={{ textAlign: 'center' }}>
        <img
          src={getUrl(item.url)}
          alt={item.key}
          style={{ height: '40px', width: 'auto' }}
        />
      </div>
    ),
  });

  if (selectHandler) {
    columns.unshift({
      title: 'Select',
      width: '4rem',
      render: (item) => (
        <div style={{ textAlign: 'center' }}>
          <Checkbox
            checked={selected.includes(item._id)}
            onChange={() => selectHandler(item._id)}
          />
        </div>
      ),
    });
  }

  columns.push({
    title: 'url',
    render: (item) =>
      `${SERVER_URL}${item.url}`,
  });

  // columns.push({
  //   title: 'uploadedby',
  //   render: (item) =>
  //     `${item.uploadedBy ? item.uploadedBy.firstName : ''} ${item.uploadedBy ? item.uploadedBy.lastName : ''
  //     }`,
  //   ...getColumnSearchProps(['uploadedBy', 'firstName', 'lastName']),
  // });

  if (isGallery) {
    columns.push({
      title: 'Akcija',
      render: (text, record) => (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
          }}
        >
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <EditOutlined
              title={`Edit ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => editHandler(record._id)}
            />
          </div>
          <div style={{ margin: '2px', padding: '4px' }}>
            <Popconfirm
              placement="left"
              title={`Ovo ce obrisati sliku`}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText="Ok"
              cancelText="Cancel"
            >
              <DeleteOutlined title={`Obrisi sliku`} />
            </Popconfirm>
          </div>
        </div>
      ),
    });
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedImages(selectedRows);
    },
  };
  return (
    <div>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        size="middle"
        bordered
        dataSource={data}
        columns={columns}
        rowKey="_id"
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default GalleryTable;
