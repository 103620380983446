import React, {useEffect, useState} from 'react';
import {Input, Button, Form, Row, Col, Select, Typography, Card, Table, Checkbox} from 'antd';
import moment from 'moment'
import UploadFile from "../base/UploadFile";
const { Option } = Select;
const { Text } = Typography;
const {TextArea} = Input;

const formInit = {
  _id: null,
  orderNumber: null,
  user: {},
  totalPrice: null,
  status: '',
  order: [],
  reference: [],
  pdf: undefined,
  message: '',
};

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
};

const OrderForm = ({ data, onSubmit, createInvoice, downloadFile, token, handleFileChange, fileList }) => {
  const [form] = Form.useForm();
  let sort = [0, 1, 2];
  let ref = [];
  sort.forEach(number => {
    let index = data.pdf.findIndex(item => item.reference === number);
    if(index >= 0){
      ref.push(data.pdf[index].originalname.replace('.pdf', ''));
    } else {
      ref.push(undefined)
    }
  })
  let initialValues = { ...formInit, ...{ ...data, reference: data.pdf.length > 0 ? ref : undefined }};
  const orderStatus = ['On hold', 'Delivered', 'Not delivered', 'Processed'];
  let [reference, setReference] = useState(data.pdf.length > 0 ? ref : []);
  let [colorIndex, setColorIndex] = useState([]);

  const onFinish = async (values) => {
    let arrCodes = []
    data.order.forEach((item) => {
      arrCodes.push(item.code)
    })
    values.pdf = fileList.map((file) => {
      return file[0] && file[0]._id ? file[0]._id : file[0] && file[0].response ? file[0].response.file._id : undefined;
    })
    values.statusColorNumber = colorIndex;
    onSubmit(values, arrCodes);
  };
  let tableRowColors = [undefined, '#dff0d8', '#d9edf7', '#fcf8e3', '#f2dede'];


  useEffect(() => {
    let colors = [...colorIndex]
    if(!data.statusColorNumber || (data.statusColorNumber && data.statusColorNumber.length === 0)) {
      for (let i = 0; i < data.order.length; i++) {
        colors[i] = 0;
      }
    } else {
      for(let i=0; i < data.statusColorNumber.length; i++){
        colors[i] = data.statusColorNumber[i];
      }
    }

    setColorIndex(colors);
  }, [])

  const colorHandler = (index) => {
    const color = [...colorIndex]
    if(color[index] < tableRowColors.length - 1){
      color[index]++
    } else {
      color[index] = 0;
    }
    setColorIndex(color);
  }

  const columns = [
    {
      key: '1',
      title: 'šifra',
      dataIndex: 'code',
      render(text, record, index) {
        return {
          props: {
            style: { background:  tableRowColors[colorIndex[index]] },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      key: '2',
      title: 'proizvod',
      dataIndex: 'name',
      render(text, record, index) {
        return {
          props: {
            style: { background:  tableRowColors[colorIndex[index]] },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
       key: '3',
       title: 'pakovanje',
       dataIndex: 'package',
       render(text, record, index) {
         return {
          props: {
            style: { background:  tableRowColors[colorIndex[index]] },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      key: '4',
      title: 'cena bez pdv-a',
      dataIndex: 'price',
      render(text, record, index) {
        return {
          props: {
            style: { background: tableRowColors[colorIndex[index]] },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      key: '5',
      title: 'količina',
      dataIndex: 'quantityNumber',
      render(text, record, index) {
        return {
          props: {
            style: { background: tableRowColors[colorIndex[index]] },
          },
          children: <div>{text}</div>,
        };
      },
    },

    {
      key: '6',
      title: 'zbir',
      dataIndex: 'total',
      render(text, record, index) {
        return {
          props: {
            style: { background: tableRowColors[colorIndex[index]] },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      key: '7',
      title: 'status',
      render(text, record, index) {
        return {
          props: {
            style: { background: tableRowColors[colorIndex[index]] },
          },
          children: <div><Button onClick={() => colorHandler(index)}>Promeni status</Button></div>,
        };
      },
    }
  ];

  let fullName = '';
  if (data.user) {
    fullName += data.user.firstName ? data.user.firstName + ' ' : '';
    fullName += data.user.lastName ? data.user.lastName : '';
  }

  const pdv = (data.deliveryPrice + Number(data.totalPrice)) * 0.2;
  data.order.map(item => {
    item.total =  (item.price * item.quantity * (data.user && data.user.legalType === 'private' ? 1 : item.packageQuantity)).toFixed(2);
    item.quantityNumber = data.user && data.user.legalType === 'corporate' ? item.packageQuantity * item.quantity : item.quantity;
    item.package = '1/' + item.packageQuantity;
    item.price = Number(item.price).toFixed(2);
    return item;
  })

  const inputHandler = (e, i) => {
    let ref = [...reference];
    ref[i] = e.target.value;
    setReference(ref);
  }

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          layout={formItemLayout}
          initialValues={initialValues}
          onFinish={(values) => onFinish(values)}
          // layout='vertical'
          form={form}>
          <Row type='flex' gutter={16}>
            <Col xs={24} md={8}>
              <div className='panel-body'>
                <Card title='Informacije o porudžbini' bordered={false}>
                  <p>
                    <Text strong>Broj porudžbine: </Text>
                    {data.orderNumber ? data.orderNumber : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Kupac: </Text>
                    {fullName !== '' ? fullName : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Grad: </Text>
                    {data.user && data.user.city ? data.user.city : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Ukupna cena: </Text>
                    {data.totalPrice ? (Number(data.totalPrice) + data.deliveryPrice /*+ pdv */).toFixed(2) + " RSD" : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Vreme naručivanja: </Text>
                    {data.createdAt ? moment(data.createdAt).format('DD.MM.YYYY. HH:mm') : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>TXT fajl: </Text>
                    {data.fileName ? <a style={{color: '#337ab7'}} onClick={() => downloadFile(data.fileName)}>Preuzmi</a> : 'Nema podataka'}
                  </p>
                </Card>
                <Row type='flex'>
                  <Col xs={24} md={8}>
                    <Form.Item style={{marginRight: '20px' }} label='Referenca' name={['reference', 0]}>
                      <Input onChange={(e) => inputHandler(e, 0)}/>
                    </Form.Item>
                    <Form.Item style={{marginRight: '20px' }} name='order-pdf'>
                      <UploadFile fileList={fileList[0]} onChangeHandler={handleFileChange} index={0} token={token} fileType={'order-pdf'} fileSavePath={`public/orders/pdf/`} fileName={reference[0]}/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item style={{marginRight: '20px' }} label='Faktura1' name={['reference', 1]}>
                      <Input onChange={(e) => inputHandler(e, 1)}/>
                    </Form.Item>
                    <Form.Item name='order-pdf'>
                      <UploadFile fileList={fileList[1]} onChangeHandler={handleFileChange} index={1} token={token} fileType={'order-pdf'} fileSavePath={`public/orders/pdf/`} fileName={reference[1]}/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item style={{marginRight: '20px' }} label='Faktura2' name={['reference', 2]}>
                      <Input onChange={(e) => inputHandler(e, 2)}/>
                    </Form.Item>
                    <Form.Item name='order-pdf'>
                      <UploadFile fileList={fileList[2]} onChangeHandler={handleFileChange} index={2} token={token} fileType={'order-pdf'} fileSavePath={`public/orders/pdf/`} fileName={reference[2]}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label='Napomena' name='message'>
                  <TextArea rows={4}/>
                </Form.Item>
                <Form.Item label='Status porudžbine' name='status'>
                  <Select>
                    {orderStatus.map((item, index) => (
                      <Option key={index} value={item}>{item}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <div className='text-right'>
                  <Button type='primary' htmlType='submit'>
                    Izmeni
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Table
                  pagination={false}
                  rowKey='_id'
                  style={{ margin: '0px 0px 40px 0px' }}
                  columns={columns}
                  dataSource={data.order}
                  bordered
                  rowClassName={'row-color'}
                />
                  <p style={{ fontWeight: 'bold' }}>Ukupno:</p>
                  <p>Ukupno: {data.totalPrice ? Number(data.totalPrice).toFixed(2) + ' RSD' : 'Nema podataka'}</p>
                  <p>Prevoz: {data.deliveryPrice ? data.deliveryPrice.toFixed(2) + ' RSD' : 'Nema podataka'}</p>
                  <p>PDV: {pdv.toFixed(2) + ' RSD'}</p>
                  <p>Za uplatu: {(Number(data.totalPrice) + data.deliveryPrice /* pdv */).toFixed(2) + ' RSD'}</p>
                  <p>Avansna uplata? {data.deposit ? 'Da' : 'Ne'}</p>
                {/*</div>*/}
                <div className='text-right'>
                  <Button type='primary' onClick={createInvoice}>
                    Kreiraj fakturu
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default OrderForm;
