import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Tabs,
  Upload,
  message,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import UploadBox from '../base/UploadBox';

const { Option } = Select;
const formInit = {
  name: '',
  phone: [],
  employees: [
    {
      firstName: '',
      lastName: '',
      function: '',
      image: undefined,
    },
  ],
  gallery: [],
  type: undefined,
  country: undefined,
};

const StoreForm = ({
  isNew,
  data,
  image,
  onSubmit,
  SERVER_URL,
  token,
  gallery,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  let initialValues;
  const prevForm = sessionStorage.getItem('storeForm');
  const [employees, setEmployees] = useState([{}]);
  const [imageUrl, setImageUrl] = useState([]);
  const [imageIds, setImageIds] = useState([]);
  const [loading, setLoading] = useState([]);
  let [phone, setPhone] = useState([{}]);
  // let [currentIndex, setCurrentIndex] = useState(0);

  if (data) {
    initialValues = { ...formInit, ...data };
  }
  if (prevForm) {
    initialValues = JSON.parse(prevForm);
  }
  if (gallery && gallery.length > 0) form.setFieldsValue({ gallery });

  useEffect(() => {
    let phoneArr = [];
    if (data && data.phone && data.phone.length > 0) {
      for (let i = 0; i < data.phone.length; i++) {
        phoneArr.push({});
      }
      setPhone(phoneArr);
    }
  }, []);

  useEffect(() => {
    if (data && data.employees) {
      if (prevForm) {
        data.employees = JSON.parse(prevForm).employees;
      }
      let images = data.employees.map((item) => item.image && item.image.url);
      let ids = data.employees.map((item) => item.image && item.image._id);
      setImageUrl(images);
      setImageIds(ids);
      setEmployees(data.employees);
    }
  }, [data]);

  const onFinish = async (values, isNew) => {
    sessionStorage.removeItem('storeForm');
    imageIds.map((id, index) => {
      values.employees[index].image = id;
    });
    onSubmit(values, isNew);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Možete dodati jedino JPG/PNG fajlove!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Slika mora biti manja od 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info, index) => {
    if (info.file.status === 'uploading') {
      loading[index] = true;
      setLoading(loading);
      return;
    }
    if (info.file.status === 'done') {
      let values = form.getFieldsValue();
      let urls = [...imageUrl];
      urls[index] = info.file.response.image.url;
      imageIds[index] = info.file.response.image._id;
      setImageUrl(urls);
      setImageIds(imageIds);
      loading[index] = false;
      setLoading(loading);
      values.employees[index].image = info.file.response.image;
      form.setFieldsValue(values);
    }
  };

  const addPhone = () => {
    setPhone([...phone, {}]);
  };

  const removePhone = () => {
    const p = [...phone];
    let values = form.getFieldsValue();
    if (p.length > 1) {
      p.pop();
      values.phone.pop();
      setPhone(p);
      form.setFieldsValue(values);
    }
  };
  const galleryHandler = () => {
    const values = form.getFieldsValue();
    sessionStorage.setItem('storeForm', JSON.stringify(values));
    history.push(
      `/admin/gallery/edit-content?gallery=true&limit=5&fromPage=${isNew ? 'new-store' : 'edit-store/' + data._id
      }${values.gallery
        ? '&imageIds=' + values.gallery.map((img) => img._id).join(',')
        : ''
      }`,
    );
  };

  const removeImage = (index) => {
    const formValues = form.getFieldsValue();
    formValues.employees[index].image = null;
    form.setFieldsValue(formValues);
    const ids = [...imageIds];
    const images = [...imageUrl];
    images[index] = undefined;
    ids[index] = null;
    setImageIds(ids);
    setImageUrl(images);
  };

  let currentGallery = form.getFieldValue('gallery');
  const countries = ['Srbija', 'Mađarska', 'Hrvatska'];

  return (
    <div className="panel panel-body">
      <div className="panel-body">
        <Form
          className="form-horizontal"
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout="vertical"
          form={form}
        >
          <Row type="flex" gutter={16}>
            <Col xs={24} md={16}>
              <div className="panel-body">
                <Form.Item
                  label="Ime prodavnice"
                  rules={[
                    { required: true, message: 'Unesite ime prodavnice!' },
                  ]}
                  name="name"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Tip"
                  rules={[
                    { required: true, message: 'Izaberite tip prodavnice!' },
                  ]}
                  name="type"
                >
                  <Select>
                    <Option value="HIMTEX">HIMTEX PRODAVNICA</Option>
                    <Option value="HOGERT">HÖGERT® PRODAVNICA</Option>
                    <Option value="HOGERT HTZ">HÖGERT® HTZ PRODAVNICA</Option>
                    <Option value="GTV LET">GTV® LED PRODAVNICA</Option>
                    <Option value="BLUE DOLPHIN">BLUE®DOLPHIN PRODAVNICE PRODAVNICA</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Adresa"
                  rules={[
                    { required: true, message: 'Unesite adresu prodavnice!' },
                  ]}
                  name="address"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Grad"
                  rules={[{ required: true, message: 'Unesite grad!' }]}
                  name="city"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Država"
                  rules={[{ required: true, message: 'Izaberite državu!' }]}
                  name="country"
                >
                  <Select>
                    {countries.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Geografska širina"
                  rules={[
                    {
                      required: true,
                      message: 'Unesite geografsku širinu prodavnice!',
                    },
                  ]}
                  name="latitude"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Geografska dužina"
                  rules={[
                    {
                      required: true,
                      message: 'Unesite geografsku dužinu prodavnice!',
                    },
                  ]}
                  name="longitude"
                >
                  <Input />
                </Form.Item>
                {phone.length > 0 &&
                  phone.map((item, index) => (
                    <Form.Item
                      label={`Telefon ${index + 1}`}
                      rules={[
                        {
                          required: true,
                          message: 'Unesite broj telefona prodavnice!',
                        },
                      ]}
                      name={['phone', index]}
                    >
                      <Input />
                    </Form.Item>
                  ))}
                <div style={{ marginBottom: '20px' }} className="text-right">
                  <Button style={{ marginRight: '10px' }} onClick={removePhone}>
                    Obriši telefon
                  </Button>
                  <Button onClick={addPhone}>Dodaj telefon</Button>
                </div>
                <div className="panel panel-primary">
                  <div className="panel-heading">
                    <h4 className="panel-title">Slike prodavnice</h4>
                  </div>
                  <Form.Item name="gallery" valuePropName="image">
                    <div
                      className="panel-body"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      {currentGallery &&
                        currentGallery.length > 0 &&
                        currentGallery.map((item, index) => (
                          <UploadBox
                            key={index}
                            handler={() => { }}
                            image={item.url}
                            preview
                          />
                        ))}
                      <UploadBox handler={galleryHandler} />
                    </div>
                  </Form.Item>
                </div>
                <div className="text-right">
                  <Button type="primary" htmlType="submit">
                    {isNew ? 'Dodaj' : 'Izmeni'} prodavnicu
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={24} md={8}>
              <div className="panel-body">
                {employees.map((item, index) => (
                  <div key={index}>
                    <Form.Item
                      rules={[
                        { required: false, message: 'Unesite ime zaposlenog!' },
                      ]}
                      label="Ime zaposlenog"
                      name={['employees', index, 'firstName']}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {
                          required: false,
                          message: 'Unesite prezime zaposlenog!',
                        },
                      ]}
                      label="Prezime zaposlenog"
                      name={['employees', index, 'lastName']}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {
                          required: false,
                          message: 'Unesite funkciju zaposlenog!',
                        },
                      ]}
                      label="Funkcija zaposlenog"
                      name={['employees', index, 'function']}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name={['employees', index, 'image']}>
                      <Upload
                        name="image"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={`${SERVER_URL}/upload-local-image`}
                        headers={{
                          type: 'employee',
                          resize: true,
                          Authorization: `Bearer ${token}`,
                          uri: 'public/images/employees/',
                          height: 100,
                        }}
                        beforeUpload={beforeUpload}
                        onChange={(info) => handleChange(info, index)}
                      >
                        {imageUrl[index] ? (
                          <img
                            src={`${SERVER_URL}/${imageUrl[index]}`}
                            alt="avatar"
                            style={{ width: '100%' }}
                          />
                        ) : (
                          <div>
                            {loading[index] ? (
                              <LoadingOutlined />
                            ) : (
                              <PlusOutlined />
                            )}
                            <div style={{ marginTop: 8 }}>Upload</div>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                    <Button
                      style={{ marginBottom: '20px' }}
                      onClick={() => removeImage(index)}
                    >
                      Obriši sliku
                    </Button>
                  </div>
                ))}
                <div
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  className="text-right"
                >
                  {employees.length > 1 && (
                    <Button
                      style={{ margin: '0px 10px 0px 10px' }}
                      type="primary"
                      onClick={() => {
                        let index = employees.length - 1;
                        let arr = [...employees];
                        arr.splice(index, 1);
                        let ids = [...imageIds];
                        ids.splice(index, 1);
                        setImageIds(ids);
                        setEmployees(arr);
                      }}
                    >
                      Obriši zaposlenog
                    </Button>
                  )}
                  <Button
                    type="primary"
                    onClick={() => setEmployees([...employees, {}])}
                  >
                    Dodaj zaposlenog
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default StoreForm;
