import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import OrderForm from '../../components/forms/OrderForm';

const EditData = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [order, fetchOrder] = useAxios('', {}, currentuser.data.token, 'get');
  const [fileList, setFileList] = useState([]);
  const { orderId } = props.match.params;

  useEffect(() => {
    const files = [...fileList];
    for (let i = 0; i < 3; i++) {
      files[i] = new Array();
    }
    setFileList(files);
  }, []);

  useEffect(() => {
    if (orderId) fetchOrder(`${SERVER_URL}/orders/${orderId}`, {});
    let files = [...fileList];
    const getFiles = async () => {
      if (
        orderId &&
        !order.isLoading &&
        order.data &&
        currentuser.language &&
        order.data.pdf
      ) {
        const sort = [0, 1, 2];
        const promise = sort.map(async (num) => {
          const index = order.data.pdf.findIndex(
            (item) => item.reference === num,
          );
          if (index >= 0) {
            let url = await getPdf(order.data.pdf[index].localPath);
            files[num].push({
              name: order.data.pdf[index].originalname,
              _id: order.data.pdf[index]._id,
              uid: '-1',
              status: 'done',
              url,
            });
          }
        });
        await Promise.all(promise);
        setFileList(files);
      }
    };
    getFiles();
  }, [fetchOrder, orderId, order]);

  const onSubmit = async (formData, codes) => {
    // Getting data by codes
    let dataIds = [];
    try {
      const resData = await Axios.get(
        `${SERVER_URL}/data-by-codes?codes=${JSON.stringify(codes)}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      resData.data.map((data) => dataIds.push(data._id));
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
    const method = 'put';
    const route = `${SERVER_URL}/orders/${orderId}`;
    let fileName = fileList
      .map((item) => item[0] && item[0].name)
      .filter((item) => item !== undefined);
    try {
      await Axios[method](
        route,
        {
          status: formData.status,
          statusColorNumber: formData.statusColorNumber,
          fileName,
          pdf: formData.pdf.filter((item) => item !== undefined),
          message: formData.message,
          dataIds,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      notification.success({
        message: `Order updated.`,
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.href = `/admin/edit-order/${orderId}`;
      }, 1200);
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const createInvoice = async () => {
    const method = 'post';
    const route = `${SERVER_URL}/invoices`;
    try {
      await Axios[method](
        route,
        { orderId: orderId, dateOfPayment: order.data.createdAt },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: `Invoice created.`,
        placement: 'bottomRight',
      });
      history.push('/admin/orders');
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const getPdf = async (path) => {
    let url = '';
    await Axios.get(`${SERVER_URL}/get-pdf?fileName=${path}`, {
      withCredentials: false,
      responseType: 'arraybuffer',
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    })
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        url = URL.createObjectURL(blob);
      })
      .catch((err) => console.log(err.message));
    return url;
  };

  const onChangeHandler = async (file, index) => {
    let files = [...fileList];
    if (file.status === 'uploading') {
      files[index] = files[index].filter((item) => item.status === 'done');
      files[index].push(file);
      setFileList(files);
    } else if (file.status === 'done') {
      file.name = file.response.file.originalname;
      const url = await getPdf(file.response.file.localPath);
      files[index] = files[index].filter((item) => item.status === 'done');
      files[index].push({ ...file, url });
      setFileList(files);
    } else if (
      file.status === 'removed' ||
      (file.response && file.response.status === 'removed')
    ) {
      let fileId =
        file.response && file.response.file ? file.response.file._id : file._id;
      files[index] = files[index].filter((item) => {
        if (item._id) {
          return item._id !== fileId;
        } else if (item.response && item.response.file) {
          return item.response.file._id !== fileId;
        }
      });
      setFileList(files);
      const check =
        order.data &&
        order.data.documents &&
        order.data.documents.some((item) => {
          if (item._id) {
            return item._id === fileId;
          } else if (item.response && item.response.file) {
            return item.response.file._id === fileId;
          }
        });
      if (!orderId || (orderId && !check)) {
        await Axios.get(`${SERVER_URL}/pdfs/${fileId}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        }).then(async (res) => {
          await Axios.get(
            `${SERVER_URL}/delete-pdf?file=${JSON.stringify(res.data)}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
        });
      }
    }
  };

  const downloadFile = async (path) => {
    let textFile;
    try {
      const textFileResponse = await Axios.get(
        `${SERVER_URL}/get-file?fileName=public/orders/${path}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      textFile = textFileResponse.data;
    } catch (error) {
      console.log(error);
    }
    const blob = new Blob([textFile], { type: 'text/json;charset=utf-8' });

    const blobUrl = URL.createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.href = blobUrl;
    anchor.target = '_blank';
    anchor.download = path;
    anchor.click();
    URL.revokeObjectURL(blobUrl);
  };
  const isDataFetched =
    !order.isLoading &&
    Object.keys(order.data).length > 0 &&
    currentuser.language;
  return (
    <div style={{ padding: '8px' }}>
      <div className="actions-block">
        <Link to="/admin/orders">
          <Button type="primary">Sve porudžbine</Button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined
              spin
              style={{ fontSize: '3rem', marginTop: '5rem' }}
            />
          </div>
        )}
        {orderId && !order.isError && order.data && isDataFetched && (
          <OrderForm
            data={order.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            token={currentuser.data.token}
            createInvoice={createInvoice}
            downloadFile={downloadFile}
            handleFileChange={onChangeHandler}
            fileList={fileList}
          />
        )}
      </div>
    </div>
  );
};

export default EditData;
