import React, {useEffect, useContext, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import ReclamationForm from "../../components/forms/ReclamationForm";

const EditReclamation = (props) => {
	const history = useHistory();
	const currentuser = useContext(UserContext);
	const [reclamation, fetchReclamation] = useAxios('', {}, currentuser.data.token, 'get');
	const { reclamationId } = props.match.params;
	const [modalVisible, setModalVisible] = useState(false);

	useEffect(() => {
		if (reclamationId) fetchReclamation(`${SERVER_URL}/reclamations/${reclamationId}`, {});
	}, [fetchReclamation, reclamationId]);

	const onSubmit = async (formData) => {
		const method = 'put';
		const route = `${SERVER_URL}/reclamations/${reclamationId}`;
		try {
			await Axios[method](route, { ...formData, dateAccepted: formData.status === 'Primljena' ? new Date() : reclamation.data.dateAccepted, dateResolve: formData.status === 'Razrešena' ? new Date() : reclamation.data.dateResolve }, {
				withCredentials: false,
				headers: { Authorization: `Bearer ${currentuser.data.token}` },
			});
			notification.success({
				message: `Reklamacija izmenjena.`,
				placement: 'bottomRight',
			});
			history.push('/admin/reclamations');
		} catch (error) {
			const msg = error.response ? error.response.data.message : error.message ? error.message : error;
			console.log(msg);
			notification.error({
				message: msg,
				placement: 'bottomRight',
			});
		}
	};

	const onSubmitModal = async (formData, index) => {
		try {
			await Axios.put(`${SERVER_URL}/reclamation-items/${reclamationId}`, { index, form: {...formData, reclamationResolutionMade: formData.resolution && formData.resolution !== reclamation.data.resolution ? new Date() : reclamation.data.reclamationResolutionMade }}, {
				withCredentials: false,
				headers: { Authorization: `Bearer ${currentuser.data.token}` },
			})
			if(formData.resolution !== reclamation.data.resolution){
				await Axios.post(`${SERVER_URL}/reclamation-send-email`, { email: reclamation.data.userId.email, resolution: formData.resolution, product: reclamation.data.reclamation[index].productName  }, {
					withCredentials: false,
					headers: { Authorization: `Bearer ${currentuser.data.token}` },
				})
			}
			notification.success({
				message: `Reklamacija izmenjena.`,
				placement: 'bottomRight',
			});
			setModalVisible(!modalVisible);
			setTimeout(() => {
				window.location.reload();
			}, 1200);
		} catch (error) {
			const msg = error.response ? error.response.data.message : error.message ? error.message : error;
			console.log(msg);
			notification.error({
				message: msg,
				placement: 'bottomRight',
			});
		}
	}

	const changeModalVisible = () => {
		setModalVisible(!modalVisible)
	}

	const isDataFetched = !reclamation.isLoading && Object.keys(reclamation.data).length > 0 && currentuser.language;

	return (
		<div style={{ padding: '8px' }}>
			<div className='actions-block'>
				<Link to='/admin/reclamations'>
					<Button type='primary'>Sve reklamacije</Button>
				</Link>
			</div>

			<div>
				{!isDataFetched && (
					<div style={{ textAlign: 'center' }}>
						<LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
					</div>
				)}
				{reclamationId && !reclamation.isError && reclamation.data && isDataFetched && (
					<ReclamationForm
						data={reclamation.data}
						language={currentuser.language}
						onSubmit={onSubmit}
						onSubmitModal={onSubmitModal}
						token={currentuser.data.token}
						serverUrl={SERVER_URL}
						modalVisible={modalVisible}
						changeModalVisible={changeModalVisible}
					/>
				)}
			</div>
		</div>
	);
};

export default EditReclamation;
