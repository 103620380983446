import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button, Input } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { Table } from '../../components/tables';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'avatar',
  'address',
  'zip',
  'deliveryAddress',
  'deliveryAddressSame',
  'idNumber',
  'website',
  'activity',
  'legalForm',
  'deliveryCity',
  'taxId',
  'supplierDiscount',
  'street',
  'municipality',
  'place',
  'streetDelivery',
  'placeDelivery',
  'municipalityDelivery',
  'streetNumber',
  'streetNumberDelivery',
];

const Users = (props) => {
  const currentuser = useContext(UserContext);
  const [users, fetchUsers] = useAxios('', [], currentuser.data.token, 'get');
  const [filteredUsers, setFilteredUsers] = useState([]);
  let filter = {};

  if (props.match.path.includes('users-private')) {
    filter = { legalType: 'private' };
  } else if (props.match.path.includes('users-corporate')) {
    filter = { legalType: 'corporate' };
  }

  useEffect(() => {
    if (currentuser.data.role === 'commercialist') {
      filter['commercialist'] =
        currentuser.data.firstName + ' ' + currentuser.data.lastName;
    }
    fetchUsers(`${SERVER_URL}/users?filter=${JSON.stringify(filter)}`, []);
    setFilteredUsers(users);
  }, [fetchUsers, users]);

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const searchData = (value) => {
    let filtered = JSON.parse(JSON.stringify(users));
    filtered.data.items = users.data.items.filter(
      (item) =>
        item.email.toString().toLowerCase().includes(value.toLowerCase()) ||
        (item.firstName + ' ' + item.lastName)
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
    );
    setFilteredUsers(filtered);
  };

  const signUp = async (id) => {
    try {
      await Axios.post(
        `${SERVER_URL}/log-in-admin`,
        { userId: id },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      if (process.env.NODE_ENV === 'development') {
        window.location.href = 'http://localhost:6001';
      } else if (process.env.NODE_ENV === 'production') {
        window.location.href = 'https://himtexcompany-staging.concordsofttest.com';
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  let columnKeys;
  if (users.data.items && users.data.items.length > 0) {
    const keys = Object.keys(users.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (
    filteredUsers.data &&
    filteredUsers.data.items &&
    filteredUsers.data.items.length > 0
  ) {
    tableData = filteredUsers.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      if (Array.isArray(item.phone)) item.phone = item.phone[0];
      return item;
    });
  }
  return (
    <div style={{ padding: '8px' }}>
      <div className="actions-block">
        <Link to="/admin/new-user">
          <Button type="primary">Novi korisnik</Button>
        </Link>
        <div className="text-right">
          <Input.Search
            onSearch={searchData}
            allowClear
            style={{ width: '100%' }}
          />
        </div>
      </div>
      {/* <div style={{ textAlign: 'center', overflowX:'auto' }}> */}
      <div className="table-container">
        {filteredUsers.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: '3rem', marginTop: '5rem' }}
          />
        )}
        {!filteredUsers.isLoading &&
          filteredUsers.data &&
          filteredUsers.data.items &&
          filteredUsers.data.items.length > 0 && (
            <Table
              signUp={signUp}
              data={tableData}
              deleteHandler={deleteUserHandler}
              columnKeys={columnKeys}
              title="User"
              editPath="/admin/edit-user/"
              viewPath="/admin/view-user/"
            />
          )}
        {!filteredUsers.isLoading &&
          filteredUsers.data &&
          filteredUsers.data.items &&
          filteredUsers.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default Users;
