import React, { useContext, useEffect } from 'react';
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { SERVER_URL } from "../../config";
import { LoadingOutlined } from "@ant-design/icons";
import Table from "../../components/tables/RequestsTable";

const TABLE_COLUMN_KEYS = ['_id', '__v', 'refund', 'userId', 'orderId', 'orderNumber', 'dateAccepted', 'dateResolve', 'dateSubmitted'];

const Requests = () => {
  const currentuser = useContext(UserContext);
  const [requests, fetchRequests] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchRequests(`${SERVER_URL}/requests`, []);
  }, [fetchRequests]);

  let tableData = [];
  if (requests.data.items && requests.data.items.length > 0) {
    tableData = requests.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      // item.orderNumber = item.orderId ? item.orderId.orderNumber : '';
      // item.user = item.userId ? item.userId.firstName + ' ' + item.userId.lastName : '';
      return item;
    });
  }
  return (
    <div style={{ padding: '8px' }}>
      <div style={{ textAlign: 'center' }}>
        {requests.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!requests.isLoading && requests.data.items && requests.data.items.length > 0 && (
          <Table data={tableData} title='Requests' viewPath='/admin/view-request/' />
        )}
        {!requests.isLoading && requests.data.items && requests.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default Requests;
