import React, {useContext, useEffect} from 'react';
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { Button, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import BrandFrom from "../../components/forms/BrandForm";
import { SERVER_URL } from "../../config";
import queryString from 'query-string';
import Axios from "axios";

const EditBrand = (props) => {
    const history = useHistory();
    const currentuser = useContext(UserContext);
    const [brand, fetchBrand] = useAxios('', {}, currentuser.data.token, 'get');
    const [brandImage, fetchbrandImage] = useAxios('', null, currentuser.data.token, 'get');
    const { brandId } = props.match.params;
    const { imgId } = queryString.parse(props.location.search);

    useEffect(() => {
        if (brandId) fetchBrand(`${SERVER_URL}/brands/${brandId}`, {});
        if (imgId) fetchbrandImage(`${SERVER_URL}/images/${imgId}`, {});
    }, [fetchBrand, fetchbrandImage, brandId, imgId]);

    const onSubmit = async (formData, isNew) => {
        const method = isNew ? 'post' : 'put';
        const route = isNew ? `${SERVER_URL}/brands` : `${SERVER_URL}/brands/${brandId}`;
        try {
            await Axios[method](route, formData, {
                withCredentials: false,
                headers: { Authorization: `Bearer ${currentuser.data.token}` },
            });

            notification.success({
                message: `Baner ${isNew ? 'kreiran.' : 'izmenjen.'}`,
                placement: 'bottomRight',
            });
            history.push('/admin/brands');
        } catch (error) {
            const msg = error.response ? error.response.data.message : error.message ? error.message : error;
            console.log(msg);
            notification.error({
                message: msg,
                placement: 'bottomRight',
            });
        }
    }
    const isDataFetched = !brand.isLoading && brand.data && !brandImage.isLoading && currentuser.language;

    return(
        <div style={{ padding: '8px' }}>
            <div className='actions-block'>
                <Link to='/admin/brands'>
                    <Button type='primary'>Svi baneri</Button>
                </Link>
            </div>

            <div>
                {!isDataFetched && (
                    <div style={{ textAlign: 'center' }}>
                        <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
                    </div>
                )}
                {!brandId && isDataFetched && (
                    <BrandFrom
                        isNew={true}
                        image={brandImage.data}
                        language={currentuser.language}
                        onSubmit={onSubmit}
                        SERVER_URL={SERVER_URL}
                        token={currentuser.data.token}
                    />
                )}
                {brandId && !brand.isError && brand.data && isDataFetched && (
                    <BrandFrom
                        isNew={false}
                        data={brand.data}
                        image={brandImage.data}
                        language={currentuser.language}
                        onSubmit={onSubmit}
                        SERVER_URL={SERVER_URL}
                        token={currentuser.data.token}
                    />
                )}
            </div>
        </div>
    )
}

export default EditBrand;
