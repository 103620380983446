import React, { useContext } from 'react';
import { UserContext } from '../../App';
import { GalleryForm } from '../../components/forms';

const GalleryNewContent = () => {
  const currentuser = useContext(UserContext);

  return (
    <div className="content-wrapper">
      <div className="content">
        <GalleryForm
          newContent={true}
          data={null}
          token={currentuser.data.token}
          imageSavePath="public/images/common/"
          imageType="common"
          imageHeight={500}
        />
      </div>
    </div>
  );
};

export default GalleryNewContent;
