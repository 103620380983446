import React, {useContext, useEffect} from 'react';
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { Button, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import StoreForm from "../../components/forms/StoreForm";
import { SERVER_URL } from "../../config";
import queryString from 'query-string';
import Axios from "axios";

const EditBrand = (props) => {
	const history = useHistory();
	const currentuser = useContext(UserContext);
	const [store, fetchStore] = useAxios('', {}, currentuser.data.token, 'get');
	const [gallery, fetchGallery] = useAxios('', [], currentuser.data.token, 'get');
	const { storeId } = props.match.params;
	const { imgId, imageIds } = queryString.parse(props.location.search);

	useEffect(() => {
		if (storeId) fetchStore(`${SERVER_URL}/stores/${storeId}`, {});
		if (imageIds) fetchGallery(`${SERVER_URL}/images?filter=${JSON.stringify({ _id: { $in: imageIds.split(',') } })}&select=url -uploadedBy`, []);
	}, [fetchStore, storeId, fetchGallery, imageIds]);

	const onSubmit = async (formData, isNew) => {
		const method = isNew ? 'post' : 'put';
		const route = isNew ? `${SERVER_URL}/stores` : `${SERVER_URL}/stores/${storeId}`;
		try {
			await Axios[method](route, formData, {
				withCredentials: false,
				headers: { Authorization: `Bearer ${currentuser.data.token}` },
			});

			notification.success({
				message: `Prodavnica ${isNew ? 'kreirana.' : 'izmenjena.'}`,
				placement: 'bottomRight',
			});
			history.push('/admin/stores');
		} catch (error) {
			const msg = error.response ? error.response.data.message : error.message ? error.message : error;
			console.log(msg);
			notification.error({
				message: msg,
				placement: 'bottomRight',
			});
		}
	}
	const isDataFetched = !store.isLoading && store.data && currentuser.language;

	return(
		<div style={{ padding: '8px' }}>
			<div className='actions-block'>
				<Link to='/admin/stores'>
					<Button type='primary'>Sve prodavnice</Button>
				</Link>
			</div>

			<div>
				{!isDataFetched && (
					<div style={{ textAlign: 'center' }}>
						<LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
					</div>
				)}
				{!storeId && isDataFetched && (
					<StoreForm
						isNew={true}
						language={currentuser.language}
						onSubmit={onSubmit}
						SERVER_URL={SERVER_URL}
						token={currentuser.data.token}
						gallery={gallery.data}
					/>
				)}
				{storeId && !store.isError && store.data && isDataFetched && (
					<StoreForm
						isNew={false}
						data={store.data}
						language={currentuser.language}
						onSubmit={onSubmit}
						SERVER_URL={SERVER_URL}
						token={currentuser.data.token}
						gallery={gallery.data}
					/>
				)}
			</div>
		</div>
	)
}

export default EditBrand;
