import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Layout, Menu } from 'antd';
import { useContext } from 'react';
import { ROUTES } from '../../config';
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import { UserContext } from '../../App';
const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const { isCollapsed, setCollapsed, collapsedNav  } = props;
  const currentuser = useContext(UserContext);
  const user = currentuser.data;
  return (
    <Sider breakpoint='lg' className={collapsedNav ? "nav-menu-collapsed" : "nav-display"} trigger={null} collapsible collapsed={isCollapsed}>
      <Link to='/admin' className="logo-desktop">
        <div className='logo'>
          <img src='/logo.png' style={{'maxHeight':'49px'}} alt='Himtex Company' />
        </div>
      </Link>
      <Menu theme='dark' mode='inline' defaultSelectedKeys={[window.location.pathname]}>
        {ROUTES.map((route) => {
          let showInMenu = false;
          if(user){
            if(user.role === 'admin'){
              showInMenu = true;
            } else if(user.role === 'commercialist' && ['Korisnici', 'Porudžbine'].includes(route.label)) {
              showInMenu = true;
            }
          }
          return(
            showInMenu ? <SubMenu
              key={route.label.toLowerCase()}
              title={
                isCollapsed ? (
                  <route.icon/>
                ) : (
                  <span>
                      <route.icon/>
                    {route.label}
                    </span>
                )

              }
          >
            {route.children
              .filter((item) => item.showInMenu)
              .map((item) => (
                <Menu.Item key={item.path}>
                  <Link to={item.path}>{item.label}</Link>
                </Menu.Item>
              ))}
          </SubMenu> : null
        )})}
      </Menu>
    </Sider>
  );
};
export default NavMenu;
