import React, {useContext, useEffect} from 'react';
import {UserContext} from "../../App";
import useAxios from "../../hooks/useAxios";
import {SERVER_URL} from "../../config";
import {LoadingOutlined} from "@ant-design/icons";
import Table from "../../components/tables/RefundTable";

const TABLE_COLUMN_KEYS = ['_id', '__v', 'refund', 'status', 'userId', 'orderId', 'orderNumber', 'user', 'reclamation', 'dateSubmitted', 'dateAccepted', 'dateResolve'];

const Reclamations = () => {
	const currentuser = useContext(UserContext);
	const [reclamations, fetchReclamations] = useAxios('', [], currentuser.data.token, 'get');

	useEffect(() => {
		fetchReclamations(`${SERVER_URL}/reclamations`, []);
	}, [fetchReclamations]);

	let columnKeys;
	if (reclamations.data.items && reclamations.data.items.length > 0) {
		const keys = Object.keys(reclamations.data.items[0]);
		columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
		columnKeys.splice(1, 0, 'orderNumber');
		columnKeys.splice(2, 0, 'status');
		columnKeys.splice(3, 0, 'user');

	}
	let tableData = [];
	if (reclamations.data.items && reclamations.data.items.length > 0) {
		tableData = reclamations.data.items.map((item) => {
			item.createdAt = new Date(item.createdAt).toLocaleString();
			item.updatedAt = new Date(item.updatedAt).toLocaleString();
			item.orderNumber = item.orderId ? item.orderId.orderNumber : '';
			item.user = item.userId ? item.userId.firstName + ' ' + item.userId.lastName : '';
			return item;
		});
	}
	return (
    <div style={{ padding: "8px" }}>
      <div style={{ textAlign: "center" }}>
        {reclamations.isLoading && <LoadingOutlined spin style={{ fontSize: "3rem", marginTop: "5rem" }} />}
        {!reclamations.isLoading && reclamations.data.items && reclamations.data.items.length > 0 && (
          <Table data={tableData} columnKeys={columnKeys} title="Refund" editPath="/admin/edit-reclamations/" />
        )}
        {!reclamations.isLoading && reclamations.data.items && reclamations.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
}

export default Reclamations;
