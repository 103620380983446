import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import PostForm from '../../components/forms/PostForm';
import { UserContext } from '../../App';
import { Button, notification } from 'antd';
import queryString from 'query-string';

const EditPost = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [post, fetchPost] = useAxios('', {}, currentuser.data.token, 'get');
  const [postImage, fetchpostImage] = useAxios(
    '',
    null,
    currentuser.data.token,
    'get',
  );
  const [tags, fetchTags] = useAxios('', null, currentuser.data.token, 'get');
  const { postId } = props.match.params;
  const { imgId } = queryString.parse(props.location.search);

  const [categories, fetchCategories] = useAxios(
    '',
    [],
    currentuser.data.token,
    'get',
  );

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/categories`, []);
    if (postId) fetchPost(`${SERVER_URL}/posts/${postId}`, {});
    if (imgId) fetchpostImage(`${SERVER_URL}/images/${imgId}`, {});
    fetchTags(`${SERVER_URL}/tags`, []);
  }, [fetchPost, fetchpostImage, postId, imgId, fetchTags, fetchCategories]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew
      ? `${SERVER_URL}/posts`
      : `${SERVER_URL}/posts/${postId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Objava ${isNew ? 'kreirana.' : 'izmenjena.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin/posts');
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };
  const isDataFetched =
    !post.isLoading &&
    post.data &&
    currentuser.language &&
    !tags.isLoading &&
    tags.data &&
    !postImage.isLoading;
  return (
    <div style={{ padding: '8px' }}>
      <div className="actions-block">
        <Link to="/admin/posts">
          <Button type="primary">Sve objave</Button>
        </Link>
      </div>
      <div>
        {!postId && isDataFetched && (
          <PostForm
            isNew={true}
            // data={post.data}
            image={postImage.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            tags={tags.data.items}
            categories={categories.data}
          />
        )}
        {postId && !post.isError && post.data && isDataFetched && (
          <PostForm
            isNew={false}
            data={post.data}
            image={postImage.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            tags={tags.data.items}
            categories={categories.data}
          />
        )}
      </div>
    </div>
  );
};

export default EditPost;
