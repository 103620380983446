import React from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Input, Select, /* DatePicker, */ Button, Form, Row, Col } from 'antd';
import UploadBox from '../base/UploadBox';
import UploadFile from '../base/UploadFile';

const { Option } = Select;

const formInit = {
  _id: null,
  title: {},
  file: null,
  image: null,
};

const PricelistForm = ({ isNew, data, image, onSubmit, language, token, onChangeHandler, file, path, getPdf }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  let initialValues = { ...formInit, ...data };
  let documentForm;
  let documentType;
  if (path.includes('edit-pricelist') || path.includes('new-pricelist')) {
    documentForm = 'pricelistForm';
    documentType = 'pricelist';
  } else if (path.includes('edit-catalog') || path.includes('new-catalog')) {
    documentForm = 'catalogForm';
    documentType = 'catalog';
  }
  const prevForm = sessionStorage.getItem(documentForm);

  if (prevForm) {
    initialValues = JSON.parse(prevForm);
  }
  let documentId = file.map(item => {
    if (item._id) {
      return item._id;
    } else if (item.response && item.response.file) {
      return item.response.file._id;
    }
  });
  let pageImage;

  if (data && data.image) pageImage = data.image.url;
  if (image && image.url) pageImage = image.url;

  const onFinish = async (values, isNew) => {
    sessionStorage.removeItem(documentForm);
    if (!isNew) {
      values._id = data._id;
      if (image) {
        values.image = image ? image._id : data.image ? data.image._id : undefined;
      }
    } else {
      if (image) {
        values.image = image ? image._id : undefined;
      }
    }
    values.file = documentId[0];
    onSubmit(values, isNew);
  };

  const imageHandler = () => {
    const values = form.getFieldsValue();
    // sessionStorage.setItem(documentForm, JSON.stringify({ title: values.title, file: values.file && values.file._id ? values.file._id : values.file }));
    sessionStorage.setItem(documentForm, JSON.stringify({ title: values.title, file: documentId[0] }));
    const galleryPath = path.split('/')[2];
    history.push(`/admin/gallery/edit-content?fromPage=${isNew ? galleryPath : galleryPath + '/' + data._id}${values.image ? '&imageIds=' + values.image._id.toString() : ''}`);
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}>
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Tabs tabPosition='left' defaultActiveKey={language.default.code}>
                  {language.list.map((lang) => (
                    <Tabs.TabPane tab={lang.code} key={lang.code}>
                      <Form.Item
                        label='Naslov'
                        rules={[{ required: lang.code === language.default.code ? true : false, message: 'Unesite naslov!' }]}
                        name={['title', lang.code]}
                      >
                        <Input />
                      </Form.Item>

                      {/* <Form.Item label='Google Description' name={['googleDescription', lang.code]}>
                        <Input />
                      </Form.Item>

                      <Form.Item label='Google Keywords' name={['keywords', lang.code]}>
                        <Input />
                      </Form.Item>

                      <Form.Item label='Status' name={['status']} rules={[{ required: true, message: 'Please select status!' }]}>
                        <Select>
                          <Option value='ACTIVE'>Active</Option>
                          <Option value='INACTIVE'>Inactive</Option>
                        </Select>
                      </Form.Item> */}
                    </Tabs.TabPane>
                  ))}
                </Tabs>
              </div>
            </Col>

            <Col xs={24} md={8}>
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Slika cenovnika</h4>
                </div>

                <Form.Item name='image' valuePropName='image'>
                  <div className='panel-body'>
                    <UploadBox handler={imageHandler} image={pageImage} />
                  </div>
                </Form.Item>
              </div>
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Dodaj fajl</h4>
                </div>

                <Form.Item name='file'>
                  <div className='panel-body'>
                    <UploadFile getPdf={getPdf} onChangeHandler={onChangeHandler} fileList={file} token={token} fileType={documentType} fileSavePath={`public/${documentType}/`} />
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Dodaj' : 'Izmeni'} {documentType === "pricelist" ? "Cenovnik" : documentType === "catalog" ? "Katalog" : null}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default PricelistForm;
