import React from 'react';
import {Card, Upload, Button, notification, message, Input, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons'
import {SERVER_URL} from "../../config";

const { Search } = Input;

const DocumentationForm = ({ token, onChangeHandler, fileList, checkDataExists, searchDocumentation, sort, onChangeSorting }) => {

    return(
        <div className='dashboard'>
            <div className='card-wrapper'>
                <Card title='Dodaj novu dokumentaciju' bordered={false}>
                    <Search onSearch={searchDocumentation} allowClear style={{ marginBottom: '20px' }} placeholder='Pretraži dokumentaciju'/>
                    <div style={{ marginBottom: '20px' }}>
                        <Radio.Group value={sort}>
                            <Radio onChange={onChangeSorting} value="normal">Nesortirano</Radio>
                            <Radio onChange={onChangeSorting} value="asc">Sortiraj A - Z</Radio>
                            <Radio onChange={onChangeSorting} value="desc">Sortiraj Z - A</Radio>
                        </Radio.Group>
                    </div>
                    <Upload
                        multiple
                        action={`${SERVER_URL}/save-pdf-document`}
                        listType="picture"
                        accept='.pdf'
                        name='pdf-document'
                        headers={{
                            type: 'documentation',
                            timestamp: Date.now().toString(),
                            Authorization: `Bearer ${token}`,
                            uri: 'public/pdf/documentation/',
                        }}
                        fileList={fileList}
                        beforeUpload={async (file) => {
                            const isAllowedFormat = ['application/pdf'].includes(file.type);
                            if (!isAllowedFormat) {
                                notification.error({
                                    message: 'You can only upload PDF file!',
                                    placement: 'bottomRight',
                                });
                            }
                            const code = file.name.split('_')[1].replace('.pdf', '');
                            const existsData = await checkDataExists(code)
                            const isAllowedSize = file.size / 1024 / 1024 < 100;
                            if (!isAllowedSize) {
                                notification.error({
                                    message: 'File must smaller than 100MB!',
                                    placement: 'bottomRight',
                                });
                            }
                            if(!existsData){
                                notification.error({
                                    message: 'Data does not exist.',
                                    placement: 'bottomRight',
                                });
                            }
                            return isAllowedFormat && existsData;
                        }}
                        onChange={({ file }) => {
                            onChangeHandler(file);
                            if (file.status === 'done') {
                                if (file.response && file.response.file) {
                                    message.success(`${file.response.file.modifiedname} file uploaded successfully.`);
                                }
                            } else if (file.status === 'error') {
                                message.error(`${file.response.file.modifiedname} file upload failed.`);
                            }
                        }}
                    >
                       <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                </Card>
            </div>
        </div>
    )
}

export default DocumentationForm;
