import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Data from '../pages/data/Data';
import EditData from '../pages/data/EditData';
import Categories from '../pages/data/Categories';
import Attributes from '../pages/data/Attributes';
import Gallery from '../pages/gallery/Gallery';
import GalleryEditContent from '../pages/gallery/GalleryEditContent';
import GalleryNewContent from '../pages/gallery/GalleryNewContent';
import Post from '../pages/post/Post';
import EditPost from '../pages/post/EditPost';
import Tag from '../pages/tags/Tag';
import EditTag from '../pages/tags/EditTag';
import ImportData from '../pages/importData/importData';
import Languages from '../pages/languages/Languages';
import Orders from '../pages/orders/Orders';
import EditOrder from '../pages/orders/EditOrders';
import Email from '../pages/orders/Email';
import Invoices from '../pages/invoices/Invoice';
import Pages from '../pages/page/Page';
import EditPages from '../pages/page/EditPage';
import Pricelist from '../pages/pricelist/Pricelist';
import EditPricelist from '../pages/pricelist/EditPricelist';
import Catalog from '../pages/catalog/Catalog';
import EditCatalog from '../pages/catalog/EditCatalog';
import Declaration from '../pages/declaration/Declaration';
import EditDeclaration from '../pages/declaration/EditDeclaration';
import EditBrand from '../pages/brands/EditBrand';
import Brands from '../pages/brands/Brand';
import EditSupplier from '../pages/supplier/EditSupplier';
import Supplier from '../pages/supplier/Supplier';
import Refunds from '../pages/refund/Refunds';
import Requests from '../pages/requests/Requests';
import EditRefund from '../pages/refund/EditRefund';
import ViewRequest from '../pages/requests/ViewRequest';
import Reclamations from '../pages/reclamation/Reclamations';
import EditReclamation from '../pages/reclamation/EditReclamation';
import Stores from '../pages/stores/Store';
import EditStore from '../pages/stores/EditStore';
import EditDocumentation from '../pages/documentation/EditDocumentation';
import Slider from '../pages/slider/Sliders';
import EditSlider from '../pages/slider/EditSlider';
import ImageFolder from '../pages/imageFolder/ImageFolder';

import { UserOutlined, ShopOutlined, FileImageOutlined, ReadOutlined, TranslationOutlined, FilePdfOutlined, BookOutlined, FileOutlined, SafetyCertificateOutlined, LineOutlined, UsergroupAddOutlined, WalletOutlined, SlidersOutlined, QuestionCircleOutlined } from '@ant-design/icons';

export default [
  {
    label: 'Korisnici',
    icon: UserOutlined,
    children: [
      {
        label: 'Svi korisnici',
        path: '/admin/users',
        component: Users,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi korisnik',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Maloprodajni kupci',
        path: '/admin/users-private',
        component: Users,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Veleprodajni kupci',
        path: '/admin/users-corporate',
        component: Users,
        allowed: ['admin', 'commercialist'],
        showInMenu: true,
      },
      {
        label: 'New User Image',
        path: '/admin/new-user/:imgId',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit User Image',
        path: '/admin/edit-user/:imgId/:userId',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit User',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'View User',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Galerija',
    icon: FileImageOutlined,
    allowed: ['admin'],
    children: [
      {
        label: 'Galerija',
        path: '/admin/gallery',
        component: Gallery,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Nove slike',
        path: '/admin/gallery/new-content',
        component: GalleryNewContent,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Folderi',
        path: '/admin/image-folders',
        component: ImageFolder,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit Content',
        path: '/admin/gallery/edit-content',
        component: GalleryEditContent,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Proizvodi',
    icon: ShopOutlined,
    children: [
      {
        label: 'Svi proizvodi',
        path: '/admin/data',
        component: Data,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi proizvod',
        path: '/admin/new-data',
        component: EditData,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New Data Image',
        path: '/admin/new-data/:imgId',
        component: EditData,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'New Variation',
        path: '/admin/edit-variation/:dataId',
        component: EditData,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'New Variation',
        path: '/admin/new-variation/:dataId',
        component: EditData,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'New Variation',
        path: '/admin/edit-variation/:imgId/:dataId',
        component: EditData,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit Data Image',
        path: '/admin/edit-data/:imgId/:dataId',
        component: EditData,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit Data',
        path: '/admin/edit-data/:dataId',
        component: EditData,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Kategorije',
        path: '/admin/data-categories',
        component: Categories,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Add/Edit Category',
        path: '/admin/data-categories/:categoryId',
        component: Categories,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Categories',
        path: '/admin/data-categories/:imgId/:categoryId',
        component: Categories,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Atributi',
        path: '/admin/data-attributes',
        component: Attributes,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Blog objave',
    icon: ReadOutlined,
    children: [
      {
        label: 'Sve objave',
        path: '/admin/posts',
        component: Post,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Nova objava',
        path: '/admin/new-post',
        component: EditPost,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New post',
        path: '/admin/new-post/:imgId',
        component: EditPost,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit post',
        path: '/admin/edit-post/:postId',
        component: EditPost,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit post',
        path: '/admin/edit-post/:imgId/:postId',
        component: EditPost,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Svi tagovi',
        path: '/admin/tags',
        component: Tag,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New tag',
        path: '/admin/new-tag',
        component: EditTag,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'New tag',
        path: '/admin/new-tag/:imgId',
        component: EditTag,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit tag',
        path: '/admin/edit-tag/:tagId',
        component: EditTag,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit tag',
        path: '/admin/edit-tag/:imgId/:tagId',
        component: EditTag,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Stranice',
    icon: BookOutlined,
    children: [
      {
        label: 'Sve stranice',
        path: '/admin/Pages',
        component: Pages,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Nova stranica',
        path: '/admin/new-page',
        component: EditPages,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit page',
        path: '/admin/edit-page/:id',
        component: EditPages,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'New page',
        path: '/admin/new-page/:imgId',
        component: EditPages,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit page',
        path: '/admin/edit-page/:imgId/:id',
        component: EditPages,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Prodavnice',
    icon: BookOutlined,
    children: [
      {
        label: 'Sve prodavnice',
        path: '/admin/stores',
        component: Stores,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Nova prodavnica',
        path: '/admin/new-store',
        component: EditStore,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit store',
        path: '/admin/edit-store/:storeId',
        component: EditStore,
        allowed: ['admin'],
        showInMenu: false,
      },
      // {
      //   label: 'New page',
      //   path: '/admin/new-page/:imgId',
      //   component: EditPages,
      //   allowed: ['admin'],
      //   showInMenu: false,
      // },
      // {
      //   label: 'Edit page',
      //   path: '/admin/edit-page/:imgId/:id',
      //   component: EditPages,
      //   allowed: ['admin'],
      //   showInMenu: false,
      // },
    ],
  },
  {
    label: 'Import podataka',
    icon: TranslationOutlined,
    children: [
      {
        label: 'Import podataka',
        path: '/admin/import-data',
        component: ImportData,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Dokumentacija',
    icon: TranslationOutlined,
    children: [
      {
        label: 'Nova dokumentacija',
        path: '/admin/new-documentation',
        component: EditDocumentation,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Porudžbine',
    icon: ShopOutlined,
    children: [
      {
        label: 'Sve porudžbine',
        path: '/admin/orders',
        component: Orders,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Nove VP porudžbine',
        path: '/admin/orders-new-corporate',
        component: Orders,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Obrađene VP porudžbine',
        path: '/admin/orders-processed-corporate',
        component: Orders,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Nove MP porudžbine',
        path: '/admin/orders-new-private',
        component: Orders,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Obrađene MP porudžbine',
        path: '/admin/orders-processed-private',
        component: Orders,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Mejl za recenzije',
        path: '/admin/orders-processed-email',
        component: Email,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit order',
        path: '/admin/edit-order/:orderId',
        component: EditOrder,
        allowed: ['admin'],
        showInMenu: false,
      }
    ],
  },
  {
    label: 'Povrati',
    icon: WalletOutlined,
    children: [
      {
        label: 'Svi povrati',
        path: '/admin/refunds',
        component: Refunds,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit order',
        path: '/admin/edit-refunds/:refundId',
        component: EditRefund,
        allowed: ['admin'],
        showInMenu: false,
      }
    ],
  },
  {
    label: 'Reklamacije',
    icon: WalletOutlined,
    children: [
      {
        label: 'Sve reklamacije',
        path: '/admin/reclamations',
        component: Reclamations,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit order',
        path: '/admin/edit-reclamations/:reclamationId',
        component: EditReclamation,
        allowed: ['admin'],
        showInMenu: false,
      }
    ],
  },
  {
    label: 'Fakture',
    icon: FilePdfOutlined,
    children: [
      {
        label: 'Sve fakture',
        path: '/admin/invoices',
        component: Invoices,
        allowed: ['admin'],
        showInMenu: true,
      }
    ],
  },
  {
    label: 'Zahtevi za obaveštenja',
    icon: QuestionCircleOutlined,
    children: [
      {
        label: 'Svi zahtevi',
        path: '/admin/requests',
        component: Requests,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'View request',
        path: '/admin/view-request/:requestID',
        component: ViewRequest,
        allowed: ['admin'],
        showInMenu: false,
      }
    ],
  },
  {
    label: 'Baneri',
    icon: LineOutlined,
    children: [
      {
        label: 'Svi baneri',
        path: '/admin/brands',
        component: Brands,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi baner',
        path: '/admin/new-brand',
        component: EditBrand,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New brand',
        path: '/admin/new-brand/:imgId',
        component: EditBrand,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit brand',
        path: '/admin/edit-brand/:brandId',
        component: EditBrand,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit brand',
        path: '/admin/edit-brand/:imgId/:brandId',
        component: EditBrand,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Slajder',
    icon: SlidersOutlined,
    children: [
      {
        label: 'Svi slajder',
        path: '/admin/sliders',
        component: Slider,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi slajder',
        path: '/admin/new-slider',
        component: EditSlider,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit sl',
        path: '/admin/edit-slider/:sliderId',
        component: EditSlider,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Deklaracije',
    icon: SafetyCertificateOutlined,
    children: [
      {
        label: 'Sve deklaracije',
        path: '/admin/declarations',
        component: Declaration,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Nova deklaracija',
        path: '/admin/new-declaration',
        component: EditDeclaration,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit post',
        path: '/admin/edit-declaration/:declarationId',
        component: EditDeclaration,
        allowed: ['admin'],
        showInMenu: false,
      }
    ],
  },
  {
    label: 'Dokumenti',
    icon: FileOutlined,
    children: [
      {
        label: 'Svi cenovnici',
        path: '/admin/pricelists',
        component: Pricelist,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi cenovnik',
        path: '/admin/new-pricelist',
        component: EditPricelist,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New pricelist',
        path: '/admin/new-pricelist/:imgId',
        component: EditPricelist,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'New pricelist',
        path: '/admin/edit-pricelist/:id',
        component: EditPricelist,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'New pricelist',
        path: '/admin/edit-pricelist/:imgId/:id',
        component: EditPricelist,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Svi katalozi',
        path: '/admin/catalogs',
        component: Catalog,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi katalog',
        path: '/admin/new-catalog',
        component: EditCatalog,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New catalog',
        path: '/admin/new-catalog/:imgId',
        component: EditCatalog,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'New catalog',
        path: '/admin/edit-catalog/:id',
        component: EditCatalog,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'New pricelist',
        path: '/admin/edit-catalog/:imgId/:id',
        component: EditCatalog,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Dobavljači',
    icon: UsergroupAddOutlined,
    children: [
      {
        label: 'Svi dobavljači',
        path: '/admin/suppliers',
        component: Supplier,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Novi dobavljač',
        path: '/admin/new-supplier',
        component: EditSupplier,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit supplier',
        path: '/admin/edit-supplier/:supplierId',
        component: EditSupplier,
        allowed: ['admin'],
        showInMenu: false,
      }
    ],
  },
  // {
  //   label: 'Languages',
  //   icon: TranslationOutlined,
  //   children: [
  //     {
  //       label: 'Languages',
  //       path: '/admin/languages',
  //       component: Languages,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
];
