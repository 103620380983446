import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Row, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { AttributeTable } from '../../components/tables';
import { AttributeForm, ValueForm } from '../../components/forms';

const Attributes = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [attributes, fetchAttributes] = useAxios('', [], currentuser.data.token, 'get');
  const [edit, setEdit] = useState({ visible: false, attribute: {} });
  const [values, fetchValues] = useAxios('', [], currentuser.data.token, 'get');
  const [editValue, setEditValue] = useState({ visible: false, attrId: null, value: {} });

  useEffect(() => {
    fetchAttributes(`${SERVER_URL}/attributes`, []);
    fetchValues(`${SERVER_URL}/values`, []);
  }, [fetchAttributes, fetchValues]);

  const deleteAttributeHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/attributes/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Atribut je obrisan.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const onSubmitAttribute = async (data, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/attributes` : `${SERVER_URL}/attributes/${edit.attribute._id}`;
    try {
      await Axios[method](route, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Atribut ${isNew ? 'kreiran.' : 'izmenjen.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data-attributes');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const editAttributeHandler = (attrId) => {
    setEditValue({ visible: false, attrId: null, value: {} });
    setEdit({ visible: true, attribute: attributes.data.items.find((a) => a._id === attrId) || {} });
  };

  const deleteValueHandler = async (attrId, valId) => {
    try {
      await Axios.delete(`${SERVER_URL}/values/${valId}?attr=${attrId}`, {
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Vrednost je obrisana.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data-attributes');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molimo pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const onSubmitValue = async (data, attrId, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/values` : `${SERVER_URL}/values/${editValue.value._id}`;
    try {
      await Axios[method](
        route,
        { ...data, attribute: attrId },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: `Vrednost ${isNew ? 'kreirana.' : 'izmenjena.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data-attributes');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const editValueHandler = (attrId, valId) => {
    setEdit({ visible: false, attribute: {} });
    setEditValue({ visible: true, attrId: attrId, value: valId ? values.data.items.find((v) => v._id === valId) : {} });
  };

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Button type='primary' onClick={() => editAttributeHandler(null)}>
          Dodaj atribut
        </Button>
      </div>

      <Row type='flex' gutter={8}>
        <Col xs={24} md={edit.visible || editValue.visible ? 16 : 24}>
          <div className='panel panel-primary' style={{ textAlign: 'center' }}>
            {attributes.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
            {!attributes.isLoading &&
              !attributes.isError &&
              attributes.data.items &&
              attributes.data.items.length > 0 &&
              currentuser &&
              currentuser.language && (
                <AttributeTable
                  attributes={attributes.data.items}
                  language={currentuser.language}
                  deleteHandler={deleteAttributeHandler}
                  deleteValueHandler={deleteValueHandler}
                  addValue={editValueHandler}
                  editHandler={editAttributeHandler}
                  editValueHandler={editValueHandler}
                  editValue={editValue}
                />
              )}
            {!attributes.isLoading && !attributes.isError && attributes.data.length === 0 && <h2>NEMA PODATAKA</h2>}
          </div>
        </Col>

        {edit.visible && (
          <Col xs={24} md={8}>
            <AttributeForm onSubmit={onSubmitAttribute} attribute={edit.attribute} language={currentuser.language} setEdit={setEdit} />
          </Col>
        )}

        {editValue.visible && (
          <Col xs={24} md={8}>
            <ValueForm
              onSubmit={onSubmitValue}
              attrId={editValue.attrId}
              value={editValue.value || {}}
              language={currentuser.language}
              setEdit={setEditValue}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Attributes;
