import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input, Button } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';

const RefundsTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath }) => {
	const user = useContext(UserContext);
	let searchInput;

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
			return (
				<div style={{ padding: 8 }}>
					<Input
						ref={(node) => {
							searchInput = node;
						}}
						placeholder={`Search ${dataIndex}`}
						value={selectedKeys[0]}
						onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
						onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
						style={{ width: 188, marginBottom: 8, display: 'block' }}
					/>
					<Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} size='small' style={{ width: 90, marginRight: 8 }}>
						Search
					</Button>
					<Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
						Reset
					</Button>
				</div>
			);
		},
		filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
		onFilter: (value, record) => {
			const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
			if (!filterRecord || !value) return false;
			return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
		},
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => {
					searchInput.select();
				});
			}
		},
	});

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
	};

	const handleReset = (clearFilters) => {
		clearFilters();
	};

	const columns = columnKeys.map((item) => ({
		key: item,
		title: item.toUpperCase(),
		dataIndex: ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
		...getColumnSearchProps(['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item),
	}));

	columns.push({
		title: 'Action',
		render: (text, record) => (
			<div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
				<div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
					<Link to={editPath + record._id}>
						<EditOutlined title={`Edit ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }} />
					</Link>
				</div>
			</div>
		),
	});

	return (
		<div>
			<Table
				size='middle'
				bordered
				dataSource={data}
				columns={columns}
				rowKey='_id'
				pagination={{
					defaultPageSize: 20,
					position: 'bottom',
					showSizeChanger: false,
					pageSizeOptions: ['10', '20', '50', '100'],
					hideOnSinglePage: true,
				}}
			/>
		</div>
	);
};

export default RefundsTable;
