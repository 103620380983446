import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import RequestForm from '../../components/forms/RequestForm';

const ViewRequest = (props) => {
  const currentuser = useContext(UserContext);
  const [request, fetchRequest] = useAxios('', {}, currentuser.data.token, 'get');
  const { requestID } = props.match.params;

  useEffect(() => {
    if (requestID) fetchRequest(`${SERVER_URL}/requests/${requestID}`, {});
  }, [fetchRequest, requestID]);

  const isDataFetched = !request.isLoading && Object.keys(request.data).length > 0 && currentuser.language;

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/requests'>
          <Button type='primary'>Svi zahtevi</Button>
        </Link>
      </div>
      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}
        {requestID && !request.isError && request.data && isDataFetched && (
          <RequestForm
            data={request.data}
            language={currentuser.language}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default ViewRequest;
