import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/PostTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
	'_id',
	'__v',
	'gallery'
];

const Slider = () => {
	const currentuser = useContext(UserContext);
	const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
	const history = useHistory();

	useEffect(() => {
		fetchData(`${SERVER_URL}/sliders`, []);
	}, [fetchData]);

	const deleteDataHandler = async (id) => {
		try {
			await Axios.delete(`${SERVER_URL}/sliders/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
			notification.success({
				message: 'Item is deleted.',
				placement: 'bottomRight',
			});
			history.push('/admin');
			history.push('/admin/sliders');
		} catch (err) {
			notification.error({
				message: 'Problem with delete. Please try later.',
				placement: 'bottomRight',
			});
		}
	};

	let columnKeys;
	if (data.data && data.data.items && data.data.items.length > 0) {
		const keys = Object.keys(data.data.items[0]);
		columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
	}

	let tableData = [];
	if (data.data && data.data.items && data.data.items.length > 0) {
		tableData = data.data.items.map((item) => {
			item.createdAt = new Date(item.createdAt).toLocaleString();
			item.updatedAt = new Date(item.updatedAt).toLocaleString();
			return item;
		});
	}

	return (
		<div style={{ padding: '8px' }}>
			<div className='actions-block'>
				<Link to='/admin/new-slider'>
					<Button type='primary'>Novi slajder</Button>
				</Link>
			</div>

			<div style={{ textAlign: 'center' }}>
				{data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
				{!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
					<Table data={tableData} deleteHandler={deleteDataHandler} columnKeys={columnKeys} title='Slider' editPath='/admin/edit-slider/' />
				)}
				{!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
			</div>
		</div>
	);
};

export default Slider;
