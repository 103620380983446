import React, { useContext } from 'react';
import { Form, Input, Button, Card } from 'antd';
import { UserContext } from '../App';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const Login = () => {
  const user = useContext(UserContext);

  const onFinish = (values) => {
    user.handleLogin(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
      <div className="log">
        <div className="card-wrapper">
          <img style={{'marginBottom':'20px'}} className="login-logo" src="/logo.png" alt="Himtex logo" />
          <Card title="Prijavi se" className="login-header" bordered={false}>
            <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Unesite vašu email adresu!' }]}>
                <Input />
              </Form.Item>

              <Form.Item label='Lozinka' name='password' rules={[{ required: true, message: 'Unesite vašu lozinku!' }]}>
                <Input.Password />
              </Form.Item>

              <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                <Button type='primary' htmlType='submit' style={{ backgroundColor: "#f95e00", borderRadius: '0', width: '120px' }}>
                  PRIJAVA</Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
  );
};

export default Login;
