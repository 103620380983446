import React, {useState, useEffect} from 'react';
import {Button, Card, Col, Form, Row, Select, Table, Typography, DatePicker, Input, Modal} from "antd";
import moment from "moment";
import {EditOutlined} from "@ant-design/icons";
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const formInit = {
	_id: null,
	status: '',
};

const formInitModal = {
	resolution: '',
	proposal: '',
	reclamationDeadline: '',
	reclamationResolvingWay: '',
	deadlineExtension: '',
	reclamationResolutionDate: '',
}

const formItemLayout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 8 },
};

const ReclamationForm = ({ data, onSubmit, serverUrl, modalVisible, changeModalVisible, onSubmitModal }) => {
	const [form] = Form.useForm();
	const [formModal] = Form.useForm();
	const [currentIndex, setCurrentIndex] = useState(0);

	const deadlineTime = data && data.reclamation[currentIndex] && data.reclamation[currentIndex].reclamationDeadline ? new Date(data.reclamation[currentIndex].reclamationDeadline).getTime() : undefined;
	const resolutionDate = data && data.reclamation[currentIndex] && data.reclamation[currentIndex].reclamationResolutionDate ? new Date(data.reclamation[currentIndex].reclamationResolutionDate).getTime() : undefined;
	let initialValues = { ...formInit, ...data };
	let initialValuesModal = { ...formInitModal, ...{ ...data.reclamation[currentIndex], reclamationDeadline: deadlineTime ? moment(new Date(deadlineTime), 'YYYY-MM-DD') : '', reclamationResolutionDate: resolutionDate ? moment(new Date(resolutionDate), 'YYYY-MM-DD') : '' }}

	const onFinish = async (values) => {
		onSubmit(values);
	};
	const onFinishModal = async (values) => {
		onSubmitModal(values, currentIndex);
	}

	useEffect(() => formModal.resetFields(), [modalVisible]);

	let fullName = '';
	if (data.userId) {
		fullName += data.userId.firstName ? data.userId.firstName + ' ' : '';
		fullName += data.userId.lastName ? data.userId.lastName : '';
	}
	const reclamationStatus = ['Podneta', 'Primljena', 'Razrešena'];
	const reclamationResolution = ['Prihvaćena', 'Neprihvaćena'];
	const reclamationProposal = ['Zamena robe', 'Slanje na servis', 'Povrat novca'];

	const columns = [
		{
			key: '1',
			title: 'Naziv proizvoda',
			dataIndex: 'productName'
		},
		{
			key: '2',
			title: 'Šifra proizvoda',
			dataIndex: 'code'
		},
		{
			key: '3',
			title: 'Zahtevana količina',
			dataIndex: 'quantity'
		},
		{
			key: '4',
			title: 'Količina',
			dataIndex: 'orderQuantity'
		},
		{
			key: '5',
			title: 'Razlog reklamacije',
			dataIndex: 'reclamationReason'
		},
		{
			key: '6',
			title: 'Akcija',
			render: (text, record, index) => (
				<div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
					<div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
						<a onClick={() => {setCurrentIndex(index); changeModalVisible();}}>
							<EditOutlined style={{ textDecoration: 'none', color: 'black' }} />
						</a>
					</div>
				</div>)
		},
	];
	return (
		<div className='panel panel-body'>
			<div className='panel-body'>
				<Form
					className='form-horizontal'
					layout={formItemLayout}
					initialValues={initialValues}
					onFinish={(values) => onFinish(values)}
					layout='vertical'
					form={form}>
					<Row type='flex' gutter={16}>
						<Col xs={24} md={8}>
							<div className='panel-body'>
								<Card title='Informacije o reklamaciji' bordered={false}>
									<p>
										<Text strong>Broj reklamacije: </Text>
										{data.reclamationNumber ? data.reclamationNumber : 'Nema podataka'}
									</p>
									<p>
										<Text strong>Kupac: </Text>
										{fullName !== '' ? fullName : 'Nema podataka'}
									</p>
									<p>
										<Text strong>Broj porudžbine: </Text>
										{data.orderId && data.orderId.orderNumber ? data.orderId.orderNumber : 'Nema podataka'}
									</p>
									<p>
										<Text strong>Komentar: </Text>
										{data.comment ? data.comment : 'Nema podataka'}
									</p>
									<p>
										<Text strong>Kreirano: </Text>
										{data.createdAt ? new Date(data.createdAt).toLocaleString() : 'Nema podataka'}
									</p>
								</Card>
								<Form.Item label='Status reklamacije' name='status'>
									<Select>
										{reclamationStatus.map((item, index) => (
											<Option key={index} value={item}>{item}</Option>
										))}
									</Select>
								</Form.Item>

								<div className='text-right'>
									<Button type='primary' htmlType='submit'>
										Izmeni
									</Button>
								</div>
							</div>
						</Col>
						<Modal
							title={`Reklamacija - ${data.reclamation[currentIndex].productName}`}
							visible={modalVisible}
							cancelButtonProps={{ style: { display: 'none' } }}
							okButtonProps={{ style: { display: 'none' } }}
							onCancel={changeModalVisible}
							style={{top:'100px'}}
						>
							<Form
								onFinish={onFinishModal}
								form={formModal}
								initialValues={initialValuesModal}
								layout='vertical'
							>
								<Form.Item label='Odluka reklamacije' name='resolution'>
								<Select>
									{reclamationResolution.map((item, index) => (
										<Option key={index} value={item}>{item}</Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label='Predlog reklamacije' name='proposal'>
								<Select>
									{reclamationProposal.map((item, index) => (
										<Option key={index} value={item}>{item}</Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label='Ugovoren rok za rešenje reklamacije' name='reclamationDeadline'>
								<DatePicker placeholder="Izaberite datum"/>
							</Form.Item>
							<Form.Item label='Način rešavanja reklamacije' name='reclamationResolvingWay'>
								<Input/>
							</Form.Item>
							<Form.Item label='Informacije o produžetku roka rešavanja reklamacije' name='deadlineExtension'>
								<TextArea rows={4}/>
							</Form.Item>
							<Form.Item label='Datum rešenja reklamacije' name='reclamationResolutionDate'>
								<DatePicker placeholder="Izaberite datum"/>
							</Form.Item>
								<div className='text-right'>
									<Button type='primary' htmlType='submit'>
										Izmeni
									</Button>
								</div>
							</Form>
						</Modal>
						<Col xs={24} md={16}>
							<div className='panel-body'>
								<Table pagination={false} rowKey='_id' style={{ margin: '0px 0px 40px 0px' }} columns={columns} dataSource={data.reclamation} bordered />
								{data.reclamation.length > 0 && data.reclamation.map((item, index) => (
									<div key={index}>
										<Text>Naziv proizvoda: {item.productName}</Text>
										<Row type='flex' gutter={16}>
											<Col xs={24} md={8}>
												<h4>Slika proizvoda</h4>
												<img style={{width: '300px'}} src={`${serverUrl}/${item.productImage.url}`}/>
											</Col>
											<Col xs={24} md={8}>
												{item.recordImage && <div>
													<h2>Record image</h2>
													<img style={{width: '300px'}} src={`${serverUrl}/${item.recordImage.url}`}/>
												</div>}
											</Col>
										</Row>
									</div>
								))}
							</div>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	)
}

export default ReclamationForm;
