import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import Table from '../../components/tables/UsersTable';
import Axios from 'axios';
import { ImportExcel } from '../../components/csv/ImportExcel';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'googleDescription',
  'keywords',
  'content',
  'image',
  'user',
  'url',
  'tag',
];

const ImportData = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/import-data`, []);
  }, [fetchData]);

  let columnKeys;
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));

  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      // item.user = item.createdBy.email;
      return item;
    });
  }

  const uploadData = (val) => {
    try {
      if (val.file.response) {
        if (val.file.status === 'done') {
          notification.success({
            message: val.file.response.message,
            placement: 'bottomRight',
          });
        } else {
          notification.error({
            message: val.file.response.message,
            placement: 'bottomRight',
          });
        }
        history.push('/admin');
        history.push('/admin/import-data');
      }
    } catch (err) {
      notification.error({
        message: 'Problem with insert.',
        placement: 'bottomRight',
      });
    }
  };

  const handleImportFile = async (event) => {
    let file = event.target.files[0];
    let formData = new FormData();
    formData.append("excelData", file);
    // const disabledButton = [...disabled];
    // disabledButton[index] = true;
    // setDisabled(disabledButton);
    try {
      const resClients = await Axios.post(`${SERVER_URL}/upload-data`, formData, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}`, height: 100, uri: 'public/static/excel/' } });
      notification.success({
        message: resClients.data.status === 'Imported' ? 'Podaci su uspešno importovani' : 'Nije bilo novih podataka za importovanje',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteDataHandler = async (id) => {
    // try {
    //   await Axios.delete(`${SERVER_URL}/posts/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
    //   notification.success({
    //     message: 'Item is deleted.',
    //     placement: 'bottomRight',
    //   });
    //   history.push('/admin');
    //   history.push('/admin/posts');
    // } catch (err) {
    //   notification.error({
    //     message: 'Problem with delete. Please try later.',
    //     placement: 'bottomRight',
    //   });
    // }
  };
  const handleClick = (name, i) => {
    document.getElementById('input-file').click();
  };


  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <div style={{ display: 'flex' }}>
          {/*<ImportExcel token={currentuser.data.token} uploadData={uploadData} />*/}
          <Button onClick={() => handleClick('Delmax', 0)} title='Import excel list' type='secondary' icon={<UploadOutlined />}>Import Excel</Button>
          <input style={{ display: 'none' }} id='input-file' type='file' accept='.XLSX' onChange={handleImportFile} />
        </div>
      </div>

      <div style={{ textAlign: 'center' }}>
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
          <Table data={tableData} deleteHandler={deleteDataHandler} columnKeys={columnKeys} title='Data' editPath='/admin/ImportData/' />
        )}
        {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default ImportData;
