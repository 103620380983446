import React, { useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Form, Input, Button, Card, Select, Result, Table, Popconfirm} from 'antd';
import UploadBox from '../base/UploadBox';
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
// const layout = {
//   labelCol: { span: 8 },
//   wrapperCol: { span: 8 },
// };

// const tailLayout = {
//   wrapperCol: { offset: 8, span: 8 },
// };

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const tailLayout = {
  wrapperCol: { span: 24 },
};

const initialValues = {
  email: undefined,
  password: undefined,
  address: undefined,
  city: undefined,
  zip: undefined,
  country: undefined,
  avatar: undefined,
  role: undefined,
  companyName: undefined,
  idNumber: undefined,
  taxId: undefined,
  legalForm: undefined,
  activity: undefined,
  commercialist: undefined,
  website: undefined,
};

const initialValuesSupplier = {
  supplier: undefined,
  discount: 0,
  currency: '',
};

const UserForm = ({ data, updateHandler, createHandler, result, setResult, avatar, suppliers, addSupplier, deleteSupplier, tableData }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [formSupplier] = Form.useForm();
  let user = data ? { ...data } : initialValues;
  user.city = data && data.place ? data.place.name : null
  user.address = data && data.street ? data.street.name + data.streetNumber : null
  const isNew = data ? false : true;

  const prevForm = sessionStorage.getItem('userForm');
  if (prevForm) user = JSON.parse(prevForm);

  let image;
  if (data && data.avatar) image = data.avatar.url;
  if (avatar) image = avatar.url;

  const columns = [
    {
      title: 'Supplier',
      dataIndex: 'supplier'
    },
    {
      title: 'Discount',
      dataIndex: 'discount'
    },
    {
      title: 'Currency',
      dataIndex: 'currency'
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div style={{margin: '2px', padding: '4px'}}>
<Popconfirm
  placement='left'
  title={`This will delete supplier`}
  onConfirm={() => {
    deleteSupplier(record.supplierId._id);
  }}
  okText='Ok'
  cancelText='Cancel'
    >
    <DeleteOutlined />
    </Popconfirm>
    </div>
)
},
]

  const onFinish = (values, isNew) => {
    sessionStorage.removeItem('userForm');
    if (isNew) {
      if (avatar) {
        values.avatar = avatar._id;
      }
      createHandler(values);
    } else {
      if (data.avatar) values.avatar = data.avatar._id;
      if (avatar) values.avatar = avatar._id;
      updateHandler(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const avatarHandler = () => {
    const values = form.getFieldsValue();
    sessionStorage.setItem('userForm', JSON.stringify(values));
    history.push(`/admin/gallery/edit-content?fromPage=${isNew ? 'new-user' : 'edit-user/' + data._id}${values.avatar ? '&imageIds=' + values.avatar._id.toString() : ''}`);
  };

  return (
    <div>
    {/* <h2>{isNew ? 'NEW' : 'EDIT'} USER</h2> */}
    <div className='dashboard'>
    <div className='card-wrapper'>
    {/* eslint-disable-next-line no-useless-concat */}
    <Card title={`${isNew ? 'DODAJ NOVOG' : 'IZMENI'} KORISNIKA`} bordered={false}>
    {!result && (
  <Form
  {...layout}
  name='basic'
  initialValues={user}
  onFinish={(values) => onFinish(values, isNew)}
  onFinishFailed={onFinishFailed}
  layout='horizontal'
  form={form}
    >
    <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Unesite email!' }]}>
    <Input disabled={true} placeholder='Email' />
    </Form.Item>

    <Form.Item label='Ime' name='firstName' rules={[{ required: true, message: 'Unesite ime!' }]}>
    <Input disabled={true} placeholder='Ime' />
    </Form.Item>

    <Form.Item label='Prezime' name='lastName' rules={[{ required: true, message: 'Unesite prezime!' }]}>
    <Input disabled={true} placeholder='Prezime' />
    </Form.Item>

    <Form.Item label='Adresa' name='address'>
    <Input disabled={true} placeholder='Adresa' />
    </Form.Item>

    <Form.Item label='Grad' name='city'>
    <Input disabled={true} placeholder='Grad' />
    </Form.Item>

    <Form.Item label='PTT' name='zip'>
    <Input disabled={true} placeholder='PTT' />
    </Form.Item>

  {data && data.phone.length > 0 && data.phone.map((item, index) => (
    <Form.Item key={index} label={`Telefon ${index + 1}`} name={['phone', index]}>
  <Input disabled={true} placeholder='Telefon' />
    </Form.Item>
  ))}

  {isNew && (
  <Form.Item label='Lozinka' name='password' rules={[{ required: true, message: 'Unesite lozinku!' }]}>
    <Input.Password disabled={true} placeholder='Lozinka' />
    </Form.Item>
  )}

<Form.Item label='Rola' name='role' rules={[{ required: true, message: 'Unesite rolu!' }]}>
    <Select placeholder='Rola'>
      <Select.Option value='admin'>Admin</Select.Option>
      <Select.Option value='commercialist'>Komercijalista</Select.Option>
      <Select.Option value='wholesale user'>Veleprodajni kupac</Select.Option>
      <Select.Option value='retail user'>Maloprodajni kupac</Select.Option>
    </Select>
    </Form.Item>

  {!isNew && (
  <Form.Item label='Status' name='status' rules={[{ required: true, message: 'Please select status!' }]}>
    <Select>
    <Select.Option value='ACTIVE'>AKTIVAN</Select.Option>
    <Select.Option value='SUSPENDED'>SUSPENDOVAN</Select.Option>
    <Select.Option value='DELETED'>OBRISAN</Select.Option>
    <Select.Option value='WAITING_FOR_ACTIVATION'>ČEKANJE NA AKTIVACIJU</Select.Option>
    </Select>
    </Form.Item>
  )}
  { data && data.legalType === 'corporate' && <div>
  <Form.Item label='Ime kompanije' name='companyName'>
    <Input disabled={true} placeholder='Ime kompanije'/>
    </Form.Item>
    <Form.Item label='Matični broj' name='idNumber'>
    <Input disabled={true} placeholder='Matični broj'/>
    </Form.Item>
    <Form.Item label='PIB' name='taxId'>
    <Input disabled={true} placeholder='PIB'/>
    </Form.Item>
    <Form.Item label='Pravna forma' name='legalForm'>
    <Input disabled={true} placeholder='Pravna forma'/>
    </Form.Item>
    <Form.Item label='Odgovorna osoba' name='commercialist'>
    <Input disabled={true} placeholder='Odgovorna osoba'/>
    </Form.Item>
    <Form.Item label='Sajt' name='website'>
    <Input disabled={true} placeholder='Sajt'/>
    </Form.Item>
    </div>}
    <Form.Item label='Slika' className='upload-wrapper right' name='avatar'>
    <UploadBox handler={avatarHandler} image={image} />
  </Form.Item>

  <Form.Item {...tailLayout}>
  <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
    Potvrdi
    </Button>
    </Form.Item>
    </Form>
  )}
  {result && (
  <Result
    title='Korisnik je kreiran i poslat je verifikacioni mejl'
    extra={
      <Button type='primary' key='console' onClick={() => setResult(false)}>
    Ok
    </Button>
  }
    />
  )}
</Card>
  {data && data.legalType === 'corporate' && (
  <Card style={{ margin: '0px 0px 0px 10px' }} title='Rabati i valute' bordered={false}>
    <Table
    rowKey='_id'
    style={{ margin: '0px 0px 40px 0px' }}
    columns={columns}
    dataSource={tableData}
    bordered
    pagination={{
    defaultPageSize: 10,
      position: 'bottom',
      showSizeChanger: false,
      pageSizeOptions: ['10', '20'],
      hideOnSinglePage: true,
  }}
    />
    <Form
    {...layout}
    layout='vertical'
    initialValues={initialValuesSupplier}
    onFinish={(values) => addSupplier(values)}
    form={formSupplier}
      >
      <Form.Item name='supplier' label='Dobavljač' rules={[{ required: true, message: 'Izaberite dobavljača!' }]}>
    <Select
    style={{ width: '50%', float: 'left', textAlign: 'left' }}
    showSearch
    optionFilterProp="children"
    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
  >
    {suppliers.length > 0 && suppliers.map((item, index) => (
      <Option value={item._id} key={index}>
      {item.title.en}
      </Option>
    ))}
  </Select>
  </Form.Item>
  <Form.Item name='discount' label='Rabat'>
    <Input style={{ width: '50%', float: 'left', textAlign: 'left' }} type='number' min={0}/>
  </Form.Item>
  <Form.Item name='currency' label='Valuta'>
    <Input style={{ width: '50%', float: 'left', textAlign: 'left' }}/>
  </Form.Item>
  <Form.Item {...tailLayout}>
  <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
    Dodaj rabat
  </Button>
  </Form.Item>
  </Form>
  </Card>
  )
  }
</div>
  </div>
  </div>
);
};

export default UserForm;
