import React, {useContext, useEffect} from 'react';
import { UserContext } from '../../App';
import useAxios from "../../hooks/useAxios";
import {SERVER_URL} from "../../config";
import {LoadingOutlined} from "@ant-design/icons";
import Table from "../../components/tables/DeclarationTable";
import Axios from "axios";
import {useHistory} from 'react-router-dom';
import {notification} from "antd";

const TABLE_COLUMN_KEYS = [
    '_id',
    '__v',
    'content',
    'qualityClass',
    'material',
    'user',
    'createdBy',
    'countryOfOrigin',
    'importer',
    'productName',
    'manufacturer'
];

const Declaration = () => {
   const currentuser = useContext(UserContext);
   const [declarations, fetchDeclarations] = useAxios('', [], currentuser.data.token, 'get');
   const history = useHistory();

   useEffect(() => {
       if(currentuser.language) fetchDeclarations(`${SERVER_URL}/declarations`, []);
   }, [fetchDeclarations, currentuser]);

    const deleteDeclarationHandler = async (id) => {
        try {
            await Axios.delete(`${SERVER_URL}/declarations/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
            notification.success({
                message: 'Item is deleted.',
                placement: 'bottomRight',
            });
            history.push('/admin');
            history.push('/admin/declarations');
        } catch (err) {
            notification.error({
                message: 'Problem with delete. Please try later.',
                placement: 'bottomRight',
            });
        }
    };

    let columnKeys;
    if (declarations.data && declarations.data && declarations.data.length > 0) {
        const keys = Object.keys(declarations.data[0]);
        columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
        columnKeys.splice(1, 0, 'createdBy')
    }

    let tableData = [];
    if (declarations.data && declarations.data && declarations.data.length > 0) {
        tableData = declarations.data.map((item) => {
            item.createdAt = new Date(item.createdAt).toLocaleString();
            item.updatedAt = new Date(item.updatedAt).toLocaleString();
            item.createdBy = item.user;
            return item;
        });
    }

   return(
       <div style={{ padding: '8px' }}>
           <div style={{ textAlign: 'center' }}>
               {declarations.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
               {!declarations.isLoading && declarations.data && declarations.data && declarations.data.length > 0 && (
                   <Table data={tableData} deleteHandler={deleteDeclarationHandler} columnKeys={columnKeys} title='Declaration' editPath='/admin/edit-declaration/' />
               )}
               {!declarations.isLoading && declarations.data && declarations.data && declarations.data.length === 0 && <h2>NEMA PODATAKA</h2>}
           </div>
       </div>
   )
}

export default Declaration;