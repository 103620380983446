import React, {useContext, useEffect, useState} from "react";
import DocumentationForm from '../../components/forms/DocumentationForm';
import {UserContext} from "../../App";
import Axios from "axios";
import {SERVER_URL} from "../../config";
import useAxios from "../../hooks/useAxios";
import {Input} from "antd";

const EditDocumentation = () => {
    const currentuser = useContext(UserContext);
    const [fileList, setFileList] = useState([]);
    const [fileListDocuments, setFileListDocuments] = useState([]);
    const [pdfs, fetchPdfs] = useAxios('', null, currentuser.data.token, 'get');
    const [searchValue, setSearchValue] = useState('');
    const [sorting, setSorting] = useState('normal')

    useEffect(() => {
        fetchPdfs(`${SERVER_URL}/pdf-type-document`)
        const getFiles = async () => {
            if(sorting === 'normal') {
                if (!pdfs.isLoading && pdfs.data && currentuser.language) {
                    const files = pdfs.data.map(async (item) => {
                        const url = await getPdf(item.localPath);
                        return {name: item.modifiedname, _id: item._id, uid: '-1', status: 'done', url: url};
                    })
                    const fileList = await Promise.all(files);
                    setFileList(fileList.filter((item) => item.name.split('_')[0].toLowerCase().indexOf(searchValue.toLowerCase()) >= 0));
                    setFileListDocuments(fileList)
                }
            }
        };
        getFiles();
    }, [pdfs, fetchPdfs, sorting])

    const getPdf = async (path) => {
        let url = '';
        await Axios.get(`${SERVER_URL}/get-pdf?fileName=${path}`, { withCredentials: false, responseType: 'arraybuffer', headers: { Authorization: `Bearer ${currentuser.data.token}` }}).then(res => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            url = URL.createObjectURL(blob);
        }).catch(err => console.log(err.message))
        return url;
    };

    const checkDataExists = async (code) => {
        try {
            const dataResponse = await Axios.get(`${SERVER_URL}/data-by-code?code=${code}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` }})
            if(dataResponse.data) return true;
        } catch (error) {
            if(error.response.status === 401) return false;
        }
    }

    const onChangeHandler = async (file) => {
        if (file.status === 'uploading'){
            setFileList([...fileList.filter(item => item.status === 'done'), file]);
        } else if (file.status === 'done') {
            file.name = file.response.file.modifiedname;
            const url = await getPdf(file.response.file.localPath)
            setFileList([...fileList.filter(item => item.status === 'done'), {...file, url}])
        } else if (file.status === 'removed' || (file.response && file.response.status === 'removed')) {
            let fileId = file.response && file.response.file ? file.response.file._id : file._id

            setFileList(fileList.filter(item => {
                if(item._id){
                    return item._id !== fileId;
                } else if (item.response && item.response.file){
                    return item.response.file._id !== fileId;
                }
            }))
            await Axios.get(`${SERVER_URL}/pdfs/${fileId}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` }
            }).then(async res => {
                await Axios.get(`${SERVER_URL}/delete-pdf?file=${JSON.stringify(res.data)}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` }})
            })
        }
    }

    const handleSearchDocumentation = (value) => {
      let files = [...fileListDocuments].filter((item) => item.name.split('_')[0].toLowerCase().indexOf(value.toLowerCase()) >= 0);
      if(sorting !== 'normal'){
          files.sort((a, b) => {
              if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return sorting === 'asc' ? 1 : -1;
              }
              return sorting === 'desc' ? 1 : -1;
          })
      }
      setSearchValue(value);
      setFileList(files);
    }

    const sortingHandler = (e) => {
        const sortType = e.target.value;
        if(sortType !== 'normal') {
            fileList.sort((a, b) => {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return sortType === 'asc' ? 1 : -1;
                }
                return sortType === 'desc' ? 1 : -1;
            })
        }
        setSorting(e.target.value);
    }

    return(
        <div style={{ padding: '8px' }}>
            <DocumentationForm
                sort={sorting}
                onChangeSorting={sortingHandler}
                searchDocumentation={handleSearchDocumentation}
                token={currentuser.data.token}
                onChangeHandler={onChangeHandler}
                checkDataExists={checkDataExists}
                fileList={fileList}
            />
        </div>
    )
}

export default EditDocumentation;
