import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { Button, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SliderForm from "../../components/forms/SliderForm";
import { SERVER_URL } from "../../config";
import queryString from 'query-string';
import Axios from "axios";

const EditSlider = (props) => {
	const history = useHistory();
	const currentuser = useContext(UserContext);
	const [slider, setSlider] = useState({});
	const [sliders, fetchSliders] = useAxios('', [], currentuser.data.token, 'get');
	const [gallery, fetchGallery] = useAxios('', null, currentuser.data.token, 'get');
	const [reload, triggerReload] = useState(false);

	const { sliderId } = props.match.params;
	const { imageIds } = queryString.parse(props.location.search);

	const fetchSlider = async () => {
		try {
			const res = await Axios.get(`${SERVER_URL}/sliders/${sliderId}`, {
				withCredentials: false,
				headers: { Authorization: `Bearer ${currentuser.data.token}` },
			});
			setSlider(res.data);
		} catch (error) {
			console.log('error', error);
		}
	};

	useEffect(() => {
		fetchSlider();
		if (imageIds) fetchGallery(`${SERVER_URL}/images?filter=${JSON.stringify({ _id: { $in: imageIds.split(',') } })}&select=url -uploadedBy`, []);
		fetchSliders(`${SERVER_URL}/sliders`, []);
	}, [sliderId, imageIds, fetchGallery, fetchSliders, reload]);

	const onSubmit = async (formData, isNew) => {
		const method = isNew ? 'post' : 'put';
		const route = isNew ? `${SERVER_URL}/sliders` : `${SERVER_URL}/sliders/${sliderId}`;
		try {
			if (!isNew || (sliders.data.items.length < 2 && isNew)) {
				await Axios[method](route, formData, {
					withCredentials: false,
					headers: { Authorization: `Bearer ${currentuser.data.token}` },
				});
				notification.success({
					message: `Slajder ${isNew ? 'kreiran.' : 'izmenjen.'}`,
					placement: 'bottomRight',
				});
				history.push('/admin/sliders');
			} else {
				notification.error({
					message: `Slajder je već kreiran.`,
					placement: 'bottomRight',
				});
			}
		} catch (error) {
			const msg = error.response ? error.response.data.message : error.message ? error.message : error;
			console.log(msg);
			notification.error({
				message: msg,
				placement: 'bottomRight',
			});
		}
	};
	const isDataFetched = slider && currentuser.language;
	return (
		<div style={{ padding: '8px' }}>
			<div className='actions-block'>
				<Link to='/admin/sliders'>
					<Button type='primary'>Svi Slajderi</Button>
				</Link>
			</div>

			<div>
				{!isDataFetched && (
					<div style={{ textAlign: 'center' }}>
						<LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
					</div>
				)}
				{!sliderId && isDataFetched && (
					<SliderForm
						key={Math.random()}
						isNew={true}
						language={currentuser.language}
						gallery={gallery.data}
						onSubmit={onSubmit}
						SERVER_URL={SERVER_URL}
						token={currentuser.data.token}
						triggerReload={triggerReload}
					/>
				)}
				{sliderId && slider && slider && isDataFetched && (
					<SliderForm
						key={Math.random()}
						isNew={false}
						data={slider}
						gallery={gallery.data}
						language={currentuser.language}
						onSubmit={onSubmit}
						SERVER_URL={SERVER_URL}
						token={currentuser.data.token}
						triggerReload={triggerReload}
					/>
				)}
			</div>
		</div>
	);
};

export default EditSlider;
