import React, { useContext } from "react";
import { Table, Input, Button, Popconfirm, Checkbox } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { UserContext } from "../../App";
import { Link } from "react-router-dom";

const DataTable = ({
  data,
  deleteHandler,
  columnKeys,
  title,
  editPath,
  viewPath,
  selected,
  selectHandler,
}) => {
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? "red" : "#bbb", fontSize: "1rem" }}
      />
    ),
    onFilter: (value, record) => {
      let filterRecord;
      if (dataIndex.includes("category")) {
        filterRecord = Array.isArray(dataIndex)
          ? record &&
          record[dataIndex[0]] &&
          record[dataIndex[0]][dataIndex[1]] &&
          record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]];
      } else if (!dataIndex.includes("category")) {
        filterRecord = Array.isArray(dataIndex)
          ? !dataIndex.includes("category")
            ? record[dataIndex[0]][dataIndex[1]]
            : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
          : "";
      }

      if (!filterRecord || !value) return false;
      return filterRecord
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const sort = (a, b) => {
    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    }
    return 0;
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  let columns = columnKeys.map((item) => ({
    key: item,
    title: item,
    dataIndex:
      item === "createdBy"
        ? "user"
        : ["title", "category"].includes(item)
          ? item === "title"
            ? [item, user?.language ? user?.language?.selected?.code : ""]
            : [item, "name", user?.language ? user?.language?.selected?.code : ""]
          : item,
    sorter: (a, b) => {
      let dataIndex =
        item === "createdBy"
          ? "user"
          : ["title", "category"].includes(item)
            ? item === "title"
              ? [item, user?.language ? user?.language?.selected?.code : ""]
              : [
                item,
                "name",
                user?.language ? user?.language?.selected?.code : "",
              ]
            : item;
      if (Array.isArray(dataIndex)) {
        if (dataIndex.includes("category")) {
          if (
            a[dataIndex[0]] &&
            a[dataIndex[0]][dataIndex[1]] &&
            a[dataIndex[0]][dataIndex[1]][dataIndex[2]] &&
            b[dataIndex[0]] &&
            b[dataIndex[0]][dataIndex[1]] &&
            b[dataIndex[0]][dataIndex[1]][dataIndex[2]]
          ) {
            return sort(
              a[dataIndex[0]][dataIndex[1]][dataIndex[2]],
              b[dataIndex[0]][dataIndex[1]][dataIndex[2]],
            );
          }
        } else {
          return sort(a, b);
        }
      } else {
        if (dataIndex === "createdAt" || dataIndex === "updatedAt") {
          return sort(new Date(a[dataIndex]), new Date(b[dataIndex]));
        }
        return sort(a[dataIndex], b[dataIndex]);
      }
    },
    ...getColumnSearchProps(
      item === "createdBy"
        ? "user"
        : ["title", "category"].includes(item)
          ? item === "title"
            ? [item, user?.language ? user?.language?.selected?.code : ""]
            : [item, "name", user?.language ? user?.language?.selected?.code : ""]
          : item,
    ),
  }));

  columns.unshift({
    title: "Select",
    width: "4rem",
    render: (item) => (
      <div style={{ textAlign: "center" }}>
        <Checkbox
          checked={selected.includes(item._id)}
          onChange={() => selectHandler(item._id)}
        />
      </div>
    ),
  });

  columns.push({
    title: "Action",
    render: (text, record) => (
      <div
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "15px",
        }}
      >
        {editPath && (
          <div style={{ margin: "2px", padding: "4px", cursor: "pointer" }}>
            <Link to={editPath + record._id}>
              <EditOutlined
                title={`Edit ${title.toLowerCase()}`}
                style={{ textDecoration: "none", color: "black" }}
              />
            </Link>
          </div>
        )}

        {viewPath && (
          <div style={{ margin: "2px", padding: "4px", cursor: "pointer" }}>
            <Link to={viewPath + record._id}>
              <EyeOutlined
                title={`View ${title.toLowerCase()}`}
                style={{ textDecoration: "none", color: "black" }}
              />
            </Link>
          </div>
        )}

        <div style={{ margin: "2px", padding: "4px" }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement="left"
            title={`This will delete ${title.toLowerCase()}`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText="Ok"
            cancelText="Cancel"
          >
            <DeleteOutlined title={`Delete ${title.toLowerCase()}`} />
          </Popconfirm>
        </div>
      </div>
    ),
  });

  columns = columns.filter((column) => {
    return (
      column.key !== "updatedAt" &&
      column.key !== "weight" &&
      column.key !== "unitOfMeasure" &&
      column.key !== "status"
    );
  });

  return (
    <div>
      <Table
        size="middle"
        bordered
        dataSource={data}
        columns={columns}
        rowKey="_id"
        pagination={{
          defaultPageSize: 200,
          position: "bottom",
          showSizeChanger: false,
          pageSizeOptions: ["10", "20", "50", "100"],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default DataTable;
