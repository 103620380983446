import React, { useContext, useEffect, useState } from 'react';
import { Card, Checkbox, message, notification, Radio, Space, Upload } from 'antd';
import { SERVER_URL } from '../../config';
import { InboxOutlined } from '@ant-design/icons';
import useAxios from '../../hooks/useAxios';
import { UserContext } from '../../App';

const { Dragger } = Upload;

const GalleryForm = ({
  token,
  selectHandler,
  imageSavePath,
  imageType,
  imageHeight,
  newContent,
}) => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  useEffect(() => {
    fetchData(`${SERVER_URL}/image-folders`, []);
  }, [fetchData]);
  const [chosenFolder, setChosenFolder] = useState();

  const [checked, setChecked] = useState(false);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  imageType =
    imageType === 'common' ? (checked ? 'brand' : 'common') : imageType;
  let newPath = [];
  if (imageType === 'brand') {
    newPath = imageSavePath.split('/');
    for (let i = newPath.length - 1; i > 1; i--) {
      newPath.pop();
    }
    newPath.push('brand');
    newPath.push('');
    imageSavePath = newPath.join('/');
  }
  return (
    <div className="dashboard">
      <div className="card-wrapper">
        <Card title="Dodaj nove slike" bordered={false}>
          <Dragger
            multiple
            action={`${SERVER_URL}/upload-local-image`}
            accept=".jpg,.jpeg,.png,.bmp,.svg,.webp"
            name="image"
            getValueFromEvent={normFile}
            headers={{
              type: imageType,
              resize: true,
              // resizemob: false,
              height: imageHeight,
              Authorization: `Bearer ${token}`,
              uri: imageSavePath,
              ...(chosenFolder && { folder: chosenFolder }),
              // urimob: imageSavePath + 'mob/',
            }}
            beforeUpload={(file) => {
              const isAllowedFormat = [
                'image/jpeg',
                'image/png',
                'image/webp',
                'image/svg+xml',
              ].includes(file.type);
              if (!isAllowedFormat) {
                notification.error({
                  message: 'You can only upload JPG/PNG/SVG/WEBP file!',
                  placement: 'bottomRight',
                });
              }
              const isAllowedSize = file.size / 1024 / 1024 < 5;
              if (!isAllowedSize) {
                notification.error({
                  message: 'Image must smaller than 5MB!',
                  placement: 'bottomRight',
                });
              }
              return isAllowedFormat && isAllowedSize;
            }}
            onChange={async ({ file }) => {
              if (file.status === 'done') {
                if (file.response && file.response.file) {
                  message.success(
                    `${file.response.file.name} file uploaded successfully.`,
                  );
                  if (selectHandler) {
                    selectHandler(file.response.image._id);
                  }
                }
              } else if (file.status === 'error') {
                message.error(`${file.response.file.name} file upload failed.`);
                // message.error(`${file.response.message} file upload failed.`);
              }
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Klikni ili prevuci ovde slike za dodavanje
            </p>
            {/* <p className='ant-upload-hint'>Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p> */}
          </Dragger>
          {newContent && (
            <div>
              <Checkbox
                onChange={(e) => setChecked(e.target.checked)}
                checked={checked}
              />
              <span style={{ margin: '0px 0px 0px 8px' }}>
                Dodaj sliku za baner
              </span>
            </div>
          )}
          {data && data.data && data.data.items && data.data.items.length !== 0 && (
            <div>
              <p style={{ marginTop: '15px', fontSize: '1.1em' }}>Izaberite folder:</p>
              <Radio.Group onChange={(event) => setChosenFolder(event.target.value)}>
                <Space direction="vertical">
                  {data.data.items.map((item) =>
                    <Radio value={item._id}>{item.folderName}</Radio>
                  )}
                </Space>
              </Radio.Group>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default GalleryForm;
