import React from 'react';
import { Button, Card, Col, Form, Row, Typography } from "antd";
const { Text } = Typography;

const formInit = {
  _id: null,
  status: '',
};

const RequestForm = ({ data }) => {
  const [form] = Form.useForm();

  let initialValues = { ...formInit, ...data };
  const onFinish = async (values) => {
    // onSubmit(values);
  };
  let fullName = '';
  if (data.user) {
    fullName += data.user.firstName ? data.user.firstName + ' ' : '';
    fullName += data.user.lastName ? data.user.lastName : '';
  }

  return (
    <div className='panel panel-body'>
      <div className='panel-body' style={{ width: '100%' }}>
        <Form
          className='form-horizontal'
          // layout={formItemLayout}
          initialValues={initialValues}
          onFinish={(values) => onFinish(values)}
          layout='vertical'
          form={form}>
          <Row type='flex' gutter={16}>
            <Col xs={24} md={24}>
              <div className='panel-body'>
                <Card title='Informacije o zahtevu' bordered={false}>
                  <p>
                    <Text strong>Šifra proizvoda: </Text>
                    {data.product ? data.product.code : `Nema podataka`}
                  </p>
                  <p>
                    <Text strong>Ime kupca: </Text>
                    {data.user ? data.user.firstName : `Nema podataka`}
                  </p>
                  <p>
                    <Text strong>Prezime kupca: </Text>
                    {data.user ? data.user.lastName : `Nema podataka`}
                  </p>
                  <p>
                    <Text strong>Email kupca: </Text>
                    {data.user ? data.user.email : `Nema podataka`}
                  </p>
                  <p>
                    <Text strong>Status: </Text>
                    {data.status ? data.status : `Nema podataka`}
                  </p>
                  <p>
                    <Text strong>Kreirano: </Text>
                    {data.createdAt ? new Date(data.createdAt).toLocaleString() : 'Nema podataka'}
                  </p>
                  <p>
                    <Text strong>Izmenjeno: </Text>
                    {data.updatedAt ? new Date(data.updatedAt).toLocaleString() : 'Nema podataka'}
                  </p>
                </Card>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default RequestForm;
