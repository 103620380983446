import React, {useEffect, useState} from 'react';
import {Button, Card, Col, DatePicker, Form, Input, Modal, Row, Select, Table, Typography} from "antd";
import {EditOutlined} from "@ant-design/icons";
import moment from "moment";
const { Text } = Typography;
const { Option } = Select;

const formInit = {
	_id: null,
	status: '',
};

const formInitModal = {
	resolution: '',
	refundResolutionDate: '',
	shipmentNumber: '',
}

const formItemLayout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 8 },
};

const RefundForm = ({ data, onSubmit, changeModalVisible, modalVisible, onSubmitModal }) => {
	const [form] = Form.useForm();
	const [formModal] = Form.useForm();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [resolution, setResolution] = useState('Neodobren');

	const resolutionDate = data && data.refund[currentIndex] && data.refund[currentIndex].refundResolutionDate ? new Date(data.refund[currentIndex].refundResolutionDate).getTime() : undefined;
	let initialValuesModal = { ...formInitModal, ...{...data.refund[currentIndex], ...{ ...data.refund[currentIndex], refundResolutionDate: resolutionDate ? moment(new Date(resolutionDate), 'YYYY-MM-DD') : '' }}}

	let initialValues = { ...formInit, ...data };
	const onFinish = async (values) => {
		onSubmit(values);
	};

	let fullName = '';
	if (data.userId) {
		fullName += data.userId.firstName ? data.userId.firstName + ' ' : '';
		fullName += data.userId.lastName ? data.userId.lastName : '';
	}
	const refundStatus = ['Podnet', 'Primljen', 'Uspesno vracen'];
	const refundResolution = ['Odobren', 'Neodobren'];
	const columns = [
		{
			key: '1',
			title: 'Naziv proizvoda',
			dataIndex: 'productName'
		},
		{
			key: '2',
			title: 'Šifra proizvoda',
			dataIndex: 'code'
		},
		{
			key: '3',
			title: 'Zahtevana količina',
			dataIndex: 'quantity'
		},
		{
			key: '4',
			title: 'Količina',
			dataIndex: 'orderQuantity'
		},
		{
			key: '5',
			title: 'Razlog povrata',
			dataIndex: 'refundReason'
		},
		{
			key: '6',
			title: 'Akcija',
			render: (text, record, index) => (
				<div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
					<div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
						<a onClick={() => {setCurrentIndex(index); changeModalVisible();}}>
							<EditOutlined style={{ textDecoration: 'none', color: 'black' }} />
						</a>
					</div>
				</div>)
		},
	];

	const onFinishModal = async (values) => {
		onSubmitModal(values, currentIndex);
	}

	useEffect(() => {
		formModal.resetFields();
	}, [modalVisible]);

	useEffect(() => {
		setResolution(data.refund[currentIndex].resolution)
	}, [currentIndex]);

	const handleSelectChange = (value) => {
		setResolution(value)
	}


	return (
		<div className='panel panel-body'>
			<div className='panel-body'>
				<Form
					className='form-horizontal'
					layout={formItemLayout}
					initialValues={initialValues}
					onFinish={(values) => onFinish(values)}
					layout='vertical'
					form={form}>
					<Row type='flex' gutter={16}>
						<Col xs={24} md={8}>
							<div className='panel-body'>
								<Card title='Informacije o povratu' bordered={false}>
									<p>
										<Text strong>Broj povrata: </Text>
										{data.refundNumber ? data.refundNumber : 'Nema podataka'}
									</p>
									<p>
										<Text strong>Kupac: </Text>
										{fullName !== '' ? fullName : 'Nema podataka'}
									</p>
									<p>
										<Text strong>Broj porudžbine: </Text>
										{data.orderId && data.orderId.orderNumber ? data.orderId.orderNumber : 'Nema podataka'}
									</p>
									<p>
										<Text strong>Komentar: </Text>
										{data.comment ? data.comment : 'Nema podataka'}
									</p>
									<p>
										<Text strong>Kreirano: </Text>
										{data.createdAt ? new Date(data.createdAt).toLocaleString() : 'Nema podataka'}
									</p>
								</Card>
								<Form.Item label='Status povrata' name='status'>
									<Select>
										{refundStatus.map((item, index) => (
											<Option key={index} value={item}>{item}</Option>
										))}
									</Select>
								</Form.Item>
								<div className='text-right'>
									<Button type='primary' htmlType='submit'>
										Izmeni
									</Button>
								</div>
							</div>
						</Col>
						<Modal
							title={`Povrat - ${data.refund[currentIndex].productName}`}
							visible={modalVisible}
							cancelButtonProps={{ style: { display: 'none' } }}
							okButtonProps={{ style: { display: 'none' } }}
							onCancel={changeModalVisible}
						>
							<Form
								onFinish={onFinishModal}
								form={formModal}
								initialValues={initialValuesModal}
								layout='vertical'
							>
								<Form.Item label='Odluka povrata' name='resolution'>
									<Select
										onChange={handleSelectChange}
									>
										{refundResolution.map((item, index) => (
											<Option key={index} value={item}>{item}</Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item label='Datum rešenja povrata' name='refundResolutionDate'>
									<DatePicker/>
								</Form.Item>
								{ resolution === 'Odobren' &&
								<Form.Item name='shipmentNumber' label='Shipment number'>
									<Input />
								</Form.Item> }
								<div className='text-right'>
									<Button type='primary' htmlType='submit'>
										Izmeni
									</Button>
								</div>
							</Form>
						</Modal>
						<Col xs={24} md={16}>
							<div className='panel-body'>
								<Table pagination={false} rowKey='_id' style={{ margin: '0px 0px 40px 0px' }} columns={columns} dataSource={data.refund} bordered />
							</div>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	)
}

export default RefundForm;
