import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/PostTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'shorthandName',
  'user',
  'createdBy',
  '_v',
  'modifiedBy',
];

const Suppliers = () => {
  const currentuser = useContext(UserContext);
  const [suppliers, fetchSuppliers] = useAxios(
    '',
    [],
    currentuser.data.token,
    'get',
  );
  const history = useHistory();

  useEffect(() => {
    fetchSuppliers(`${SERVER_URL}/suppliers`, []);
  }, [fetchSuppliers]);

  const deleteDataHandler = async (id) => {
    try {
      // await Axios.delete(`${SERVER_URL}/suppliers/${id}`, {
      //   withCredentials: false,
      //   headers: { Authorization: `Bearer ${currentuser.data.token}` },
      // });
      notification.warning({
        message: 'Dobavljači se trenutno ne mogu brisati.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/suppliers');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (
    suppliers.data &&
    suppliers.data.items &&
    suppliers.data.items.length > 0
  ) {
    const keys = Object.keys(suppliers.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys.splice(1, 0, 'createdBy');
  }
  let tableData = [];
  if (
    suppliers.data &&
    suppliers.data.items &&
    suppliers.data.items.length > 0
  ) {
    tableData = suppliers.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      item.user = item.createdBy ? item.createdBy.email : '';
      return item;
    });
  }
  return (
    <div style={{ padding: '8px' }}>
      <div className="actions-block">
        <Link to="/admin/new-supplier">
          <Button type="primary">Novi dobavljač</Button>
        </Link>
      </div>

      <div style={{ textAlign: 'center' }}>
        {suppliers.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: '3rem', marginTop: '5rem' }}
          />
        )}
        {!suppliers.isLoading &&
          suppliers.data &&
          suppliers.data.items &&
          suppliers.data.items.length > 0 && (
            <Table
              data={tableData}
              deleteHandler={deleteDataHandler}
              columnKeys={columnKeys}
              title="Data"
              editPath="/admin/edit-supplier/"
            />
          )}
        {!suppliers.isLoading &&
          suppliers.data &&
          suppliers.data.items &&
          suppliers.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default Suppliers;
