import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, notification, Row, Select, Tabs } from "antd";
import UploadBox from "../base/UploadBox";
import { useHistory } from "react-router-dom";
import { SERVER_URL } from '../../config';
import Axios from 'axios';
import { PropertySafetyFilled } from '@ant-design/icons';

const formInit = {
  _id: null,
  gallery: [],
};

const SliderForm = ({ isNew, data, language, gallery, onSubmit, token, triggerReload }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [selected, setSelected] = useState();
  const prevForm = sessionStorage.getItem('sliderForm');
  let initialValues = { ...formInit, ...data };

  if (prevForm) initialValues = JSON.parse(prevForm);

  if (gallery && gallery.length > 0) {
    form.setFieldsValue({ gallery });
  } else if (data && data.gallery && data.gallery.length > 0) {
    form.setFieldsValue({ gallery: data.gallery });
  }
  let currentGallery = form.getFieldValue("gallery");

  const onFinish = async (values, isNew) => {
    sessionStorage.removeItem('sliderForm');
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  const saveImageDetails = async () => {
    selected.fontSize1 = +selected.fontSize1;
    selected.fontSize2 = +selected.fontSize2;
    try {
      const imgRes = await Axios.put(`${SERVER_URL}/images/${selected._id}`, {
        ...selected
      }, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });
      notification.success({
        message: 'Uspešno sačuvano.',
        duration: 3,
        placement: 'bottomRight'
      });
    } catch (error) {
      notification.error({
        message: 'Greška prilikom čuvanja.',
        duration: 3,
        placement: 'bottomRight'
      });
    }
    triggerReload(prev => !prev);
    setSelected();
  };

  const galleryHandler = () => {
    const values = form.getFieldsValue();
    sessionStorage.setItem('sliderForm', JSON.stringify(values));
    history.push(
      `/admin/gallery/edit-content?gallery=true&limit=5&fromPage=${isNew ? "new-slider" : "edit-slider/" + data._id
      }${values.gallery ? "&imageIds=" + values.gallery.map((img) => img._id).join(",") : ""}`,
    );
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Form
          className='form-horizontal'
          style={{ width: '550px' }}
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}>
          <span>Naziv slajdera</span>
          <Form.Item name='name' style={{ width: '300px' }}>
            <Input />
          </Form.Item>
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className="panel panel-primary">
                <div className="panel-heading">
                  <h4 className="panel-title">Galerija slika</h4>
                </div>

                <Form.Item name="gallery" valuePropName="image">
                  <div className="panel-body" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                    {currentGallery &&
                      currentGallery.length > 0 &&
                      currentGallery.map((item, index) => <UploadBox key={index} handler={() => setSelected(item)} image={item.url} preview />)}
                    <UploadBox handler={galleryHandler} />
                  </div>
                </Form.Item>
                <div className="text-right">
                  {/*<Button onClick={deactivateGalleryImage}>Deaktiviraj slike</Button>*/}
                </div>
              </div>
              <div className='text-right'>
                <Button type='primary' htmlType='submit'>
                  {isNew ? 'Dodaj' : 'Izmeni'} slajder
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        {selected &&
          <div style={{ backgroundColor: '#fff', display: 'flex' }}>
            <div style={{ backgroundColor: '#fff', border: '1px solid lightgray', minWidth: '200px' }}>
              <img src={selected.url.includes('http') ? selected.url : SERVER_URL + selected.url} style={{ objectFit: 'contain', width: '400px', height: '300px' }}></img>
            </div>
            <div style={{ marginLeft: '15px', marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
              <label style={{ fontSize: '12px' }}>Tekst 1:</label>
              <Input style={{ height: '25px', marginBottom: '10px', marginTop: '5px' }} className='slider-input' placeholder="Tekst za prikaz" value={selected.description1} name='description1' onChange={(e) => { e.persist(); setSelected(prev => ({ ...prev, description1: e.target.value })); }} />
              <label style={{ fontSize: '12px' }}>Velčina teksta 1:</label>
              <Input style={{ height: '25px', marginBottom: '10px', marginTop: '5px' }} className='slider-input' placeholder="Veličina teksta u px(primer: 12)" value={selected.fontSize1} name='fontSize1' onChange={(e) => { e.persist(); setSelected(prev => ({ ...prev, fontSize1: e.target.value })); }} />
              <label style={{ fontSize: '12px' }}>Boja teksta 1:</label>
              <Input style={{ height: '25px', marginBottom: '10px', marginTop: '5px' }} className='slider-input' placeholder="Boja teksta u hex(primer: #fff)" value={selected.fontColor1} name='fontColor1' onChange={(e) => { e.persist(); setSelected(prev => ({ ...prev, fontColor1: e.target.value })); }} />

              <label style={{ fontSize: '12px' }}>Tekst 2:</label>
              <Input style={{ height: '25px', marginBottom: '10px', marginTop: '5px' }} className='slider-input' placeholder="Tekst za prikaz" value={selected.description2} name='description2' onChange={(e) => { e.persist(); setSelected(prev => ({ ...prev, description2: e.target.value })); }} />
              <label style={{ fontSize: '12px' }}>Veličina teksta 2:</label>
              <Input style={{ height: '25px', marginBottom: '10px', marginTop: '5px' }} className='slider-input' placeholder="Veličina teksta u px(primer: 12)" value={selected.fontSize2} name='fontSize2' onChange={(e) => { e.persist(); setSelected(prev => ({ ...prev, fontSize2: e.target.value })); }} />
              <label style={{ fontSize: '12px' }}>Boja teksta 2:</label>
              <Input style={{ height: '25px', marginBottom: '10px', marginTop: '5px' }} className='slider-input' placeholder="Boja teksta u hex(primer: #fff)" value={selected.fontColor2} name='fontColor2' onChange={(e) => { e.persist(); setSelected(prev => ({ ...prev, fontColor2: e.target.value })); }} />
              <Button
                onClick={() => saveImageDetails()}
                style={{ marginTop: '10px', width: '150px', marginLeft: 'auto', borderColor: '#f95e00', backgroundColor: '#f95e00', color: '#fff' }}
              >Sačuvaj</Button>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default SliderForm;
