import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Form,
  notification,
  Modal,
  Radio,
  Space,
  Input,
  Checkbox,
  Select,
} from 'antd';
import { FolderOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { GalleryTable } from '../../components/tables';
import Axios from 'axios';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'key',
  'updatedAt',
  'uploadedBy',
  'url',
  'forMobile',
  'localPath',
  'createdAt',
  'updatedAtOriginal',
];

const Gallery = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [chosenFolder, setChosenFolder] = useState('Prikazi sve');
  const [chosenFolderForm, setChosenFolderForm] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [foundImage, setFoundImage] = useState();
  const [isVisibleMulti, setIsVisibleMulti] = useState(false);
  const [selectedImages, setSelectedImages] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const [images, fetchImages] = useAxios(
    '',
    null,
    currentuser.data.token,
    'get',
  );
  const { id } = props.match.params;

  useEffect(() => {
    if (chosenFolder) {
      let folderFilter = {};
      if (chosenFolder === 'Prikazi sve') {
        folderFilter = { $exists: false };
      } else {
        folderFilter = chosenFolder;
      }
      fetchImages(
        `${SERVER_URL}/images?filter=${JSON.stringify({
          type: { $ne: 'reclamation' },
          folder: folderFilter,
        })}`,
      );
    } else if (!images.data || !chosenFolder) {
      fetchImages(
        `${SERVER_URL}/images?filter=${JSON.stringify({
          type: { $ne: 'reclamation' },
        })}`,
      );
    }
  }, [id, fetchImages, chosenFolder]);

  useEffect(() => {
    fetchData(`${SERVER_URL}/image-folders`, []);
  }, [fetchData]);

  let columnKeys;
  if (images && images.data && images.data.length > 0) {
    const keys = Object.keys(images.data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));

  }

  let tableData = [];
  if (images && images.data && images.data.length > 0) {
    tableData = images.data.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      if (item.folder && item.folder.folderName) {
        item.folder = item.folder.folderName;
      }
      return item;
    });
  }

  const deleteImage = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/remove-local-image/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je obrisana.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/gallery');
    } catch (error) {
      console.log(error.message);
    }
  };

  const hideModal = () => {
    setIsVisible(false);
    setIsVisibleMulti(false);
    if (chosenFolderForm) {
      setChosenFolderForm();
    }
    form.resetFields();
  };

  const updateImage = async (values) => {
    try {
      await Axios.put(
        `${SERVER_URL}/images/${foundImage}`,
        { ...values, folder: chosenFolderForm },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'Izmenjena je slika.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/gallery');
      setChosenFolderForm();
      setFoundImage();
    } catch (err) {
      notification.error({
        message: 'Problem sa izmenom. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };
  const updateMany = async (values) => {

    try {
      await Axios.put(`${SERVER_URL}/imagesmulti`, {
        ...values,
        folder: chosenFolderForm,
        images: selectedImages,
      });
      notification.success({
        message: 'Izmenjene su slike.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/gallery');
      setChosenFolderForm();
      setFoundImage();
    } catch (err) {
      notification.error({
        message: 'Problem sa izmenom. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const deleteMany = async () => {
    try {
      const selectedImagesIds = selectedImages.map((image) => {
        return {
          id: image._id,
          productName: image.productName,
        };
      });
      await Axios.delete(`${SERVER_URL}/imagesmulti`, {
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
        // withCredentials: false,
        data: {
          images: selectedImagesIds,
        },
      });
      notification.success({
        message: 'Stavke su obrisane.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/gallery');
    } catch (error) {
      console.log(error.message);
    }
  };

  const editModal = async (id) => {
    setFoundImage(id);
    const response = await Axios.get(`${SERVER_URL}/images/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    setIsVisible(true);
    form.setFieldsValue({
      originalname: response.data.originalname,
    });
    if (response.data.folder) {
      setChosenFolderForm(response.data.folder._id);
    }
  };

  const removeFromFolder = (value) => {
    if (value) {
      setChosenFolderForm();
    }
  };

  // <Button
  //   onClick={() => setChosenFolder(item._id)}
  //   style={{ marginRight: '5px' }}
  // >
  //   <FolderOutlined /> {item.folderName}
  // </Button>;

  return (
    <div className="content-wrapper new-gallery">
      <div className="actions-block">
        <Link to="/admin/gallery/new-content">
          <Button type="primary">Dodaj nove slike</Button>
        </Link>
      </div>
      <div style={{ marginBottom: '10px', display: 'flex' }}>
        {data && data.data && data.data.items && data.data.items.length !== 0 && (
          <Select
            className="select-folder"
            onSelect={(values) => setChosenFolder(values)}
            style={{ display: 'unset' }}
          >
            {data.data.items.map((item) => (
              <Select.Option value={item._id}>{item.folderName}</Select.Option>
            ))}
            <Select.Option value={'Prikazi sve'}>Prikaži sve</Select.Option>
          </Select>
        )}
        <Button
          style={{ marginRight: '5px' }}
          type="primary"
          disabled={!selectedImages ? true : false}
          // onClick={() => setChosenFolder('Slike proizvoda')}
          onClick={() => {
            setIsVisible(!isVisible);
            setIsVisibleMulti(!isVisibleMulti);
          }}
        >
          Dodaj u folder
        </Button>
        <Button
          type="primary"
          disabled={!selectedImages ? true : false}
          onClick={() => setDeleteModal(true)}
        >
          Izbriši
        </Button>
      </div>

      <div className="content">
        {images && images.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: '3rem', marginTop: '5rem', marginLeft: '50%' }}
          />
        )}
        {images && !images.isLoading && images.isError && (
          <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>
        )}
        {images &&
          !images.isLoading &&
          !images.isError &&
          images.data &&
          images.data.length > 0 && (
            <GalleryTable
              isGallery={true}
              data={tableData}
              columnKeys={columnKeys}
              deleteHandler={deleteImage}
              editHandler={editModal}
              title="View Gallery"
              setSelectedImages={setSelectedImages}
            />
          )}
        {images &&
          images.data &&
          !images.isLoading &&
          images.data.length === 0 && (
            <h2 style={{ marginTop: '3rem', textAlign: 'center' }}>
              NO IMAGES
            </h2>
          )}
      </div>
      <Modal
        centered
        visible={isVisible}
        okText="SAČUVAJ"
        cancelText="PONIŠTI"
        title="Izmeni sliku"
        onCancel={hideModal}
        onOk={form.submit}
      >
        <Form
          form={form}
          onFinish={isVisibleMulti ? updateMany : updateImage}
          style={{ marginTop: '20px' }}
        >
          {/* {isVisibleMulti ? 'vise' : 'jedan'} */}
          {!isVisibleMulti && (
            <Form.Item
              name="originalname"
              label="Naziv slike"
              rules={[{ required: true, message: 'Unesite naziv slike!' }]}
            >
              <Input />
            </Form.Item>
          )}

          {data &&
            data.data &&
            data.data.items &&
            data.data.items.length !== 0 && (
              <Form.Item name="selectedfolder" label="Izaberite folder">
                <Select
                  className="select-folder"
                  onSelect={(values) => setChosenFolderForm(values)}
                >
                  {data.data.items.map((item) => (
                    <Select.Option value={item._id}>
                      {item.folderName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          {/* {data &&
            data.data &&
            data.data.items &&
            data.data.items.length !== 0 && (
              <div>
                <p style={{ marginTop: '15px', fontSize: '1.1em' }}>
                  Izaberite folder:
                </p>
                <Radio.Group
                  value={chosenFolderForm}
                  onChange={(event) => setChosenFolderForm(event.target.value)}
                >
                  <Space direction="vertical">
                    {data.data.items.map((item) => (
                      <Radio value={item._id}>{item.folderName}</Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </div>
            )} */}

          {!isVisibleMulti && (
            <div style={{ marginTop: '15px' }}>
              <Checkbox
                onClick={(event) => removeFromFolder(event.target.checked)}
              >
                Ukloni iz foldera
              </Checkbox>
            </div>
          )}
        </Form>
      </Modal>
      <Modal
        centered
        visible={deleteModal}
        okText="IZBRIŠI"
        cancelText="ODUSTANI"
        title="Obriši slike"
        onCancel={() => setDeleteModal(false)}
        onOk={deleteMany}
      >
        Da li ste sigurni da želite da obrišete slike?
      </Modal>
    </div>
  );
};

export default Gallery;
