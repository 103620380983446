import React from 'react';
import { Upload, Button, notification, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../../config';

const UploadFile = ({ token, fileType, fileSavePath, fileList, onChangeHandler, fileName, index }) => {
  return (
    <Upload
      action={`${SERVER_URL}/save-pdf`}
      accept='.pdf'
      name='pdf-file'
      headers={{
        type: fileType,
        Authorization: `Bearer ${token}`,
        uri: fileSavePath,
        timestamp: Date.now().toString(),
        filename: fileType === 'order-pdf' ? fileName : null,
        reference: index !== undefined ? index : null,
      }}
      fileList={fileList}
      beforeUpload={(file) => {
        if(fileType === 'order-pdf' && !fileName){
          notification.error({
            message: 'Order reference is missing',
            placement: 'bottomRight',
          });
        }
        const isAllowedFormat = ['application/pdf'].includes(file.type);
        if (!isAllowedFormat) {
          notification.error({
            message: 'You can only upload PDF!',
            placement: 'bottomRight',
          });
        }
        const isAllowedFileNumber = fileType !== 'document' ? fileList.length === 0 : true;
        if (!isAllowedFileNumber) {
          notification.error({
            message: 'Only one file can be uploaded',
            placement: 'bottomRight',
          });
        }
        return isAllowedFileNumber && isAllowedFormat && (fileType === 'order-pdf' ? fileName ? true : false : true) ;
      }}
      onChange={({ file }) => {
        onChangeHandler(file, index)
        if (file.status === 'done') {
          if (file.response && file.response.file) {
            message.success(`${file.response.file.originalname} file uploaded successfully.`);
          }
        } else if (file.status === 'error') {
          message.error(`${file.response.file.originalname} file upload failed.`);
        }
      }}
    >
      <Button icon={<UploadOutlined />}>Upload</Button>
    </Upload>
  );
};
export default UploadFile;
