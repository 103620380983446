import React, { useEffect, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import queryString from "query-string";
import { SERVER_URL } from "../../config";
import { DataForm } from "../../components/forms";

const EditData = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios("", {}, currentuser.data.token, "get");
  const [categories, fetchCategories] = useAxios(
    "",
    [],
    currentuser.data.token,
    "get",
  );
  const [allData, fetchAllData] = useAxios(
    "",
    [],
    currentuser.data.token,
    "get",
  );
  const [featureImage, fetchfeatureImage] = useState(null);
  const [sizeTableImage, fetchSizeTableImage] = useAxios(
    "",
    null,
    currentuser.data.token,
    "get",
  );
  const [verticalImage, fetchVerticalImage] = useAxios(
    "",
    null,
    currentuser.data.token,
    "get",
  );
  const [declarations, fetchDeclarations] = useAxios(
    "",
    [],
    currentuser.data.token,
    "get",
  );
  const [variations, fetchVariations] = useAxios(
    "",
    [],
    currentuser.data.token,
    "get",
  );
  const [parentData, fetchParentData] = useAxios(
    "",
    [],
    currentuser.data.token,
    "get",
  );
  const [suppliers, fetchSuppliers] = useAxios(
    "",
    [],
    currentuser.data.token,
    "get",
  );
  const [gallery, fetchGallery] = useAxios(
    "",
    [],
    currentuser.data.token,
    "get",
  );
  const [pictogram, fetchPictogram] = useAxios(
    "",
    [],
    currentuser.data.token,
    "get",
  );
  const [documentFile, fetchDocumentFile] = useAxios(
    "",
    null,
    currentuser.data.token,
    "get",
  );
  const [variationId, setVariationId] = useState(null);
  const { imgId, imageIds, pictogramIds, sizeTableImageId, verticalImageId } =
    queryString.parse(props.location.search);
  const [fileList, setFileList] = useState([]);
  const { dataId } = props.match.params;
  const newVariation = props.location.pathname.includes("new-variation");

  const prevForm = sessionStorage.getItem("dataForm");
  const values = JSON.parse(prevForm);

  useEffect(() => {
    fetchCategories(`${SERVER_URL}/categories`, []);
    fetchAllData(`${SERVER_URL}/data`, []);
    if (dataId) {
      fetchData(`${SERVER_URL}/data/${dataId}`, {});
      fetchVariations(`${SERVER_URL}/data-variations/${dataId}`, []);
    }
    if (data && data.data && data.data.variationParent) {
      fetchParentData(
        `${SERVER_URL}/data/parent-data/${data.data.variationParent}`,
        [],
      );
    }
    if (data) {
      fetchfeatureImage(data.data.featureImage);
    }
    if (sizeTableImageId)
      fetchSizeTableImage(`${SERVER_URL}/images/${sizeTableImageId}`, {});
    if (verticalImageId)
      fetchVerticalImage(`${SERVER_URL}/images/${verticalImageId}`, {});
    if (imageIds)
      fetchGallery(
        `${SERVER_URL}/images?filter=${JSON.stringify({
          _id: { $in: imageIds.split(",") },
        })}&select=url -uploadedBy`,
        [],
      );
    if (pictogramIds)
      fetchPictogram(
        `${SERVER_URL}/images?filter=${JSON.stringify({
          _id: { $in: pictogramIds.split(",") },
        })}&select=url -uploadedBy`,
        [],
      );
    // if(newVariation) fetchPictogram(`${SERVER_URL}/images?filter=${JSON.stringify({ _id: { $in: da.split(',') } })}&select=url -uploadedBy`, [])
    fetchDeclarations(`${SERVER_URL}/declarations`);
    fetchSuppliers(`${SERVER_URL}/suppliers`, []);

    if (values && values.documents)
      fetchDocumentFile(
        `${SERVER_URL}/all-pdfs?ids=${JSON.stringify(values.documents)}`,
        [],
      );

    const getFiles = async () => {
      if (
        !documentFile.isLoading &&
        documentFile.data &&
        currentuser.language
      ) {
        const files = documentFile.data.map(async (item) => {
          const url = await getPdf(item.localPath);
          return {
            name:
              item.type === "documentation"
                ? item.modifiedname
                : item.originalname,
            _id: item._id,
            uid: "-1",
            status: "done",
            url: url,
          };
        });
        const fileList = await Promise.all(files);
        setFileList(fileList);
      } else if (
        dataId &&
        !data.isLoading &&
        data.data &&
        currentuser.language &&
        data.data.documents &&
        !values
      ) {
        const files = data.data.documents
          .filter((item) => item)
          .map(async (item) => {
            const url = await getPdf(item.localPath);
            return {
              name:
                item.type === "documentation"
                  ? item.modifiedname
                  : item.originalname,
              _id: item._id,
              uid: "-1",
              status: "done",
              url: url,
            };
          });
        const fileList = await Promise.all(files);
        setFileList(fileList);
      }
    };
    getFiles();
  }, [
    fetchCategories,
    fetchData,
    fetchfeatureImage,
    fetchVerticalImage,
    fetchSizeTableImage,
    fetchVariations,
    fetchDeclarations,
    fetchSuppliers,
    fetchParentData,
    fetchGallery,
    fetchAllData,
    data,
    dataId,
    imgId,
    imageIds,
    sizeTableImageId,
    verticalImageId,
    fetchDocumentFile,
    documentFile,
  ]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? "post" : newVariation ? "post" : "put";
    if (!isNew && newVariation) {
      delete formData._id;
      delete formData.title;
      delete formData.code;
    }
    formData.pictogram = formData?.pictogram.map((p, index) => ({
      image: p?._id,
      text: formData?.pictogramText[index],
    }));
    const route = isNew
      ? `${SERVER_URL}/data`
      : newVariation
      ? `${SERVER_URL}/data-new-variation/${dataId}?variation=${variationId}`
      : `${SERVER_URL}/data/${dataId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Data ${isNew || newVariation ? "created." : "updated."}`,
        placement: "bottomRight",
      });
      if (newVariation) {
        history.push(`/admin/edit-data/${dataId}`);
      } else if (isNew) {
        history.push("/admin/data");
      } else {
        setTimeout(() => {
          window.location.href = `/admin/edit-data/${dataId}`;
        }, 1200);
      }
    } catch (error) {
      const msg = error.response
        ? error.response.data.message
        : error.message
        ? error.message
        : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  const getPdf = async (path) => {
    let url = "";
    await Axios.get(`${SERVER_URL}/get-pdf?fileName=${path}`, {
      withCredentials: false,
      responseType: "arraybuffer",
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    })
      .then((res) => {
        const blob = new Blob([res.data], { type: "application/pdf" });
        url = URL.createObjectURL(blob);
      })
      .catch((err) => console.log(err.message));
    return url;
  };

  const onChangeHandler = async (file) => {
    if (file.status === "uploading") {
      setFileList([...fileList.filter((item) => item.status === "done"), file]);
    } else if (file.status === "done") {
      const url = await getPdf(file.response.file.localPath);
      setFileList([
        ...fileList.filter((item) => item.status === "done"),
        { ...file, url },
      ]);
    } else if (
      file.status === "removed" ||
      (file.response && file.response.status === "removed")
    ) {
      let fileId =
        file.response && file.response.file ? file.response.file._id : file._id;

      setFileList(
        fileList.filter((item) => {
          if (item._id) {
            return item._id !== fileId;
          } else if (item.response && item.response.file) {
            return item.response.file._id !== fileId;
          }
        }),
      );
      const check =
        data.data &&
        data.data.documents &&
        data.data.documents.some((item) => {
          if (item._id) {
            return item._id === fileId;
          } else if (item.response && item.response.file) {
            return item.response.file._id === fileId;
          }
        });
      if (!dataId || (dataId && !check)) {
        await Axios.get(`${SERVER_URL}/pdfs/${fileId}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        }).then(async (res) => {
          await Axios.get(
            `${SERVER_URL}/delete-pdf?file=${JSON.stringify(res.data)}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
        });
      }
    }
  };
  const handleVariation = async (id) => {
    let variation = {};
    try {
      const variationResponse = await Axios.get(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      variation = variationResponse.data;
    } catch (error) {
      console.log(error);
    }
    setVariationId(id);
    return variation;
  };

  const cancelVariation = async () => {
    try {
      await Axios.get(`${SERVER_URL}/variation-cancel/${dataId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: "Varijanta je deaktivirana",
        placement: "bottomRight",
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const isDataFetched =
    !data?.isLoading &&
    data.data &&
    !allData.isLoading &&
    allData.data &&
    !categories.isLoading &&
    categories.data &&
    currentuser.language &&
    !featureImage?.isLoading &&
    !declarations.isLoading &&
    declarations.data &&
    !variations.isLoading &&
    variations.data &&
    !suppliers.isLoading &&
    suppliers.data.items;
  const removeFeatureImage = imgId === "";
  const removeGalleryImages = imageIds === "";
  const removePictogramImages = pictogramIds === "";
  const removeSizeTableImage = sizeTableImageId === "";
  const removeVerticalImage = verticalImageId === "";

  return (
    <div style={{ padding: "8px" }}>
      <div className="actions-block">
        <Link to="/admin/data">
          <Button type="primary">Svi proizvodi</Button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: "center" }}>
            <LoadingOutlined
              spin
              style={{ fontSize: "3rem", marginTop: "5rem" }}
            />
          </div>
        )}
        {!dataId && isDataFetched && (
          <DataForm
            isNew={true}
            isNewVariation={false}
            featureImage={featureImage}
            verticalImage1={verticalImage.data}
            sizeTableImage={sizeTableImage.data}
            removeFeatureImage={removeFeatureImage}
            removeSizeTableImage={removeSizeTableImage}
            removeVerticalImage={removeVerticalImage}
            removeGalleryImages={removeGalleryImages}
            gallery={gallery.data}
            pictogram={pictogram.data}
            categories={categories.data}
            allData={allData.data.items}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            declarations={declarations.data}
            suppliers={suppliers.data.items}
            fileList={fileList}
            handleFileChange={onChangeHandler}
          />
        )}
        {dataId && !data.isError && data.data && isDataFetched && (
          <DataForm
            isNew={false}
            isNewVariation={newVariation}
            variations={
              !data.data.variationParent ? variations.data : parentData.data
            }
            data={data.data}
            allData={allData.data.items}
            featureImage={featureImage}
            removeFeatureImage={removeFeatureImage}
            removeGalleryImages={removeGalleryImages}
            removeSizeTableImage={removeSizeTableImage}
            removeVerticalImage={removeVerticalImage}
            removePictogramImages={removePictogramImages}
            gallery={gallery.data}
            sizeTableImage={sizeTableImage.data}
            verticalImage1={verticalImage.data}
            pictogram={!newVariation ? pictogram.data : data.data.pictogram}
            categories={categories.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            declarations={declarations.data}
            suppliers={suppliers.data.items}
            fileList={fileList}
            handleFileChange={onChangeHandler}
            handleVariation={handleVariation}
            cancelVariation={cancelVariation}
          />
        )}
      </div>
    </div>
  );
};

export default EditData;
