import React from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Divider, Input, Select, /* DatePicker, */ Button, Form, Row, Col } from 'antd';
const { Option } = Select;

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
};


const formInit = {
    _id: null,
    title: {},
    shorthandName: {},
};

const SupplierForm = ({ isNew, data, onSubmit, language }) => {
    const [form] = Form.useForm();
    const history = useHistory();
    let initialValues = { ...formInit, ...data };

    const onFinish = async (values, isNew) => {
        onSubmit(values, isNew);
    };

    return (
        <div className='panel panel-body'>
            <div className='panel-body'>
                <Form
                    className='form-horizontal'
                    layout={formItemLayout}
                    initialValues={initialValues}
                    onFinish={(values) => onFinish(values, !data)}
                    layout='vertical'
                    form={form}>
                    <Row type='flex' gutter={16}>
                        <Col xs={24} md={16}>
                            <div className='panel-body'>
                                <Tabs tabPosition='left' defaultActiveKey={language.default.code}>
                                    {language.list.map((lang) => (
                                        <Tabs.TabPane tab={lang.code} key={lang.code}>
                                            <Form.Item
                                                label='Naslov'
                                                rules={[{ required: lang.code === language.default.code ? true : false, message: 'Unesite naslov!' }]}
                                                name={['title', lang.code]}
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item label='Skraćeno ime' name={['shorthandName', lang.code]}>
                                                <Input />
                                            </Form.Item>

                                        </Tabs.TabPane>
                                    ))}
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                    <div className='text-right'>
                        <Button type='primary' htmlType='submit'>
                            {isNew ? 'Dodaj' : 'Izmeni'} dobavljača
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default SupplierForm;
