import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Divider, Input, Select, Button, Form, Checkbox } from 'antd';
import JoditEditor from 'jodit-react';
import useAxios from '../../hooks/useAxios';
import UploadBox from '../base/UploadBox';

const configJodit = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: '300',
  buttons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    // 'font',
    'fontsize',
    '\n',
    'brush',
    'paragraph',
    'link',
    'align',
    // '|',
    'undo',
    'redo',
    'selectall',
    'cut',
    'copy',
    'paste',
    'copyformat',
    '|',
    'hr',
    'symbol',
    'fullsize',
    'print',
    'preview',
    'find',
  ],
};

let formInit = {
  name: {},
  SEOTitle: {},
  SEODescription: {},
  SEOKeywords: {},
  content: {},
  image: undefined,
  loading: false,
  attributes: [],
  activeCategory: undefined,
  showInMainMenu: undefined,
};

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};

const CategoryForm = ({
  onSubmit,
  category,
  token,
  language,
  setEdit,
  SERVER_URL,
  categoryImage,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [tab, setTab] = useState();
  const [attributes, fetchAttributes] = useAxios('', [], token, 'get');
  ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach(
    (key) => delete category[key],
  );

  useEffect(() => {
    fetchAttributes(`${SERVER_URL}/attributes?filter={"isGlobal":false}`, []);
    if (language && language.selected) setTab(language.selected.code);
  }, [fetchAttributes, language, SERVER_URL]);

  let initialValues = { ...formInit, ...category };

  const prevForm = sessionStorage.getItem('categoryForm');
  if (prevForm) initialValues = JSON.parse(prevForm);

  let image;
  if (category && category.image) image = category.image.url;

  if (categoryImage && categoryImage.url) {
    image = categoryImage.url;
    form.setFieldsValue({ image });
  }

  const onFinish = async (values, isNew) => {
    sessionStorage.removeItem('categoryForm');
    if (!isNew) {
      values._id = category._id;
    }
    if (categoryImage) {
      values.image = categoryImage._id ? categoryImage._id : undefined;
    }
    onSubmit(values, isNew);
  };

  const imageHandler = (isNew) => {
    const values = form.getFieldsValue();
    sessionStorage.setItem('categoryForm', JSON.stringify(values));
    history.push(
      `/admin/gallery/edit-content?fromPage=${
        isNew ? 'data-categories/new' : 'data-categories/' + category._id
      }${values.image ? '&imageIds=' + values.image._id.toString() : ''}`,
    );
  };

  return (
    <div className="panel panel-primary">
      <div className="panel-heading">
        <h4 className="panel-title">
          <span>{!category.name ? 'DODAJ' : 'IZMENI'}</span>
          <span
            style={{ cursor: 'pointer', padding: '6px', background: 'red' }}
            onClick={() => {
              setEdit({ visible: false, category: {} });
            }}
          >
            X
          </span>
        </h4>
      </div>
      <div className="panel-body">
        <Form
          layout={formItemLayout}
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !category.name)}
          layout="vertical"
          form={form}
        >
          <h3 style={{ marginBottom: '8px' }}>{tab} language</h3>
          <Tabs
            onChange={(key) => setTab(key)}
            tabPosition="left"
            defaultActiveKey={language ? language.default.code : undefined}
          >
            {language.list.map((lang) => (
              <Tabs.TabPane tab={lang.code} key={lang.code}>
                <Form.Item
                  label="Ime"
                  rules={[
                    {
                      required:
                        lang.code === language && language.default.code
                          ? true
                          : false,
                      message: 'Unesite ime kategorije!',
                    },
                  ]}
                  name={['name', lang.code]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="SEO Title" name={['SEOTitle', lang.code]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  label="SEO Description"
                  name={['SEODescription', lang.code]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="SEO Keywords"
                  name={['SEOKeywords', lang.code]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Sadržaj" name={['content', lang.code]}>
                  <JoditEditor
                    name={`content[${lang.code}]`}
                    style={{ margin: '2px 0px', minHeight: '350px' }}
                    config={configJodit}
                  />
                </Form.Item>
              </Tabs.TabPane>
            ))}
          </Tabs>
          <Divider type="horizontal" />
          <Form.Item label="Atributi" name="attributes">
            <Select mode="multiple">
              {attributes.data &&
                attributes.data.items &&
                attributes.data.items.length > 0 &&
                attributes.data.items.map((attr) => (
                  <Select.Option value={attr._id} key={attr._id}>
                    {attr.name[language.selected.code]}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="activeCategory"
            label="Aktivna kategorija"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="showInMainMenu"
            label="Prikazi kategoriju u glavnom meniju"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item label="Slika" name="image" valuePropName="image">
            <UploadBox
              handler={() => imageHandler(!category._id)}
              image={image}
            />
          </Form.Item>
          <div className="text-right">
            <Button type="primary" htmlType="submit">
              Potvrdi
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CategoryForm;
