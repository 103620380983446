import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import slugify from "slugify";
import { getTreeFromFlatData } from "react-sortable-tree";
import JoditEditor from "jodit-react";
import {
  Tabs,
  Divider,
  Input,
  Checkbox,
  Select,
  /* DatePicker, */ Button,
  Form,
  Row,
  Col,
  TreeSelect,
  Radio,
} from "antd";
import moment from "moment";
import useAxios from "../../hooks/useAxios";
import UploadBox from "../base/UploadBox";
import UploadFile from "../base/UploadFile";
const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
};

const configJodit = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: "300",
  buttons: [
    "source",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "eraser",
    "|",
    "superscript",
    "subscript",
    "|",
    "ul",
    "ol",
    "|",
    "outdent",
    "indent",
    "|",
    // 'font',
    "fontsize",
    "\n",
    "brush",
    "paragraph",
    "link",
    "align",
    // '|',
    "undo",
    "redo",
    "selectall",
    "cut",
    "copy",
    "paste",
    "copyformat",
    "|",
    "hr",
    "symbol",
    "fullsize",
    "print",
    "preview",
    "find",
  ],
};

const formInit = {
  _id: null,
  title: {},
  googleDescription: {},
  keywords: {},
  content: {},
  url: "",
  code: "",
  // price: 0,
  discountWholesale: false,
  discountRetail: false,
  discountWholesalePrice: 0,
  discountRetailPrice: 0,
  fullUrl: "",
  featuredAd: false,
  notActive: false,
  featuredAdUntil: "",
  status: "",
  category: "",
  categoryPath: "",
  attributes: [],
  supplier: null,
  featureImage: undefined,
  gallery: [],
  sizeTableImage: undefined,
  verticalImage: undefined,
  variations: [],
  dataViews: "wholesale",
  wholesalePrice: 0,
  retailPrice: 0,
  documents: [],
  declaration: null,
  superPrice: false,
  newProduct: false,
  pictogram: [],
  pictogramText: [],
  dontShowRebate: false,
};

const getCategoryPath = (categoryId, categories) => {
  const category = categories.find((cat) => cat._id === categoryId);
  return category ? category.path : "";
};

const DataFrom = ({
  isNew,
  data,
  categories,
  language,
  onSubmit,
  SERVER_URL,
  token,
  featureImage,
  declarations,
  isNewVariation,
  variations,
  suppliers,
  gallery,
  sizeTableImage,
  pictogram,
  removeFeatureImage,
  removeGalleryImages,
  removeSizeTableImage,
  fileList,
  handleFileChange,
  allData,
  handleVariation,
  cancelVariation,
  verticalImage1,
  removeVerticalImage,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const prevForm = sessionStorage.getItem(
    !isNewVariation ? "dataForm" : "variationForm",
  );
  const [attributes, fetchAttributes] = useAxios("", [], token, "get");
  const [globalAttributes, fetchGlobalAttributes] = useAxios(
    "",
    [],
    token,
    "get",
  );
  const [categoryAttributes, setCategoryAttributes] = useState([]);
  const [discountRetailChecked, setDiscountRetailChecked] = useState(
    !prevForm
      ? data && data.discountRetail
      : JSON.parse(prevForm).discountRetail,
  );
  const [discountWholesaleChecked, setDiscountWholesaleChecked] = useState(
    !prevForm
      ? data && data.discountWholesale
      : JSON.parse(prevForm).discountWholesale,
  );
  const [views, setViews] = useState(
    !prevForm && (!data || isNewVariation)
      ? "wholesale"
      : prevForm
      ? JSON.parse(prevForm).dataViews
      : data.dataViews,
  );
  const [deleteFeatureImage, setDeleteFeatureImage] = useState(false);
  const [deleteGalleryImage, setDeleteGalleryImage] = useState(false);
  const [deletePictogramImage, setDeletePictogramImage] = useState(false);
  const [deleteSizeTableImage, setDeleteSizeTableImage] = useState(false);
  const [deleteVerticalImage, setDeleteVerticalImage] = useState(false);

  const documentIds = fileList.map((item) => {
    if (item._id) {
      return item._id;
    }
    if (item.response && item.response.file) {
      return item.response.file._id;
    }
  });

  if (data)
    ["children", "createdAt", "path", "updatedAt", "__v"].forEach(
      (key) => delete data[key],
    );
  let initialValues = {
    ...formInit,
    ...data,
    pictogramText: data?.pictogram?.map((p) => p.text),
    pictogram: data?.pictogram?.map((p) => p.image),
  };
  let pictogramText;
  let pictogramImage = pictogram;

  if (isNewVariation) {
    pictogramImage = pictogram.map((item) => item.image);
    pictogramText = pictogram.map((item) => item.text);
    initialValues = {
      ...formInit,
      pictogram: pictogramImage,
      pictogramText,
      featureImage: data.featureImage,
      gallery: data.gallery,
      sizeTableImage: data.sizeTableImage,
      verticalImage: data.verticalImage,
      category: data.category,
      supplier: data.supplier,
      content: data.content,
      declaration: data.declaration,
      googleDescription: data.googleDescription,
      keywords: data.keywords,
    };
  }

  if (prevForm) initialValues = JSON.parse(prevForm);
  if (gallery.length > 0 && !deleteGalleryImage) {
    form.setFieldsValue({ gallery });
  } else if (deleteGalleryImage) {
    form.setFieldsValue({ gallery: [] });
  }

  if (pictogram && pictogram.length > 0 && !deletePictogramImage) {
    form.setFieldsValue({ pictogram: pictogramImage });
  } else if (deletePictogramImage) {
    form.setFieldsValue({ pictogram: [] });
  }

  let image = !deleteFeatureImage
    ? initialValues.featureImage
      ? initialValues.featureImage.url
      : undefined
    : null;

  if (featureImage && featureImage.url) {
    image = featureImage.url;
    form.setFieldsValue({ featureImage });
  }
  if (removeFeatureImage) {
    image = undefined;
    form.setFieldsValue({ featureImage: null });
  }

  let sizeImage = !deleteSizeTableImage
    ? initialValues.sizeTableImage
      ? initialValues.sizeTableImage.url
      : undefined
    : null;

  if (sizeTableImage && sizeTableImage.url) {
    sizeImage = sizeTableImage.url;
    form.setFieldsValue({ sizeTableImage });
  }
  if (removeSizeTableImage) {
    sizeImage = undefined;
    form.setFieldsValue({ sizeTableImage: null });
  }

  let verticalImage = !deleteVerticalImage
    ? initialValues.verticalImage
      ? initialValues.verticalImage.url
      : undefined
    : null;

  if (verticalImage1 && verticalImage1.url) {
    verticalImage = verticalImage1.url;
    form.setFieldsValue({ verticalImage });
  }
  if (removeVerticalImage) {
    verticalImage = undefined;
    form.setFieldsValue({ verticalImage: null });
  }

  const radioOptions = [
    { label: "Wholesale", value: "wholesale" },
    { label: "Retail", value: "retail" },
    { label: "Wholesale And Retail", value: "wholesaleAndRetail" },
  ];

  useEffect(() => {
    fetchGlobalAttributes(
      `${SERVER_URL}/attributes?filter={"isGlobal":true}`,
      [],
    );
    if (data) {
      if (data.featuredAdUntil)
        form.setFieldsValue({ featuredAdUntil: moment(data.featuredAdUntil) });
      if (data.category) {
        const categoryPath = getCategoryPath(data.category, categories);
        fetchAttributes(
          `${SERVER_URL}/attributes/category/${categoryPath}`,
          [],
        );
      }
      if (data.attributes && data.attributes.length > 0) {
        data.attributes.forEach((d) => {
          if (d && d.dateValue) {
            d.dateValue = moment(d.dateValue);
          }
        });
      }
    }
  }, [
    data,
    categories,
    form,
    SERVER_URL,
    fetchAttributes,
    fetchGlobalAttributes,
  ]);

  useEffect(() => {
    if (globalAttributes.data.items && attributes.data) {
      setCategoryAttributes([
        ...globalAttributes.data.items,
        ...attributes.data,
      ]);
    }
  }, [attributes, globalAttributes]);

  const treeData = getTreeFromFlatData({
    flatData: categories.map((node) => ({
      title: node.name[language.selected.code],
      value: node._id,
      key: node._id,
      parent: node.parent,
      _id: node._id,
    })),
    getKey: (node) => node._id, // resolve a node's key
    getParentKey: (node) => node.parent, // resolve a node's parent's key
    rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
  });

  const onChangeAttribute = (index, attrId, name, value) => {
    let prevAttributes = form.getFieldValue("attributes");
    const attr = {
      attributeId: attrId,
      [name]:
        name === "values" ? (!Array.isArray(value) ? [value] : value) : value,
    };
    prevAttributes[index] = attr;
    form.setFieldsValue({ attributes: prevAttributes });
  };

  const onFinish = async (values, isNew) => {
    sessionStorage.removeItem("dataForm");
    if (isNewVariation) {
      sessionStorage.removeItem("variationForm");
    }
    if (!isNew) {
      values._id = data._id;
      if (featureImage) {
        values.featureImage = featureImage
          ? featureImage._id
          : data.featureImage
          ? data.featureImage._id
          : undefined;
      }
    } else {
      if (featureImage) {
        values.featureImage = featureImage ? featureImage._id : undefined;
      }
    }

    if (!isNew) {
      values._id = data._id;
      if (verticalImage1) {
        values.verticalImage = verticalImage1
          ? verticalImage1._id
          : data.verticalImage
          ? data.verticalImage._id
          : undefined;
      }
    } else {
      if (verticalImage1) {
        values.verticalImage1 = verticalImage1 ? verticalImage1._id : undefined;
      }
    }

    if (!isNew) {
      values._id = data._id;
      if (sizeTableImage) {
        values.sizeTableImage = sizeTableImage
          ? sizeTableImage._id
          : data.sizeTableImage
          ? data.sizeTableImage._id
          : undefined;
      }
    } else {
      if (sizeTableImage) {
        values.sizeTableImage = sizeTableImage ? sizeTableImage._id : undefined;
      }
    }

    if (values.dataViews === "retail") {
      values.wholesalePrice = 0;
    } else if (values.dataViews === "wholesale") {
      values.retailPrice = 0;
    }
    if (!values.discountRetail) {
      values.discountRetailPrice = 0;
      values.discountRetail = false;
    }
    if (!values.discountWholesale) {
      values.discountWholesalePrice = 0;
      values.discountWholesale = false;
    }
    values.documents = documentIds;
    onSubmit(values, isNew);
  };

  const imageHandler = () => {
    if (isNewVariation === false) {
      const values = form.getFieldsValue();
      if (!values.retailPrice) {
        values["retailPrice"] = 0;
      }
      if (!values.wholesalePrice) {
        values["wholesalePrice"] = 0;
      }
      sessionStorage.setItem(
        isNewVariation ? "variationForm" : "dataForm",
        JSON.stringify({
          ...values,
          documents: documentIds,
        }),
      );
      // history.push(`/admin/gallery/edit-content/${isNew ? 'new-data/img' : isNewVariation ? 'edit-variation/' + data._id  : 'edit-data/' + data._id }`);
      history.push(
        `/admin/gallery/edit-content?limit=1&fromPage=${
          isNew
            ? "new-data"
            : isNewVariation
            ? "edit-variation/" + data._id
            : "edit-data/" + data._id
        }${
          values.featureImage
            ? "&imageIds=" + values.featureImage._id.toString()
            : ""
        }`,
      );
    }
  };

  const verticalImageHandler = () => {
    if (isNewVariation === false) {
      const values = form.getFieldsValue();
      if (!values.retailPrice) {
        values["retailPrice"] = 0;
      }
      if (!values.wholesalePrice) {
        values["wholesalePrice"] = 0;
      }
      sessionStorage.setItem(
        isNewVariation ? "variationForm" : "dataForm",
        JSON.stringify({
          ...values,
          documents: documentIds,
        }),
      );
      // history.push(`/admin/gallery/edit-content/${isNew ? 'new-data/img' : isNewVariation ? 'edit-variation/' + data._id  : 'edit-data/' + data._id }`);
      history.push(
        `/admin/gallery/edit-content?verticalImage=true&limit=1&fromPage=${
          isNew
            ? "new-data"
            : isNewVariation
            ? "edit-variation/" + data._id
            : "edit-data/" + data._id
        }${
          values.verticalImage
            ? "&verticalImageId=" + values.verticalImage._id.toString()
            : ""
        }`,
      );
    }
  };

  const sizeTableImageHandler = () => {
    if (isNewVariation === false) {
      const values = form.getFieldsValue();
      if (!values.retailPrice) {
        values["retailPrice"] = 0;
      }
      if (!values.wholesalePrice) {
        values["wholesalePrice"] = 0;
      }
      sessionStorage.setItem(
        isNewVariation ? "variationForm" : "dataForm",
        JSON.stringify({
          ...values,
          documents: documentIds,
        }),
      );
      // history.push(`/admin/gallery/edit-content/${isNew ? 'new-data/img' : isNewVariation ? 'edit-variation/' + data._id  : 'edit-data/' + data._id }`);
      history.push(
        `/admin/gallery/edit-content?sizeTableImage=true&limit=1&fromPage=${
          isNew
            ? "new-data"
            : isNewVariation
            ? "edit-variation/" + data._id
            : "edit-data/" + data._id
        }${
          values.sizeTableImage
            ? "&sizeTableImageId=" + values.sizeTableImage._id.toString()
            : ""
        }`,
      );
    }
  };

  const galleryHandler = () => {
    if (isNewVariation === false) {
      const values = form.getFieldsValue();
      sessionStorage.setItem(
        "dataForm",
        JSON.stringify({ ...values, documents: documentIds }),
      );
      // history.push(`/admin/gallery/edit-content/${isNew ? 'new-data/img' : isNewVariation ? 'edit-variation/' + data._id  : 'edit-data/' + data._id }`);
      history.push(
        `/admin/gallery/edit-content?gallery=true&limit=100&fromPage=${
          isNew
            ? "new-data"
            : isNewVariation
            ? "edit-variation/" + data._id
            : "edit-data/" + data._id
        }${
          values.gallery
            ? "&imageIds=" + values.gallery.map((img) => img._id).join(",")
            : ""
        }`,
      );
    }
  };

  const pictogramHandler = () => {
    if (isNewVariation === false) {
      const values = form.getFieldsValue();
      sessionStorage.setItem(
        "dataForm",
        JSON.stringify({ ...values, documents: documentIds }),
      );
      // history.push(`/admin/gallery/edit-content/${isNew ? 'new-data/img' : isNewVariation ? 'edit-variation/' + data._id  : 'edit-data/' + data._id }`);
      history.push(
        `/admin/gallery/edit-content?pictogram=true&limit=12&fromPage=${
          isNew
            ? "new-data"
            : isNewVariation
            ? "edit-variation/" + data._id
            : "edit-data/" + data._id
        }${
          values.pictogram
            ? "&pictogramIds=" +
              values.pictogram.map((img) => img._id).join(",")
            : ""
        }`,
      );
    }
  };

  const addVariation = () => {
    history.push("/admin/edit-variation/" + data._id);
  };

  const onRadioChange = (e) => {
    setViews(e.target.value);
    // if(value === 'retail'){
    //   form.setFieldsValue({ wholesalePrice: 0});
    // } else if(value === 'wholesale'){
    //   form.setFieldsValue({ retailPrice: 0 });
    // }
  };

  const discountRetailHandler = (e) => {
    setDiscountRetailChecked(e.target.checked);
    form.setFieldsValue({ discountRetailPrice: 0 });
  };

  const discountWholesaleHandler = (e) => {
    setDiscountWholesaleChecked(e.target.checked);
    form.setFieldsValue({ discountWholesalePrice: 0 });
  };

  let currentGallery = form.getFieldValue("gallery");
  let currentPictogram = form.getFieldValue("pictogram");

  if (removeGalleryImages) {
    form.setFieldsValue({ gallery: [] });
  }
  allData.sort((a, b) => {
    if (a?.title?.en > b?.title?.en) {
      return 1;
    }
    return -1;
  });

  const setUrl = (value) => {
    form.setFieldsValue({ url: slugify(value, { lower: true }) });
  };

  const deactivateFeatureImage = () => {
    form.setFieldsValue({ featureImage: null });
    setDeleteFeatureImage(true);
  };

  const deactivateSizeTableImage = () => {
    form.setFieldsValue({ sizeTableImage: null });
    setDeleteSizeTableImage(true);
  };

  const deactivateVerticalImage = () => {
    form.setFieldsValue({ verticalImage: null });
    setDeleteVerticalImage(true);
  };

  const deactivateGalleryImage = () => {
    setDeleteGalleryImage(true);
  };

  const deactivatePictogramImage = () => {
    setDeletePictogramImage(true);
  };

  if (variations && variations.length > 0) {
    variations.sort(function (a, b) {
      if (a.title.en < b.title.en) {
        return -1;
      }
      if (a.title.en > b.title.en) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <div className="panel panel-body">
      <div className="panel-body">
        <Form
          className="form-horizontal"
          // layout={formItemLayout}
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout="vertical"
          form={form}
        >
          <Row type="flex" gutter={16}>
            <Col xs={24} md={16}>
              <div className="panel-body">
                <Tabs
                  tabPosition="left"
                  defaultActiveKey={language.default.code}
                >
                  {language.list.map((lang) => (
                    <Tabs.TabPane tab={lang.code} key={lang.code}>
                      {isNewVariation ? (
                        <Form.Item
                          label="Naslov"
                          rules={[
                            {
                              required:
                                lang.code === language.default.code
                                  ? true
                                  : false,
                              message: "Unesite naslov!",
                            },
                          ]}
                          name={["title", lang.code]}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={async (value) => {
                              const variation = await handleVariation(value);
                              form.setFieldsValue({ code: variation.code });
                              setUrl(variation.url);
                            }}
                          >
                            {allData.length > 0 &&
                              allData.map((item, index) => (
                                <Option key={index} value={item._id}>
                                  {item.title[lang.code]}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      ) : (
                        <Form.Item
                          label="Naslov"
                          rules={[
                            {
                              required:
                                lang.code === language.default.code
                                  ? true
                                  : false,
                              message: "Unesite naslov!",
                            },
                          ]}
                          name={["title", lang.code]}
                          onChange={(e) => setUrl(e.target.value)}
                        >
                          <Input />
                        </Form.Item>
                      )}

                      <Form.Item label="Šifra" name="code">
                        {isNewVariation ? (
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={async (value) => {
                              const variation = await handleVariation(value);
                              setUrl(variation.url);
                              form.setFieldsValue({ title: variation.title });
                            }}
                          >
                            {allData.length > 0 &&
                              allData.map((item, index) => (
                                <Option key={index} value={item._id}>
                                  {item.code}
                                </Option>
                              ))}
                          </Select>
                        ) : (
                          <Input />
                        )}
                      </Form.Item>

                      <Form.Item
                        label='SEO Title'
                        name={["seoTitle", lang.code]}
                      >
                          <Input/>
                      </Form.Item>

                      <Form.Item
                        label="Google Description"
                        name={["googleDescription", lang.code]}
                      >
                        <Input
                          disabled={
                            isNewVariation || (data && data.variationParent)
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        label="Google Keywords"
                        name={["keywords", lang.code]}
                      >
                        <Input
                          disabled={
                            isNewVariation || (data && data.variationParent)
                          }
                        />
                      </Form.Item>

                      <Form.Item label="Sadržaj" name={["content", lang.code]}>
                        <JoditEditor
                          name={`content[${lang.code}]`}
                          style={{ margin: "2px 0px", minHeight: "350px" }}
                          config={{
                            ...configJodit,
                            readonly:
                              isNewVariation || (data && data.variationParent)
                                ? true
                                : false,
                          }}
                        />
                      </Form.Item>

                      <Form.Item label="360° pregled" name="view360">
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Omogući 360° pregled"
                        name="view360Active"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>

                      <Form.Item label="Deklaracija" name="declaration">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          disabled={
                            isNewVariation || (data && data.variationParent)
                              ? true
                              : false
                          }
                        >
                          {declarations.length > 0 &&
                            declarations.map((item, index) => (
                              <Option key={index} value={item._id}>
                                {item.name[lang.code]}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Tabs.TabPane>
                  ))}
                </Tabs>

                <Divider type="horizontal" />

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Item
                    style={{ marginRight: "40px" }}
                    label="Izdvojen proizvod"
                    name="featuredAd"
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                  <Form.Item
                    style={{ marginRight: "40px" }}
                    label="Neaktivan proizvod"
                    name="notActive"
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                  <Form.Item
                    style={{ marginRight: "40px" }}
                    label="Super cena"
                    name="superPrice"
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                  <Form.Item
                    style={{ marginRight: "0px" }}
                    label="Novo"
                    name="newProduct"
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>

                  <Form.Item
                    style={{ marginRight: "0px" }}
                    label="Ne prikazuj rabat"
                    name="dontShowRebate"
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                </div>
                {/*<Form.Item label='Variations'>*/}
                {/*  {!isNew && !isNewVariation && variations.length > 0  ? !data.variationParent ? variations.map((item, index) => (*/}
                {/*        <div>*/}
                {/*          <Link to={'/admin/edit-data/' + item._id} key={index} value={item._id}>*/}
                {/*            {item.title.en}*/}
                {/*          </Link>*/}
                {/*        </div>*/}
                {/*  )) : <div>*/}
                {/*    <span style={{margin: '0px 5px 0px 0px'}}>Defined in the parent data</span>*/}
                {/*       <Link to={'/admin/edit-data/' + data.variationParent}>{variations[0].title.en}</Link>*/}
                {/*    </div> : null}*/}
                {/*</Form.Item>*/}

                {/*<div className='text-right'>*/}
                {/*  <Button disabled={isNewVariation || (data && data.variationParent) || isNew} type='primary' onClick={addVariation} >*/}
                {/*    Add variation*/}
                {/*  </Button>*/}
                {/*</div>*/}

                <Form.Item label="Dobavljač" name="supplier">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={isNewVariation || (data && data.variationParent)}
                  >
                    {suppliers.length > 0 &&
                      suppliers.map((item, index) => (
                        <Option key={index} value={item._id}>
                          {item.title.en}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item label="Varijante" name="variations">
                  {!isNewVariation
                    ? variations && variations.length > 0
                      ? variations.map((item, index) =>
                          item.variationParent ? (
                            <div key={index}>
                              <Link to={`/admin/edit-data/${item._id}`}>
                                {item.title.en}
                              </Link>
                            </div>
                          ) : (
                            <div key={index}>
                              <div>Definisano u matičnom proizvodu</div>
                              <Link to={`/admin/edit-data/${item._id}`}>
                                {item.title.en}
                              </Link>
                            </div>
                          ),
                        )
                      : ""
                    : "Definisano u matičnom proizvodu"}
                </Form.Item>
                <div className="text-right">
                  {/*{data && data.variationParent || isNewVariation || isNew ?*/}
                  {/*  <Button type='primary' htmlType='button' onClick={cancelVariation}>Deaktiviraj varijantu</Button> :*/}
                  {/*  <Button onClick={() => history.push(`/admin/new-variation/${data._id}`)} type='primary' htmlType='button'>Nova varijanta</Button>}*/}
                  {data && !data.variationParent && (
                    <div className="text-right">
                      <Button
                        disabled={
                          (data && data.variationParent) ||
                          isNewVariation ||
                          isNew
                        }
                        onClick={() =>
                          history.push(`/admin/new-variation/${data._id}`)
                        }
                        type="primary"
                        htmlType="button"
                      >
                        Nova varijanta
                      </Button>
                    </div>
                  )}
                  {data && data.variationParent && (
                    <div className="text-right">
                      <Button
                        onClick={cancelVariation}
                        type="primary"
                        htmlType="button"
                      >
                        Deaktiviraj varijantu
                      </Button>
                    </div>
                  )}
                </div>

                <Form.Item label="Url" name="url">
                  <Input disabled />
                </Form.Item>

                <Row>
                  <Col span={12}>
                    <Form.Item label="Views" name="dataViews">
                      <Radio.Group
                        onChange={onRadioChange}
                        options={radioOptions}
                      >
                        {radioOptions.map((item, index) => (
                          <Radio key={index} value={item.value}>
                            {item.label}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {(views === "wholesale" ||
                      views === "wholesaleAndRetail") && (
                      <Form.Item
                        label="Wholesale price without PDV"
                        name="wholesalePrice"
                      >
                        <Input disabled={data && !isNewVariation} />
                      </Form.Item>
                    )}

                    {(views === "retail" || views === "wholesaleAndRetail") && (
                      <Form.Item
                        label="Retail price without PDV"
                        name="retailPrice"
                      >
                        <Input disabled={data && !isNewVariation} />
                      </Form.Item>
                    )}
                  </Col>
                </Row>

                <Row type="flex" gutter={4} justify="start" align="top">
                  <Col xs={24} md={8}>
                    {(views === "retail" || views === "wholesaleAndRetail") && (
                      <Form.Item
                        label="Discount retail"
                        name="discountRetail"
                        valuePropName="checked"
                      >
                        <Checkbox onChange={discountRetailHandler} />
                      </Form.Item>
                    )}
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item
                      style={{
                        display:
                          discountRetailChecked &&
                          (views === "retail" || views === "wholesaleAndRetail")
                            ? "inline"
                            : "none",
                      }}
                      label="Discount retail price"
                      name="discountRetailPrice"
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" gutter={4} justify="start" align="top">
                  <Col xs={24} md={8}>
                    {(views === "wholesale" ||
                      views === "wholesaleAndRetail") && (
                      <Form.Item
                        label="Discount wholesale"
                        name="discountWholesale"
                        valuePropName="checked"
                      >
                        <Checkbox onChange={discountWholesaleHandler} />
                      </Form.Item>
                    )}
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item
                      style={{
                        display:
                          discountWholesaleChecked &&
                          (views === "wholesale" ||
                            views === "wholesaleAndRetail")
                            ? "inline"
                            : "none",
                      }}
                      label="Discount wholesale price"
                      name="discountWholesalePrice"
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                </Row>

                {/* <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8}>
                    <Form.Item label='Featured' name='featuredAd' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item label='Featured until' name='featuredAdUntil' getValueProps={() => {}} rules={[{ type: 'object' }]}>
                      <DatePicker style={{ width: '100%' }} size='large' rules={[{ type: 'object' }]} />
                    </Form.Item>
                  </Col>
                </Row> */}
              </div>
            </Col>

            <Col xs={24} md={8}>
              <div className="panel panel-primary">
                <div className="panel-heading">
                  <h4 className="panel-title">Kategorija</h4>
                </div>

                <Form.Item
                  label="Kategorija"
                  name="category"
                  className="panel-body"
                  rules={[{ required: true, message: "Izaberite kategoriju!" }]}
                >
                  <TreeSelect
                    style={{ width: "100%", height: 30 }}
                    disabled={isNewVariation || (data && data.variationParent)}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    name="category"
                    treeData={treeData}
                    placeholder="Izaberite kategoriju"
                    onChange={(value) => {
                      form.setFieldsValue({ attributes: [] });
                      const categoryPath = getCategoryPath(value, categories);
                      fetchAttributes(
                        `${SERVER_URL}/attributes/category/${categoryPath}`,
                        [],
                      );
                    }}
                  />
                </Form.Item>
              </div>

              <div className="panel panel-primary">
                <div className="panel-heading">
                  <h4 className="panel-title">Atributi</h4>
                </div>

                <div className="panel-body">
                  <Form.List name="attributes">
                    {(fields) =>
                      categoryAttributes.length > 0 &&
                      categoryAttributes.map((attr, ind) => {
                        switch (attr.type) {
                          case "MULTICHOICE":
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, "values"]}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please select ${
                                      attr.name[language.selected.code]
                                    }`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Select
                                  mode="multiple"
                                  onChange={(value) =>
                                    onChangeAttribute(
                                      ind,
                                      attr._id,
                                      "values",
                                      value,
                                    )
                                  }
                                >
                                  {attr.values.map((val) => (
                                    <Select.Option
                                      value={val._id}
                                      key={val._id}
                                    >
                                      {val.value[language.selected.code]}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );

                          case "CHOICE":
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, "values", 0]}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please select ${
                                      attr.name[language.selected.code]
                                    }`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Select
                                  onChange={(value) =>
                                    onChangeAttribute(
                                      ind,
                                      attr._id,
                                      "values",
                                      value,
                                    )
                                  }
                                >
                                  {attr.values.map((val) => (
                                    <Select.Option
                                      value={val._id}
                                      key={val._id}
                                    >
                                      {val.value[language.selected.code]}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );

                          case "INPUT":
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, "inputValue"]}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please enter ${
                                      attr.name[language.selected.code]
                                    }`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Input
                                  onChange={(e) =>
                                    onChangeAttribute(
                                      ind,
                                      attr._id,
                                      "inputValue",
                                      e.target.value,
                                    )
                                  }
                                />
                              </Form.Item>
                            );

                          case "NUMBER":
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, "numberValue"]}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please enter ${
                                      attr.name[language.selected.code]
                                    }`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Input
                                  type="number"
                                  onChange={(e) =>
                                    onChangeAttribute(
                                      ind,
                                      attr._id,
                                      "numberValue",
                                      +e.target.value,
                                    )
                                  }
                                />
                              </Form.Item>
                            );

                          case "CHECKBOX":
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, "checkboxValue"]}
                                // className='panel-body'
                                valuePropName="checked"
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please select ${
                                      attr.name[language.selected.code]
                                    }`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Checkbox
                                  onChange={(e) =>
                                    onChangeAttribute(
                                      ind,
                                      attr._id,
                                      "checkboxValue",
                                      e.target.checked,
                                    )
                                  }
                                />
                              </Form.Item>
                            );

                          // ***************** IN PROGRESS *****************
                          // case 'DATE':
                          //   return (
                          //     <Form.Item
                          //       key={attr._id}
                          //       label={attr.name[language.selected.code]}
                          //       name={[ind, 'dateValue']}
                          //       // className='panel-body'
                          //       rules={[
                          //         { type: 'object', required: attr.isRequired, message: `Please select ${attr.name[language.selected.code]}` },
                          //       ]}
                          //       getValueProps={() => {}}
                          //       {...fields}
                          //     >
                          //       <DatePicker
                          //         style={{ width: '100%' }}
                          //         size='large'
                          //         onChange={(date) => onChangeAttribute(ind, attr._id, 'dateValue', date)}
                          //       />
                          //     </Form.Item>
                          //   );

                          default:
                            return null;
                        }
                      })
                    }
                  </Form.List>
                </div>
              </div>

              <div className="panel panel-primary">
                <div className="panel-heading">
                  <h4 className="panel-title">Izdvojena slika</h4>
                </div>

                <Form.Item name="featureImage" valuePropName="image">
                  <div className="panel-body">
                    <UploadBox image={image} handler={imageHandler} />
                  </div>
                </Form.Item>
                <div className="text-right">
                  <Button onClick={deactivateFeatureImage}>
                    Deaktiviraj sliku
                  </Button>
                </div>
              </div>

              <div className="panel panel-primary">
                <div className="panel-heading">
                  <h4 className="panel-title">Galerija slika</h4>
                </div>

                <Form.Item name="gallery" valuePropName="image">
                  <div
                    className="panel-body"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {currentGallery &&
                      currentGallery.length > 0 &&
                      currentGallery.map((item, index) => (
                        <UploadBox
                          key={index}
                          handler={() => {}}
                          image={item.url}
                          preview
                        />
                      ))}
                    <UploadBox handler={galleryHandler} />
                  </div>
                </Form.Item>
                <div className="text-right">
                  <Button onClick={deactivateGalleryImage}>
                    Deaktiviraj slike
                  </Button>
                </div>
              </div>

              <div className="panel panel-primary">
                <div className="panel-heading">
                  <h4 className="panel-title">Slika tabele veličina</h4>
                </div>
                <Form.Item name="sizeTableImage" valuePropName="image">
                  <div className="panel-body">
                    {/* <UploadBox sizeImage={sizeImage} /> */}
                    <UploadBox
                      handler={sizeTableImageHandler}
                      image={sizeImage}
                    />
                  </div>
                </Form.Item>
                <div className="text-right">
                  <Button onClick={deactivateSizeTableImage}>
                    Deaktiviraj sliku
                  </Button>
                </div>
              </div>

              <div className="panel panel-primary">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    Vertikalna slika pored proizvoda
                  </h4>
                </div>
                <Form.Item name="verticalImage" valuePropName="image">
                  <div className="panel-body">
                    {/* <UploadBox sizeImage={sizeImage} /> */}
                    <UploadBox
                      handler={verticalImageHandler}
                      image={verticalImage}
                    />
                  </div>
                </Form.Item>
                <div className="text-right">
                  <Button onClick={deactivateVerticalImage}>
                    Deaktiviraj sliku
                  </Button>
                </div>
              </div>

              <div className="panel panel-primary">
                <div className="panel-heading">
                  <h4 className="panel-title">Dodaj piktogram</h4>
                </div>

                <Form.Item name="pictogram" valuePropName="image">
                  <div
                    className="panel-body"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {currentPictogram &&
                      currentPictogram.length > 0 &&
                      currentPictogram.map((item, index) => (
                        <div>
                          <UploadBox
                            key={index}
                            handler={() => {}}
                            image={item?.url}
                            preview
                          />
                        </div>
                      ))}
                    <UploadBox handler={pictogramHandler} />
                  </div>
                </Form.Item>
                {currentPictogram &&
                  currentPictogram.length > 0 &&
                  currentPictogram.map((item, index) => (
                    <Form.Item
                      label={`${index + 1}.`}
                      style={{ display: "none" }}
                      key={index}
                      name={["pictogramText", index]}
                    >
                      <Input />
                    </Form.Item>
                  ))}
                <div className="text-right">
                  <Button onClick={deactivatePictogramImage}>
                    Deaktiviraj slike
                  </Button>
                </div>
              </div>

              <div className="panel panel-primary">
                <div className="panel-heading">
                  <h4 className="panel-title">Dodaj dokument</h4>
                </div>

                <Form.Item name="documents">
                  <div className="panel-body">
                    <UploadFile
                      fileList={fileList}
                      onChangeHandler={handleFileChange}
                      token={token}
                      fileType={"document"}
                      fileSavePath={`public/documents/`}
                    />
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className="text-right">
            <Button type="primary" htmlType="submit">
              {isNew || isNewVariation ? "Dodaj" : "Izmeni"} proizvod
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DataFrom;
