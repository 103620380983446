import React from 'react';
import { Tabs, Divider, Input, /* Select, */ Button, Form, Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import UploadBox from '../base/UploadBox';
const { TextArea } = Input;

const formInit = {
  _id: null,
  title: {},
  description: {},
  image: undefined,
};

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
};

const TagForm = ({ isNew, data, image, onSubmit, language }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  let initialValues = { ...formInit, ...data };

  const prevForm = sessionStorage.getItem('tagForm');
  if (prevForm) initialValues = JSON.parse(prevForm);
  let tagImage;

  if (data && data.image) tagImage = data.image.url;
  if (image && image.url) tagImage = image.url;

  const onFinish = async (values, isNew) => {
    sessionStorage.removeItem('tagForm');
    if (!isNew) {
      values._id = data._id;
      if (image) {
        values.image = image ? image._id : data.image ? data.image._id : undefined;
      }
    } else {
      if (image) {
        values.image = image ? image._id : undefined;
      }
    }
    onSubmit(values, isNew);
  };

  const imageHandler = () => {
    const values = form.getFieldsValue();
    sessionStorage.setItem('tagForm', JSON.stringify(values));
    history.push(`/admin/gallery/edit-content/${isNew ? 'new-tag/img' : 'edit-tag/' + data._id}`);
  };
  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          layout={formItemLayout}
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}>
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Tabs tabPosition='left' defaultActiveKey={language.default.code}>
                  {language.list.map((lang) => (
                    <Tabs.TabPane tab={lang.code} key={lang.code}>
                      <Form.Item
                        label='Title'
                        rules={[{ required: lang.code === language.default.code ? true : false, message: 'Please enter title!' }]}
                        name={['title', lang.code]}
                      // onChange={(e) => form.setFieldsValue({ url: slugify(e.target.value, { lower: true }) })}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item label='Description' name={['description', lang.code]}>
                        <TextArea rows={6} />
                      </Form.Item>
                    </Tabs.TabPane>
                  ))}
                </Tabs>

                <Divider type='horizontal' />

                {/* <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={8}>
                    <Form.Item label='Featured' name='featuredAd' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item label='Featured until' name='featuredAdUntil' getValueProps={() => {}} rules={[{ type: 'object' }]}>
                      <DatePicker style={{ width: '100%' }} size='large' rules={[{ type: 'object' }]} />
                    </Form.Item>
                  </Col>
                </Row> */}
              </div>
            </Col>

            <Col xs={24} md={8}>
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Tag image</h4>
                </div>

                <Form.Item name='image' valuePropName='image'>
                  <div className='panel-body'>
                    <UploadBox handler={imageHandler} image={tagImage} />
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Add' : 'update'} tag
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default TagForm;
