import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { Row, Col, Card, Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Languages = () => {
  const currentuser = useContext(UserContext);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState();
  const [languages, fetchLanguages] = useAxios('', null, currentuser.data.token, 'get');

  useEffect(() => {
    fetchLanguages(`${SERVER_URL}/languages`);
  }, [fetchLanguages]);

  useEffect(() => {
    if (languages.data && languages.data.items && languages.data.items.length > 0) {
      const selected = languages.data.items.filter((lang) => lang.selected).map((lang) => lang._id);
      const defaultLang = languages.data.items.find((lang) => lang.default);
      if (selected.length > 0) setSelectedLanguages(selected);
      if (defaultLang) setDefaultLanguage(defaultLang._id);
    }
  }, [languages]);

  const selectHandler = (value) => {
    // MULTISELECT
    if (selectedLanguages.includes(value)) {
      const removed = selectedLanguages.filter((item) => item !== value);
      setSelectedLanguages(removed);
    } else {
      setSelectedLanguages([...selectedLanguages, value]);
    }
  };

  const defaultHandler = (value) => {
    // SINGLE SELECT
    setDefaultLanguage(value);
    // SELECT LANGUAGE IF NOT SELECTED
    if (!selectedLanguages.includes(value)) setSelectedLanguages([...selectedLanguages, value]);
  };

  const cardColor = (id) => {
    let color = '#fff';
    if (selectedLanguages.includes(id)) color = '#d8ecf3';
    if (defaultLanguage === id) color = '#ccffcc';
    return color;
  };

  return (
    <div style={{ padding: '.5rem' }}>
      {languages && languages.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem', marginLeft: '50%', transform: 'translateX(-50%)' }} />}
      {languages && !languages.isLoading && languages.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
      {languages && !languages.isLoading && !languages.isError && languages.data && languages.data.items.length > 0 && (
        <Row gutter={[8, 8]}>
          {languages.data.items.map((language, index) => (
            <Col key={index} sm={24} md={12} xl={8}>
              <Card bordered={true} bodyStyle={{ position: 'relative', padding: '8px 12px', background: cardColor(language._id) }}>
                <span>{language.name}</span>
                <div style={{ display: 'inline', position: 'absolute', right: 0 }}>
                  <Checkbox checked={selectedLanguages.includes(language._id)} onChange={() => selectHandler(language._id)}>
                    Selected
                  </Checkbox>
                  <Checkbox checked={defaultLanguage === language._id} onChange={() => defaultHandler(language._id)}>
                    Default
                  </Checkbox>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      )}
      {languages && languages.data && !languages.isLoading && languages.data.items.length === 0 && (
        <h2 style={{ marginTop: '3rem', textAlign: 'center' }}>NO LANGUAGES</h2>
      )}
    </div>
  );
};

export default Languages;
