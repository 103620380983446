import React from "react";
import { Button, Col, Form, Input, Row, Select, Tabs } from "antd";
import JoditEditor from "jodit-react";
import UploadBox from "../base/UploadBox";
import { useHistory } from "react-router-dom";

const configJodit = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: "300",
  buttons: [
    "source",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "eraser",
    "|",
    "superscript",
    "subscript",
    "|",
    "ul",
    "ol",
    "|",
    "outdent",
    "indent",
    "|",
    // 'font',
    "fontsize",
    "\n",
    "brush",
    "paragraph",
    "link",
    "align",
    // '|',
    "undo",
    "redo",
    "selectall",
    "cut",
    "copy",
    "paste",
    "copyformat",
    "|",
    "hr",
    "symbol",
    "fullsize",
    "print",
    "preview",
    "find",
  ],
};

const formInit = {
  _id: null,
  title: {},
  content: {},
  link: {},
  anchor: {},
  textColor: {},
  backgroundPattern: {},
  buttonStyle: undefined,
  image: undefined,
};

const BrandForm = ({ isNew, data, language, image, onSubmit }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  let initialValues = { ...formInit, ...data };

  const prevForm = sessionStorage.getItem("brandForm");

  if (prevForm) initialValues = JSON.parse(prevForm);
  let brandImage;
  if (data && data.image) brandImage = data.image.url;
  if (image && image.url) brandImage = image.url;

  const onFinish = async (values, isNew) => {
    sessionStorage.removeItem("brandForm");
    if (!isNew) {
      values._id = data._id;
      if (image) {
        values.image = image
          ? image._id
          : data.image
          ? data.image._id
          : undefined;
      }
    } else {
      if (image) {
        values.image = image ? image._id : undefined;
      }
    }
    onSubmit(values, isNew);
  };

  const imageHandler = () => {
    const values = form.getFieldsValue();
    sessionStorage.setItem("brandForm", JSON.stringify(values));
    history.push(
      `/admin/gallery/edit-content?fromPage=${
        isNew ? "new-brand" : "edit-brand/" + data._id
      }`,
    );
  };
  return (
    <div className="panel panel-body">
      <div className="panel-body">
        <Form
          className="form-horizontal"
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout="vertical"
          form={form}
        >
          <Row type="flex" gutter={16}>
            <Col xs={24} md={16}>
              <div className="panel-body">
                <Tabs
                  tabPosition="left"
                  defaultActiveKey={language.default.code}
                >
                  {language.list.map((lang) => (
                    <Tabs.TabPane tab={lang.code} key={lang.code}>
                      <Form.Item
                        label="Naslov"
                        rules={[
                          {
                            required:
                              lang.code === language.default.code
                                ? true
                                : false,
                            message: "Unesite naslov!",
                          },
                        ]}
                        name={["title", lang.code]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item label="Sadržaj" name={["content", lang.code]}>
                        <JoditEditor
                          name={`content[${lang.code}]`}
                          style={{ margin: "2px 0px", minHeight: "350px" }}
                          config={configJodit}
                        />
                      </Form.Item>
                      <Form.Item label="Link" name={["link", lang.code]}>
                        <Input />
                      </Form.Item>
                      <Form.Item label="Anchor" name={["anchor", lang.code]}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Boja pozadine"
                        name={["backgroundPattern", lang.code]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Boja teksta"
                        name={["textColor", lang.code]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Stil dugmeta"
                        name="buttonStyle"
                        rules={[
                          { required: true, message: "Izmeniti stil dugmeta!" },
                        ]}
                      >
                        <Select placeholder="Stil dugmeta">
                          <Select.Option value="tamno">Tamno</Select.Option>
                          <Select.Option value="svetlo">Svetlo</Select.Option>
                        </Select>
                      </Form.Item>
                    </Tabs.TabPane>
                  ))}
                </Tabs>
              </div>
            </Col>

            <Col xs={24} md={8}>
              <div className="panel panel-primary">
                <div className="panel-heading">
                  <h4 className="panel-title">Slika banera</h4>
                </div>

                <Form.Item name="image" valuePropName="image">
                  <div className="panel-body">
                    <UploadBox handler={imageHandler} image={brandImage} />
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className="text-right">
            <Button type="primary" htmlType="submit">
              {isNew ? "Dodaj" : "Izmeni"} baner
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BrandForm;
