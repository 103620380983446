import React from 'react';
import { Table, Popconfirm, Input, Button } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import ValueTable from './ValueTable';

const AttributeTable = ({ attributes, language, addValue, deleteHandler, deleteValueHandler, editHandler, editValueHandler, editValue }) => {
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} size='small' style={{ width: 90, marginRight: 8 }}>
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const attributeColumns = [
    {
      title: 'Ime',
      dataIndex: ['name', `${language.selected.code}`],
      ...getColumnSearchProps(['name', `${language.selected.code}`]),
    },
    {
      title: 'Globalan',
      dataIndex: 'isGlobal',
      render: (text, record) => <span>{record.isGlobal ? 'YES' : 'NO'}</span>,
      filters: [
        {
          text: 'YES',
          value: true,
        },
        {
          text: 'NO',
          value: false,
        },
      ],
      onFilter: (value, record) => record.isGlobal === value,
    },
    {
      title: 'Tip',
      dataIndex: 'type',
      filters: [
        {
          text: 'INPUT',
          value: 'INPUT',
        },
        {
          text: 'CHOICE',
          value: 'CHOICE',
        },
        {
          text: 'MULTICHOICE',
          value: 'MULTICHOICE',
        },
        {
          text: 'CHECKBOX',
          value: 'CHECKBOX',
        },
        {
          text: 'NUMBER',
          value: 'NUMBER',
        },
        {
          text: 'DATE',
          value: 'DATE',
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
    },
    {
      title: 'Akcija',
      width: 80,
      render: (text, record) => (
        <div className='table-actions'>
          {['INPUT', 'NUMBER', 'CHECKBOX', 'DATE'].includes(record.type) ? null : ( // Dont allow ADD for some attribute types
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} title='Add value' onClick={() => addValue(record._id)}>
              <PlusOutlined />
            </div>
          )}
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} title='Edit attribute' onClick={() => editHandler(record._id)}>
            <EditOutlined />
          </div>
          <div style={{ margin: '2px', padding: '4px' }}>
            <Popconfirm
              placement='left'
              title={`This will delete ${record.name[language.selected.code] ? record.name[language.selected.code].toLowerCase() : ''}`}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText='Ok'
              cancelText='Cancel'
            >
              <DeleteOutlined title={`Delete ${record.name[language.selected.code] ? record.name[language.selected.code].toLowerCase() : ''}`} />
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];

  // const valueColumns = [
  //   {
  //     title: 'Value',
  //     dataIndex: ['value', language.selected.code],
  //     ...getColumnSearchProps(['value', language.selected.code]),
  //   },
  //   {
  //     title: 'Action',
  //     width: 70,
  //     render: (text, record, index) => (
  //       <div className='table-actions'>
  //         <div
  //           style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}
  //           title='Edit value'
  //           onClick={() => editValueHandler(editValue.attrId, record._id)}
  //         >
  //           <EditOutlined />
  //         </div>
  //         <div style={{ margin: '2px', padding: '4px' }}>
  //           <Popconfirm
  //             style={{ margin: '2px', padding: '4px' }}
  //             placement='left'
  //             title={`This will delete value ${record.value[language.selected.code] ? record.value[language.selected.code].toLowerCase() : ''}`}
  //             onConfirm={() => deleteValueHandler(editValue.attrId, record._id)}
  //             okText='Ok'
  //             cancelText='Cancel'
  //           >
  //             <DeleteOutlined />
  //           </Popconfirm>
  //         </div>
  //       </div>
  //     ),
  //   },
  // ];

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={attributes}
        columns={attributeColumns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
        expandedRowRender={(record, index) => {
          if (!['INPUT', 'NUMBER', 'CHECKBOX', 'DATE'].includes(record.type) && record.values.length > 0) {
            return (
              <ValueTable
                values={record.values}
                deleteValueHandler={deleteValueHandler}
                editValueHandler={editValueHandler}
                getColumnSearchProps={getColumnSearchProps}
                language={language}
                attrId={record._id}
              />
            );
          } else if (['INPUT', 'NUMBER', 'CHECKBOX', 'DATE'].includes(record.type)) {
            return null;
          } else {
            return <p>NEMA VREDNOSTI</p>;
          }
        }}
      />
    </div>
  );
};

export default AttributeTable;
