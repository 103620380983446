import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button, Modal, Form, Input } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/ImageFolderTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'googleDescription',
  'keywords',
  'content',
  'image',
  'user',
  'url',
  'tag',
];

const ImageFolder = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm();
  const [folder, setFolder] = useState();

  useEffect(() => {
    fetchData(`${SERVER_URL}/image-folders`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/image-folders/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Folder je obrisan.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/image-folders');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));

  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  const showModal = () => {
    setIsVisible(true);
  };

  const hideModal = () => {
    setIsVisible(false);
    if (folder) {
      setFolder();
    }
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    try {
      await Axios.post(`${SERVER_URL}/image-folders`, values, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Kreiran je nov folder.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/image-folders');
    } catch (err) {
      notification.error({
        message: 'Problem sa kreiranjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const updateFolder = async (values) => {
    try {
      await Axios.put(`${SERVER_URL}/image-folders/${folder._id}`, values,
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      notification.success({
        message: 'Izmenjen je folder.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/image-folders');
      setFolder();
    } catch (err) {
      notification.error({
        message: 'Problem sa izmenom. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const editModal = async (id) => {
    const response = await Axios.get(`${SERVER_URL}/image-folders/${id}`,
      { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
    );
    setFolder(response.data);
    setIsVisible(true);
    form.setFieldsValue({
      folderName: response.data.folderName,
    });
  };

  return (
    <div style={{ padding: "8px" }}>
      <div className="actions-block">
        <Button type="primary" onClick={showModal}>Novi folder</Button>
      </div>

      <div style={{ textAlign: "center" }}>
        {data.isLoading && <LoadingOutlined spin style={{ fontSize: "3rem", marginTop: "5rem" }} />}
        {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
          <Table data={tableData} deleteHandler={deleteDataHandler} editHandler={editModal}
            columnKeys={columnKeys} title="Folder" editPath="/admin/edit-image-folder/" />
        )}
        {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
      <Modal title={folder ? 'Izmeni folder' : 'Novi folder'}
        visible={isVisible}
        onOk={form.submit}
        onCancel={hideModal}
        footer={[
          <Button key="back" onClick={hideModal}>
            Odustani
          </Button>,
          !folder ? <Button key="submit" type="primary" onClick={form.submit}>
            Dodaj folder
          </Button> :
            <Button key="submit" type="primary" onClick={form.submit}>
              Izmeni folder
            </Button>,
        ]}>
        <Form form={form} onFinish={folder ? updateFolder : handleSubmit} style={{ marginTop: '20px' }}>
          <Form.Item name='folderName' label='Naziv foldera'
            rules={[{ required: true, message: 'Unesite naziv foldera!' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ImageFolder;