import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import PageForm from '../../components/forms/PageForm';
import queryString from 'query-string';
import { UserContext } from '../../App';
import { Button, notification } from 'antd';

const EditPages = (props) => {

  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [page, fetchPage] = useAxios('', {}, currentuser.data.token, 'get');
  const [pageImage, fetchpageImage] = useAxios('', null, currentuser.data.token, 'get');
  const { imgId } = queryString.parse(props.location.search);
  const { id } = props.match.params;

  useEffect(() => {
    if (id) fetchPage(`${SERVER_URL}/pages/${id}`, {});
    if (imgId) fetchpageImage(`${SERVER_URL}/images/${imgId}`, {});
  }, [fetchPage, fetchpageImage, id, imgId]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/pages` : `${SERVER_URL}/pages/${id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });


      notification.success({
        message: `Stranica ${isNew ? 'kreirana.' : 'izmenjena.'}`,
        placement: 'bottomRight',
      });
      history.push('/admin/Pages');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }

  };
  const isDataFetched = !page.isLoading && page.data && currentuser.language && !pageImage.isLoading;

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/Pages'>
          <Button type='primary'>Sve stranice</Button>
        </Link>
      </div>
      <div>
        {!id && isDataFetched && (
          <PageForm
            isNew={true}
            image={pageImage.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
        {id && !page.isError && page.data && isDataFetched && (
          <PageForm
            isNew={false}
            data={page.data}
            image={pageImage.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>

  );
};

export default EditPages;
