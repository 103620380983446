import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { Button, notification } from 'antd';
import SupplierForm from "../../components/forms/SupplierForm";

const EditSupplier = (props) => {
    const currentuser = useContext(UserContext);
    const history = useHistory();
    const [supplier, fetchSupplier] = useAxios('', {}, currentuser.data.token, 'get');
    const { supplierId } = props.match.params;

    useEffect(() => {
        if (supplierId) fetchSupplier(`${SERVER_URL}/suppliers/${supplierId}`, {});
    }, [fetchSupplier, supplierId]);

    const onSubmit = async (formData, isNew) => {
        const method = isNew ? 'post' : 'put';
        const route = isNew ? `${SERVER_URL}/suppliers` : `${SERVER_URL}/suppliers/${supplierId}`;
        try {
            await Axios[method](route, formData, {
                withCredentials: false,
                headers: { Authorization: `Bearer ${currentuser.data.token}` },
            });

            notification.success({
                message: `Supplier ${isNew ? 'created.' : 'updated.'}`,
                placement: 'bottomRight',
            });
            history.push('/admin/suppliers');
        } catch (error) {
            const msg = error.response ? error.response.data.message : error.message ? error.message : error;
            console.log(msg);
            notification.error({
                message: msg,
                placement: 'bottomRight',
            });
        }
    };

    const isDataFetched = !supplier.isLoading && supplier.data && currentuser.language;

    return(
        <div style={{ padding: '8px' }}>
            <div className='actions-block'>
                <Link to='/admin/suppliers'>
                    <Button type='primary'>Svi dobavljači</Button>
                </Link>
            </div>
            <div>
                {!supplierId && isDataFetched && (
                    <SupplierForm
                        isNew={true}
                        language={currentuser.language}
                        onSubmit={onSubmit}
                        SERVER_URL={SERVER_URL}
                        token={currentuser.data.token}
                    />
                )}
                {supplierId && !supplier.isError && supplier.data && isDataFetched && (
                    <SupplierForm
                        isNew={false}
                        data={supplier.data}
                        language={currentuser.language}
                        onSubmit={onSubmit}
                        SERVER_URL={SERVER_URL}
                        token={currentuser.data.token}
                    />
                )}
            </div>
        </div>
    )

}

export default EditSupplier;