import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/OrderTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'content', 'user', 'email'];

const Orders = (props) => {
  const currentuser = useContext(UserContext);
  const [orders, fetchOrders] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();
  let filter = {};
  const { path } = props.match;

  if (path.includes('processed-corporate')) {
    filter = { status: 'Processed', 'user.legalType': 'corporate' };
  } else if (path.includes('processed-private')) {
    filter = { status: 'Processed', 'user.legalType': 'private' };
  } else if (path.includes('new-corporate')) {
    filter = { status: { $ne: 'Processed' }, 'user.legalType': 'corporate' };
  } else if (path.includes('new-private')) {
    filter = { status: { $ne: 'Processed' }, 'user.legalType': 'private' };
  }

  useEffect(() => {
    fetchOrders(`${SERVER_URL}/orders?filter=${JSON.stringify(filter)}`, []);
  }, [fetchOrders]);

  const deleteOrderHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/orders/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Stavka je obrisana.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/orders');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (orders.data && orders.data.items && orders.data.items.length > 0) {
    const keys = Object.keys(orders.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys.splice(1, 0, 'email');
  }

  let tableData = orders.data.items;
  if (orders.data && orders.data.items && orders.data.items.length > 0) {
    tableData = orders.data.items.map((item) => {
      let reference = item.pdf.find((file) => file.reference === 0);
      let invoiceOne = item.pdf.find((file) => file.reference === 1);
      let invoiceTwo = item.pdf.find((file) => file.reference === 2);
      let pdv = (item.deliveryPrice + Number(item.totalPrice)) * 0.2;
      item.email = item.user ? item.user.email : 'Obrisan korisnik';
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      item.reference = reference ? reference.originalname : '';
      item.invoice = invoiceOne
        ? invoiceOne.originalname
        : invoiceTwo
          ? invoiceTwo.originalname
          : '';
      item.userName =
        item.user && item.user.legalType === 'corporate'
          ? item.user.companyName
          : item.user && item.user.legalType === 'private'
            ? item.user.firstName + ' ' + item.user.lastName
            : '';
      // item.total = (Number(item.totalPrice) + item.deliveryPrice + pdv).toFixed(
      //   2,
      // );
      item.total = (Number(item.totalPrice) + +item.deliveryPrice).toFixed(2);
      return item;
    });
  }
  return (
    <div style={{ padding: '8px' }}>
      <div style={{ textAlign: 'center' }}>
        {orders.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: '3rem', marginTop: '5rem' }}
          />
        )}
        {!orders.isLoading &&
          orders.data &&
          orders.data.items &&
          orders.data.items.length > 0 && (
            <Table
              data={tableData}
              deleteHandler={deleteOrderHandler}
              columnKeys={columnKeys}
              title="Order"
              editPath="/admin/edit-order/"
            />
          )}
        {!orders.isLoading &&
          orders.data &&
          orders.data.items &&
          orders.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default Orders;
