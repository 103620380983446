import React, { useEffect, useContext, useState, useRef } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button, Input, Select, Checkbox } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/DataTable';
import { SERVER_URL } from '../../config';

const { Option } = Select;
const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'contacted',
  'gallery',
  'discount',
  'featureImage',
  'follow',
  'views',
  'googleDescription',
  'keywords',
  'content',
  'gallery',
  'categoryPath',
  'fullUrl',
  'url',
  'comments',
  'attributes',
  'featuredAd',
  'featuredAdUntil',
  'user',
  'declaration',
  'variationParent',
  'supplier',
  'dataViews',
  'retailPrice',
  'wholesalePrice',
  'discountRetail',
  'discountWholesale',
  'discountRetailPrice',
  'discountWholesalePrice',
  'supplies',
  'updatedAtOriginal',
  'variations',
  'documents',
  'newProduct',
  'superPrice',
  'notActive',
  'pictogram',
  'sizeTableImage',
  'view360Active',
  'webDiscountEndDate',
  'webDiscountPercentage',
  'webDiscountRetailPrice',
  'webDiscountStartDate',
  'icon',
  'dontShowRebate'
];

const Data = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [categories, fetchCategories] = useAxios(
    '',
    [],
    currentuser.data.token,
    'get',
  );
  const history = useHistory();
  const [selected, setSelected] = useState([]);
  const [category, setCategory] = useState(undefined);
  const [updateData, setUpdateData] = useState({});
  useEffect(() => {
    fetchData(`${SERVER_URL}/data`, []);
    fetchCategories(`${SERVER_URL}/categories`, []);
  }, [fetchData, fetchCategories]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/data/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/data');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const searchData = async (value) => {
    // let filtered = JSON.parse(JSON.stringify(data));
    try {
      // const dataResponse = await Axios.get(`${SERVER_URL}/filter-data?search=${value}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } })
      fetchData(`${SERVER_URL}/filter-data?search=${value}`, []);
      // filtered.data.items = dataResponse.data.items;
    } catch (error) {
      console.log(error);
    }
  };

  const selectHandler = (value) => {
    if (selected.includes(value)) {
      const removed = selected.filter((item) => item !== value);
      setSelected(removed);
    } else {
      setSelected([...selected, value]);
    }
  };

  let columnKeys;
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }
  const handleDataChange = (e) => {
    const name = e.target.name;
    const update = { ...updateData };
    if (e.target.checked) {
      update[name] = e.target.checked;
    } else {
      delete update[name];
    }
    setUpdateData(update);
  };

  const changeDataCategory = async () => {
    try {
      await Axios.get(
        `${SERVER_URL}/data-category/${category}?data=${JSON.stringify(
          selected,
        )}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'Items are updated',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      console.log(error);
    }
  };

  const applyChanges = async () => {
    try {
      await Axios.put(
        `${SERVER_URL}/data-apply-changes?data=${JSON.stringify(selected)}`,
        updateData,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'Items are updated',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteManyData = async () => {
    try {
      await Axios.delete(
        `${SERVER_URL}/delete-many-data?data=${JSON.stringify(selected)}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'Items are deleted',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      <div className="actions-block">
        <Link to="/admin/new-data">
          <Button type="primary">Novi proizvod</Button>
        </Link>
        <div className="text-right">
          <Input.Search
            onSearch={searchData}
            allowClear
            style={{ width: '100%' }}
          />
        </div>
        {selected.length > 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              marginTop: '20px',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                style={{ width: '250px' }}
                onChange={(value) => setCategory(value)}
              >
                {categories.data.length > 0 &&
                  categories.data.map((item, index) => (
                    <Option key={index} value={item._id}>
                      {item.name.en}
                    </Option>
                  ))}
              </Select>
              <Button
                onClick={changeDataCategory}
                htmlType="button"
                style={{ marginLeft: '15px' }}
                type="primary"
              >
                Dodaj u kategoriju
              </Button>
            </div>
            <div>
              <Checkbox
                name="featuredAd"
                style={{ marginLeft: '15px' }}
                onChange={handleDataChange}
              >
                Izdvojen proizvod
              </Checkbox>
              <Checkbox name="notActive" onChange={handleDataChange}>
                Neaktivan proizvod
              </Checkbox>
              <Checkbox name="superPrice" onChange={handleDataChange}>
                Super cena
              </Checkbox>
              <Checkbox name="newProduct" onChange={handleDataChange}>
                Novo
              </Checkbox>
            </div>
            <div>
              <Button onClick={applyChanges} type="primary" htmlType="button">
                Primeni
              </Button>
              <Button
                style={{ marginLeft: '15px' }}
                onClick={deleteManyData}
                type="primary"
                htmlType="button"
              >
                Obriši
              </Button>
            </div>
          </div>
        )}
      </div>

      <div style={{ textAlign: 'center' }}>
        {data.isLoading && (
          <LoadingOutlined
            spin
            style={{ fontSize: '3rem', marginTop: '5rem' }}
          />
        )}
        {!data.isLoading &&
          data.data &&
          data.data.items &&
          data.data.items.length > 0 && (
            <Table
              selected={selected}
              selectHandler={selectHandler}
              data={tableData}
              deleteHandler={deleteDataHandler}
              columnKeys={columnKeys}
              title="Data"
              editPath="/admin/edit-data/"
            />
          )}
        {!data.isLoading &&
          data.data &&
          data.data.items &&
          data.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
      </div>
    </div>
  );
};

export default Data;
